import React from "react";

import {
  CompanyInfo,
  FooterContainer,
  FooterContent,
  SocialMedia,
  SubTitle,
  Title,
} from "./style";
import { FaFacebook, FaInstagramSquare } from "react-icons/fa";
import { FaGlobe, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
const ic_logo = require("../../assets/images/Logo_-_Branca.png");
interface FooterProps {
  companyName?: string;
  address?: string;
  email?: string;
  phone?: string;
  socialMedia?: {
    facebook?: string;
    site?: string;
    instagram?: string;
    linkedin?: string;
  };
}

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/ecolifemeioambiente/",
  linkedin:
    "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

const Footer: React.FC<FooterProps> = ({
  companyName,
  address,
  email,
  phone,
}) => {
  const { t } = useTranslation();
  return (
    <FooterContainer>
      <FooterContent>
        <div>
          <img
            src={ic_logo}
            alt="logo"
            style={{
              width: "220px",
              height: "120px",
              marginLeft: "20px",
            }}
          />
        </div>
        <CompanyInfo>
          <Title>{t("about-us")}</Title>
          <SubTitle>{t("description-about-us")}</SubTitle>
        </CompanyInfo>

        <CompanyInfo>
          <Title>{t("where-are-we")}</Title>
          <SubTitle>SHC norte, Q. 306 Bloco C SALA 117</SubTitle>
          <SubTitle>Asa Norte, Brasília - DF</SubTitle>
          <SubTitle>CEP: 70745-530</SubTitle>
        </CompanyInfo>

        <CompanyInfo>
          <Title>{t("contact-us")}</Title>
          <SubTitle>SHC norte, Q. 306 Bloco C SALA 117</SubTitle>
          <SubTitle>{t("phone")}: (61) 3201-1681</SubTitle>
          <SubTitle>Whatsapp: (61) 98100-0130</SubTitle>
          <SubTitle>E-mail: contato@ecolifemeioambiente.com.br</SubTitle>
          <SubTitle>Instagram : @ecolifemeioambiente</SubTitle>
        </CompanyInfo>
      </FooterContent>
      <SocialMedia>
        <a
          href={socialMediaLinks?.facebook}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook size={25} />
        </a>

        <a
          href={socialMediaLinks?.instagram}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram size={25} />
        </a>

        <a
          href={socialMediaLinks?.linkedin}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedin size={25} />
        </a>

        <a
          href={socialMediaLinks?.site}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaGlobe size={25} />
        </a>
      </SocialMedia>
    </FooterContainer>
  );
};

export default Footer;
