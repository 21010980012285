import styled from "styled-components";

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const Content = styled.div`
  flex-direction: row;
  display: flex;

  @media only screen and (max-width: 768px) {
    /* Estilos específicos para tablets */
    /* Por exemplo, reduzir a largura do Container */
    width: 80%;
    flex-direction: column;
  }

  @media only screen and (max-width: 914px) {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 480px) {
    /* Estilos específicos para celulares */
    /* Por exemplo, reduzir ainda mais a largura do Container */
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const Space = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100vh; /* 100% da altura da tela */

  @media only screen and (max-width: 480px) {
    height: 40vh; /* Altura desejada da linha */
    width: 70%; /* A largura será 100% do contêiner pai */
  }

  @media only screen and (max-width: 914px) {
    height: 40vh; /* Altura desejada da linha */
    width: 70%; /* A largura será 100% do contêiner pai */
  }
`;

export const Img = styled.img`
  width: 30%;
  height: 50%;
  margin-left: 5px;
  cursor: pointer;

  @media only screen and (min-width: 425px) {
    margin-top: 1rem;
    height: 35%; /* Altura desejada da linha */
    width: 100%; /* A largura será 100% do contêiner pai */
  }

  @media only screen and (min-width: 768px) {
    margin-top: 2rem;
    height: 30%; /* Altura desejada da linha */
    width: 100%; /* A largura será 100% do contêiner pai */
  }

  @media only screen and (min-width: 1024px) {
    height: 45%; /* Altura desejada da linha */
    width: 100%; /* A largura será 100% do contêiner pai */
  }
  @media only screen and (min-width: 1440px) {
    height: 50%; /* Altura desejada da linha */
    width: 100%; /* A largura será 100% do contêiner pai */
  }
  @media only screen and (min-width: 1441px) {
    height: 50%; /* Altura desejada da linha */
    width: 100%; /* A largura será 100% do contêiner pai */
  }
`;

export const ButtonImg = styled.button`
  font-family: "Josefin Sans";
  color: #fff;
  font-size: 18px;
  margin-top: 15px;
  background-color: #48c85d;
  border-radius: 8px;
  height: 60px;
  left: 0;
  top: 0;
  width: 550px;
  border: 0;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @media only screen and (min-width: 425px) {
    height: 7%;
    width: 100%;
  }

  @media only screen and (min-width: 768px) {
    height: 7%;
    width: 100%;
  }

  @media only screen and (min-width: 1024px) {
    height: 10%;
    width: 100%;
  }
  @media only screen and (min-width: 1440px) {
    height: 7%;
    width: 100%;
  }

  @media only screen and (min-width: 1441px) {
    height: 5%;
    width: 90%;
  }
`;

export const DivImg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

export const Language = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Br = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 5px;
`;

export const Us = styled.img`
  width: 40px;
  height: 40px;
  margin-left: 5px;
`;

export const ContainerChoice = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 4px #00000040;
  height: 301px;
  left: 0;
  top: 0;
  width: 594px;
  margin-top: 20px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  @media only screen and (max-width: 914px) {
    width: 100%;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
  }

  @media only screen and (max-width: 1024px) {
    width: 90%;
  }
`;

export const Button = styled.button`
  background-color: #48c85d;
  border-radius: 8px;
  height: 60px;
  left: 0;
  top: 0;
  width: 550px;
  border: 0;
  margin-bottom: 20px;

  @media only screen and (max-width: 1024px) {
    width: 80%;
    height: 50px;
  }

  @media only screen and (max-width: 768px) {
    width: 80%;
    height: 50px;
  }

  @media only screen and (max-width: 914px) {
    width: 80%;
    height: 50px;
  }

  @media only screen and (max-width: 480px) {
    width: 80%;
    height: 50px;
  }
`;

export const ButtonBuy = styled.button`
  background-color: #f5f5f5;
  border: 1px solid;
  border-color: #48c85d;
  border-radius: 8px;
  height: 60px;
  left: 0;
  top: 0;
  width: 550px;
  margin-bottom: 20px;

  @media only screen and (max-width: 1024px) {
    width: 80%;
    height: 50px;
  }

  @media only screen and (max-width: 768px) {
    width: 80%;
    height: 50px;
  }

  @media only screen and (max-width: 914px) {
    width: 80%;
    height: 50px;
  }

  @media only screen and (max-width: 480px) {
    width: 80%;
    height: 50px;
  }
`;

export const LinhaVertical = styled.div`
  height: 90vh; /* Ajuste a altura da linha conforme necessário */
  width: 0.2px;
  background-color: #c4c4c4; /* Cor da linha */
  margin-top: 5vh;
  border-radius: 4px;

  @media only screen and (max-width: 768px) {
    width: 80%;
    margin-top: 1vh;
    flex-direction: column;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 914px) {
    width: 80%;
    height: 0.5px;
    margin-top: 1vh;
    flex-direction: column;
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 480px) {
    width: 80%;
    margin-top: 1vh;
    flex-direction: column;
    margin-bottom: 30px;
  }
`;

export const Title = styled.h1`
  font-family: "Josefin Bold";
  color: #515151;
  font-size: 25px;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 914px) {
    font-size: 20px;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 480px) {
    font-size: 20px;
    margin-bottom: 20px;
  }
`;

export const LanguageText = styled.p`
  font-family: "Josefin Bold";
  color: #000;
`;

export const TitleButtonSeller = styled.h1`
  font-family: "Josefin Sans";
  color: #fff;
  font-size: 18px;
  margin-top: 15px;

  @media only screen and (max-width: 768px) {
    margin-top: 1px;
    font-family: "Josefin Bold";
  }

  @media only screen and (max-width: 914px) {
    margin-top: 1px;
    font-family: "Josefin Bold";
  }

  @media only screen and (max-width: 480px) {
    margin-top: 1px;
    font-family: "Josefin Bold";
  }
`;

export const DescriptionButtonSeller = styled.p`
  font-family: "Josefin Sans";
  color: #fff;
  margin-top: -12px;

  @media only screen and (max-width: 768px) {
    font-size: 12px;
    margin-top: -10px;
  }

  @media only screen and (max-width: 914px) {
    font-size: 12px;
    margin-top: -10px;
  }

  @media only screen and (max-width: 480px) {
    font-size: 12px;
    margin-top: -10px;
  }
`;

export const TitleButtonBuy = styled.h1`
  font-family: "Josefin Sans";
  color: #48c85d;
  font-size: 18px;
  margin-top: 15px;

  @media only screen and (max-width: 768px) {
    margin-top: 1px;
    font-family: "Josefin Bold";
  }

  @media only screen and (max-width: 914px) {
    margin-top: 1px;
    font-family: "Josefin Bold";
  }

  @media only screen and (max-width: 480px) {
    margin-top: 1px;
    font-family: "Josefin Bold";
  }
`;

export const DescriptionButtonBuy = styled.p`
  font-family: "Josefin Sans";
  color: #48c85d;
  margin-top: -12px;

  @media only screen and (max-width: 768px) {
    font-size: 12px;
    margin-top: -10px;
  }

  @media only screen and (max-width: 914px) {
    font-size: 12px;
    margin-top: -10px;
  }

  @media only screen and (max-width: 480px) {
    font-size: 12px;
    margin-top: -10px;
  }
`;
