import styled from "styled-components";
import colors from "../../../utils/colors";
export const Container = styled.div`
  width: 100%;
`;

export const ContainerCard = styled.div`
  margin: 30px;
  display: flex;
  flex-wrap: wrap;
`;

export const ContentCard = styled.div`
  margin-right: 10px;
  margin-bottom: 30px;
`;

export const Title = styled.h1`
  font-family: "Josefin Sans";
  color: ${colors.gray};
  font-size: 15px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 colunas com largura igual */
  margin-left: 30px;
  min-height: 56.8vh;
`;
