import axios from "axios";
import { baseSeller } from "../utils/server/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
const updateUser = async (userData: any) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return await axios.put(`${baseSeller}/me`, userData, config);
};

export function useMeSellerPutDataBank() {
  const queryClient = useQueryClient();
  const mutate = useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["me"] });
    },
  });
  return mutate;
}
