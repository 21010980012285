import React from "react";
import { Container, Content, Text, VerticalLine } from "./style";
import { MdHealthAndSafety } from "react-icons/md";
import { IoIosRocket } from "react-icons/io";
import { FaIdCard } from "react-icons/fa";
import { FaPix } from "react-icons/fa6";
import { FaNoteSticky } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

export default function Flys() {
  const { t } = useTranslation();
  return (
    <Container>
      <Content>
        <MdHealthAndSafety color="#48c85d" size={35} />
        <div style={{ marginLeft: "10px" }}>
          <Text>{t("security")}</Text>
          <p style={{ fontSize: "12px", marginTop: "-5px" }}>
            {t("security-description")}
          </p>
        </div>
      </Content>
      <VerticalLine />
      <Content>
        <IoIosRocket color="#48c85d" size={35} />
        <div style={{ marginLeft: "10px" }}>
          <Text>{t("speed")}</Text>
          <p style={{ fontSize: "12px", marginTop: "-5px" }}>
            {t("speed-description")}
          </p>
        </div>
      </Content>
      <VerticalLine />
      <Content>
        <FaIdCard size={35} color="#48c85d" />
        <div style={{ marginLeft: "10px" }}>
          <Text>{t("credit-card")}</Text>
          <p style={{ fontSize: "12px", marginTop: "-5px" }}>
            {t("credit-card-description")}
          </p>
        </div>
      </Content>
      <VerticalLine />
      <Content>
        <FaPix size={35} color="#48c85d" />
        <div style={{ marginLeft: "10px" }}>
          <Text>Pix</Text>
          <p style={{ fontSize: "12px", marginTop: "-5px" }}>
            {t("credit-card-description")}
          </p>
        </div>
      </Content>
      <VerticalLine />
      <Content>
        <FaNoteSticky size={35} color="#48c85d" />
        <div style={{ marginLeft: "10px" }}>
          <Text>{t("ticket")}</Text>
          <p style={{ fontSize: "12px", marginTop: "-5px" }}>
            {t("credit-card-description")}
          </p>
        </div>
      </Content>
    </Container>
  );
}
