import Navbar from "../../../components/navbar/navbar";
import { CiHome } from "react-icons/ci";
import { useCart } from "../../../context/CartContext";
import {
  Banner,
  Container,
  ContainerBanner,
  ContainerContent,
  ContainerTitle,
  Title,
  Text,
  Button,
  ContainerMargin,
  HorizontalLine,
  TitleInformation,
  TextInformation,
  Price,
  DescriptionPrice,
  MiniRetangulo,
  Quantidade,
  SomaTotal,
  ContainerFlex,
  SubPrice,
  MarginRight,
  ContentInformation,
  ContainerInformation,
  ButtonBlue,
} from "./style";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Amount from "../../../components/amount/amount";
import { ToastContainer } from "react-toastify";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import Footer from "../../../components/footer/footer";
import { FaHeart } from "react-icons/fa";
import axios from "axios";
import { baseBuyer } from "../../../utils/server/api";
import { useTranslation } from "react-i18next";
import CheckProducts from "../../../components/modal/modal";
const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin:
    "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

const DetailsPropertyBuy = () => {
  const { t } = useTranslation();
  const { addToCart } = useCart();
  const location = useLocation();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [quantidadeProduto, setQuantidadeProduto] = useState(0);
  const searchParams = new URLSearchParams(location.search);
  const item = JSON.parse(decodeURIComponent(searchParams.get("item") || "{}"));
  const [valorProduto, setValorProduto] = useState(item?.price || 0);
  const [somaTotal, setSomaTotal] = useState(0);
  const token = localStorage.getItem("token");
  const [modalOpen, setModalOpen] = useState(false);

  const handleSomaTotalChange = (novoTotal: number) => {
    setSomaTotal(novoTotal);
  };

  const abrirMaps = () => {
    const lat = item.lat;
    const long = item.long;
    const address = item.address;
    // Verifica se lat, long e address estão definidos
    if (lat && long && address) {
      // Cria a URL do Google Maps com as coordenadas e o endereço
      const mapsUrl = `https://www.google.com/maps?q=${lat},${long}&query=${encodeURIComponent(
        address
      )}`;

      // Abre uma nova janela/tab para o Google Maps
      window.open(mapsUrl, "_blank");
    }
  };

  const handleOpenMap = () => {
    const lat = item.lat;
    const long = item.long;
    const address = item.address;
    // Verifica se lat, long e address estão definidos
    if (lat && long && address) {
      // Cria a URL do Google Maps com as coordenadas e o endereço
      const mapsUrl = `https://www.google.com/maps?q=${lat},${long}&query=${encodeURIComponent(
        address
      )}`;

      // Abre uma nova janela/tab para o Google Maps
      window.open(mapsUrl, "_blank");
    }
  };

  const favorite = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Defina o tipo de conteúdo conforme necessário
      },
    };
    if (!token) {
      onOpen();
      return;
    } else {
      try {
        const response = await axios.post(
          `${baseBuyer}/product/favorite`,
          { product_id: item.id },
          config
        );

        if (response.data.active === true) {
          navigate(`/favorites`);
        }

        console.log(response.data);
      } catch (error) {
        console.log(error);
        console.error("Erro ao favoritar", (error as Error).message);
      }
    }
  };

  const handleCheckCompany = async () => {
    if (!token) {
      onOpen();
      return;
    } else {
      try {
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Defina o tipo de conteúdo conforme necessário
          },
        };

        const response = await axios.post(
          `${baseBuyer}/order/compare-company`,
          { product_id: item.id },
          config
        );

        if (!response.data.result) {
          setModalOpen(true);
        } else {
          handleCart();
        }

        console.log(response.data);
      } catch (error) {}
    }
  };

  const handleCart = () => {
    if (!token) {
      onOpen();
      return;
    } else {
      const data = {
        product_id: item?.id,
        quantity: quantidadeProduto,
      };

      const company_id = item.company_id;
      addToCart(data, company_id);
      setModalOpen(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Navbar />

      <ContainerTitle>
        <CiHome size={25} />
        <Title>
          {t("general-information")} {item?.name || item?.product?.name}
        </Title>
      </ContainerTitle>

      <ContentInformation>
        <ContainerBanner>
          <Banner src={item?.image || item?.product?.image} alt="Casa" />
        </ContainerBanner>

        <ContainerInformation>
          <ContainerMargin>
            <TitleInformation>{t("information")}</TitleInformation>
            <TextInformation>
              {item?.description || item?.product?.description}
            </TextInformation>
          </ContainerMargin>

          <ContainerMargin>
            <HorizontalLine />
          </ContainerMargin>

          <ContainerMargin>
            <ContainerFlex>
              <Price>
                {t("price")}:{" "}
                {item?.priceAsCurrency || item?.product?.priceAsCurrency}
              </Price>
              <Amount
                price={valorProduto}
                onSomaTotalChange={handleSomaTotalChange}
                quantidade={quantidadeProduto}
                setQuantidade={setQuantidadeProduto}
              />
            </ContainerFlex>

            <DescriptionPrice>{t("carbon-credit-value")}</DescriptionPrice>
          </ContainerMargin>

          <ContainerMargin>
            <SubPrice>SubTotal: R$ {somaTotal.toFixed(2)}</SubPrice>

            <Button onClick={handleCheckCompany}>{t("add-to-cart")}</Button>
          </ContainerMargin>

          <ContainerMargin>
            <ButtonBlue onClick={favorite}>
              <FaHeart size={18} />
              <p style={{ marginLeft: "5px", marginTop: "4px" }}>
                {t("add-to-favorites")}
              </p>
            </ButtonBlue>
          </ContainerMargin>
        </ContainerInformation>
      </ContentInformation>
      <ToastContainer />

      {modalOpen && (
        <CheckProducts
          onClose={() => setModalOpen(false)}
          onConfirm={() => handleCart()}
        />
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay style={{ width: "100%", height: "100%" }} />
        <ModalContent>
          <ModalHeader>
            <Title>Atenção</Title>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TextInformation>
              Para acessar essa funcionalidade é necessário estar logado. Faça o
              login ou se cadastre na plataforma!
            </TextInformation>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>Fechar</Button>
            <MarginRight />
            <Button onClick={() => navigate("/choice")}>
              Entrar ou Cadastrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Footer
        companyName="Ecolife"
        address="Caruaru - PE"
        email="contato@suaempresa.com"
        phone="(11) 1234-5678"
        socialMedia={socialMediaLinks}
      />
    </Container>
  );
};
export default DetailsPropertyBuy;
