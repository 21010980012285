import * as Yup from "yup";

const registerEngineerValidation = Yup.object().shape({
  email: Yup.string()
    .email("Digite um e-mail válido")
    .required("Campo obrigatório"),
  password: Yup.string()
    .min(8, "A senha deve ter pelo menos 8 caracteres")
    .required("Campo obrigatório"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password")], "As senhas devem coincidir")
    .required("Campo obrigatório"),
  name: Yup.string().required("Campo obrigatório"),
  rg: Yup.string().required("Campo obrigatório"),
  surname: Yup.string().required("Campo obrigatório"),
  document: Yup.string()
    .min(11, "O CPF deve ter pelo menos 6 caracteres")
    .required("Campo obrigatório"),
  phone: Yup.string().required("Campo obrigatório"),
});

export default registerEngineerValidation;
