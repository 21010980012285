import styled from "styled-components";
import colors from "../../utils/colors";

export const FooterContainer = styled.footer`
  background-color: #495057;
  color: #fff;
  padding: 20px;
  min-height: 150px;
  justify-content: center;
  align-items: center;
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
`;

export const CompanyInfo = styled.div`
  max-width: 400px;
`;

export const SocialMedia = styled.div`
  display: flex;
  margin-left: 19.5%;
  margin-top: 30px;

  a {
    color: #fff;
    margin-right: 10px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Title = styled.h1`
  font-family: "Josefin Bold";
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
`;

export const SubTitle = styled.h2`
  font-family: "Josefin Sans";
  font-size: 14px;
  color: white;
`;
