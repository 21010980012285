import NavBar from "../../components/navbar/navbar";
import {
  ButtonContainer,
  ButtonOutFile,
  Buttons,
  Container,
  ContainerInputs,
  ContainerInputsRow,
  InputContainer,
  ProfilePic,
  TitleInput,
  Button,
} from "./style";
import profile from "../../assets/images/new.png";
import InputWithIcon from "../../components/input/input";
import { MdOutlineEmail, MdOutlinePriceCheck } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { CiPhone } from "react-icons/ci";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { MdCorporateFare } from "react-icons/md";
import { FaWpforms } from "react-icons/fa";
import { CiHome } from "react-icons/ci";
import { GiWoodStick } from "react-icons/gi";
import { ButtonOutlineNoIcon } from "../../components/button/button";
import TitleComponent from "../../components/title/title";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import {
  Formik,
  Field,
  Form,
  FieldInputProps,
  ErrorMessage,
  FormikHelpers,
} from "formik";
import { ToastContainer } from "react-toastify";
import { IoDocumentTextOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { SelectContainer } from "../register/register-buy/style";
import axios from "axios";
import { baseBuyer, baseSeller } from "../../utils/server/api";
import propertyValidation from "../../utils/validation/propertyValidation";
import { ErrorText } from "../login/login-seller/style";
import MaskedInput from "../../components/input/inputMask";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import colors from "../../utils/colors";
import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";
import { useMeData } from "../../hooks/serviceMe";
const token = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data", // Defina o tipo de conteúdo conforme necessário
  },
};

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin:
    "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

export default function RegisterProperty() {
  const { data } = useMeData();
  const company = data.id;
  const [lat, setLat] = useState<any>("");
  const [long, setLong] = useState<any>("");
  const [address, setAddress] = useState<any>("");
  const [category, setCategory] = useState<any>([]);
  const [registerError, setRegisterError] = useState([]);
  const [selectedFileCib, setSelectedFileCib] = useState<any>(null);
  const [selectedFileDeed, setSelectedFileDeed] = useState<any>(null);
  const [selectedFileAddress, setSelectedFileAddress] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mensageFiles, setMensageFiles] = useState<any>("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleFileChangeCib = (event: any) => {
    const file = event.target.files[0];
    setSelectedFileCib(file);
  };

  const handleFileChangeDeed = (event: any) => {
    const file = event.target.files[0];
    setSelectedFileDeed(file);
  };

  const handleFileChangeAddress = (event: any) => {
    const file = event.target.files[0];
    setSelectedFileAddress(file);
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const initialValues = {
    name: "",
    description: "",
    category_id: "",
    hectares_amount: "",
    carbon_credits_amount: 10,
    company_id: 2,
    price: "",
    cib_nirf_number: "",
    deed_number: "",
    address: null,
    lat: null,
    long: null,
    images_file: null,
    cib_nirf_file: null,
    deed_file: null,
    address_file: null,
  };

  const handleSubmit = async (values: any) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data", // Defina o tipo de conteúdo conforme necessário
      },
    };
    if (
      selectedFile === null ||
      selectedFileDeed === null ||
      selectedFileAddress === null ||
      selectedFileCib === null
    ) {
      setMensageFiles("Selecione todos os arquivos");
      return;
    } else {
      setMensageFiles("");
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("category_id", values.category_id);
      formData.append("hectares_amount", values.hectares_amount);
      formData.append("carbon_credits_amount", "10");
      formData.append("company_id", company);
      formData.append("price", values.price.replace(/[^0-9]/g, ""));
      formData.append("cib_nirf_number", values.cib_nirf_number);
      formData.append("deed_number", values.deed_number);
      formData.append("address", address);
      formData.append("lat", lat);
      formData.append("long", long);
      formData.append("images_file", selectedFile);
      formData.append("deed_file", selectedFileDeed);
      formData.append("address_file", selectedFileAddress);
      formData.append("cib_nirf_file", selectedFileCib);

      console.log("hander", formData);

      const data = formData;
      try {
        setIsLoading(true);
        const response = await axios.post(
          `${baseSeller}/product/create`,
          data,
          config
        );
        console.log(response);
        setIsLoading(false);
        if (response.data.errors && Array.isArray(response.data.errors)) {
          console.log(response.data.errors);
          setRegisterError(response.data.errors);
        } else {
          console.log("Nenhum erro encontrado na resposta");
        }

        if (response.data.result === false) {
          console.log("error");
        } else {
          navigate("/homeSeller");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  async function getCategories() {
    try {
      const response = await axios.get(`${baseBuyer}/category`, config);
      console.log(response.data);
      setCategory(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getCategories();
    console.log("data", data);
  }, []);

  const handleSelect = async (value: any) => {
    const results = await geocodeByAddress(value.label);
    const latLng = await getLatLng(results[0]);
    setLat(latLng.lat);
    setLong(latLng.lng);
    console.log(latLng.lat, latLng.lng);
    setAddress(value.label);
    console.log(value.label);
  };

  return (
    <div>
      <div style={{ marginBottom: "150px" }}>
        <NavBar />
      </div>

      <Container>
        <TitleComponent icon={CiHome} text={t("register-property")} />

        <Formik
          initialValues={initialValues}
          validationSchema={propertyValidation}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          <Form>
            <ContainerInputs>
              <ContainerInputsRow>
                <TitleInput> {t("name")}</TitleInput>
                <Field
                  type="text"
                  name="name"
                  render={({ field }: { field?: FieldInputProps<any> }) => (
                    <InputWithIcon
                      icon={<CiHome />}
                      iconSize="23px"
                      field={field}
                    />
                  )}
                />

                {registerError.some((error: any) => error.field === "name") ? (
                  registerError
                    .filter((error: any) => error.field === "name")
                    .map((error: any, index: any) => (
                      <ErrorText key={index}>
                        <p>{error.msgs}</p>
                      </ErrorText>
                    ))
                ) : (
                  <ErrorText>
                    <ErrorMessage name="name" />
                  </ErrorText>
                )}
              </ContainerInputsRow>

              <ContainerInputsRow>
                <TitleInput> {t("vegetation")}</TitleInput>
                <SelectContainer>
                  <Field as="select" id="category_id" name="category_id">
                    <option value="" label="Selecione..." />
                    {category?.map((option: any) => (
                      <option
                        key={option.id}
                        value={option.id}
                        label={option.name}
                      />
                    ))}
                  </Field>
                </SelectContainer>
                {registerError.some(
                  (error: any) => error.field === "category_id"
                ) ? (
                  registerError
                    .filter((error: any) => error.field === "category_id")
                    .map((error: any, index: any) => (
                      <ErrorText key={index}>
                        <p>{error.msgs}</p>
                      </ErrorText>
                    ))
                ) : (
                  <ErrorText>
                    <ErrorMessage name="category_id" />
                  </ErrorText>
                )}
              </ContainerInputsRow>
            </ContainerInputs>
            <ContainerInputs>
              <ContainerInputsRow>
                <TitleInput>{t("hectares")}</TitleInput>
                <Field
                  type="text"
                  name="hectares_amount"
                  render={({ field }: { field?: FieldInputProps<any> }) => (
                    <InputWithIcon
                      icon={<GiWoodStick />}
                      iconSize="23px"
                      field={field}
                    />
                  )}
                />

                {registerError.some(
                  (error: any) => error.field === "hectares_amount"
                ) ? (
                  registerError
                    .filter((error: any) => error.field === "hectares_amount")
                    .map((error: any, index: any) => (
                      <ErrorText key={index}>
                        <p>{error.msgs}</p>
                      </ErrorText>
                    ))
                ) : (
                  <ErrorText>
                    <ErrorMessage name="hectares_amount" />
                  </ErrorText>
                )}
              </ContainerInputsRow>
              <ContainerInputsRow>
                <TitleInput> {t("price")}</TitleInput>
                <Field
                  type="text"
                  name="price"
                  render={({ field }: { field?: FieldInputProps<any> }) => (
                    <MaskedInput
                      field={field}
                      icon={<MdOutlinePriceCheck />}
                      mask="R$ 9999999999999999999"
                      iconSize="23px"
                      placeholder=""
                    />
                  )}
                />

                {registerError.some((error: any) => error.field === "price") ? (
                  registerError
                    .filter((error: any) => error.field === "price")
                    .map((error: any, index: any) => (
                      <ErrorText key={index}>
                        <p>{error.msgs}</p>
                      </ErrorText>
                    ))
                ) : (
                  <ErrorText>
                    <ErrorMessage name="price" />
                  </ErrorText>
                )}
              </ContainerInputsRow>
            </ContainerInputs>

            <ContainerInputs>
              <ContainerInputsRow>
                <TitleInput> {t("number-doc")}</TitleInput>
                <Field
                  type="text"
                  name="cib_nirf_number"
                  render={({ field }: { field?: FieldInputProps<any> }) => (
                    <InputWithIcon
                      icon={<IoDocumentTextOutline />}
                      iconSize="23px"
                      field={field}
                    />
                  )}
                />
                {registerError.some(
                  (error: any) => error.field === "cib_nirf_number"
                ) ? (
                  registerError
                    .filter((error: any) => error.field === "cib_nirf_number")
                    .map((error: any, index: any) => (
                      <ErrorText key={index}>
                        <p>{error.msgs}</p>
                      </ErrorText>
                    ))
                ) : (
                  <ErrorText>
                    <ErrorMessage name="cib_nirf_number" />
                  </ErrorText>
                )}
              </ContainerInputsRow>
              <ContainerInputsRow>
                <TitleInput>{t("deed-number")}</TitleInput>
                <Field
                  type="text"
                  name="deed_number"
                  render={({ field }: { field?: FieldInputProps<any> }) => (
                    <InputWithIcon
                      icon={<IoDocumentTextOutline />}
                      iconSize="23px"
                      field={field}
                    />
                  )}
                />
                {registerError.some(
                  (error: any) => error.field === "deed_number"
                ) ? (
                  registerError
                    .filter((error: any) => error.field === "deed_number")
                    .map((error: any, index: any) => (
                      <ErrorText key={index}>
                        <p>{error.msgs}</p>
                      </ErrorText>
                    ))
                ) : (
                  <ErrorText>
                    <ErrorMessage name="deed_number" />
                  </ErrorText>
                )}
              </ContainerInputsRow>
            </ContainerInputs>

            <div>
              <TitleInput> {t("address")} </TitleInput>
              <GooglePlacesAutocomplete
                selectProps={{
                  placeholder: "",
                  onChange: handleSelect,
                }}
                apiKey="AIzaSyAwSbFuF7Z1E1Lzj1LlEVt4WEyDxxDpsG0"
              />
            </div>
            {registerError.some((error: any) => error.field === "address") ? (
              registerError
                .filter((error: any) => error.field === "address")
                .map((error: any, index: any) => (
                  <ErrorText key={index}>
                    <p>{error.msgs}</p>
                  </ErrorText>
                ))
            ) : (
              <ErrorText>
                <ErrorMessage name="address" />
              </ErrorText>
            )}

            <TitleInput> {t("description")}</TitleInput>
            <Field
              type="text"
              name="description"
              render={({ field }: { field?: FieldInputProps<any> }) => (
                <InputWithIcon
                  icon={<GiWoodStick />}
                  iconSize="23px"
                  field={field}
                />
              )}
            />

            {registerError.some(
              (error: any) => error.field === "description"
            ) ? (
              registerError
                .filter((error: any) => error.field === "description")
                .map((error: any, index: any) => (
                  <ErrorText key={index}>
                    <p>{error.msgs}</p>
                  </ErrorText>
                ))
            ) : (
              <ErrorText>
                <ErrorMessage name="description" />
              </ErrorText>
            )}
            <div style={{ marginLeft: -30 }}>
              <TitleComponent icon={CiHome} text={t("file")} />
            </div>

            <div
              style={{
                display: "flex",
              }}
            >
              <ButtonOutFile>
                <div style={{ marginLeft: 10, marginTop: 3 }}>
                  <TitleInput> CIB </TitleInput>
                  <Field
                    type="file"
                    id="cib_nirf_file"
                    name="cib_nirf_file"
                    accept="image/*"
                    onChange={handleFileChangeCib}
                  />
                </div>
                {registerError.some(
                  (error: any) => error.field === "cib_nirf_file"
                ) ? (
                  registerError
                    .filter((error: any) => error.field === "cib_nirf_file")
                    .map((error: any, index: any) => (
                      <ErrorText key={index}>
                        <p>{error.msgs}</p>
                      </ErrorText>
                    ))
                ) : (
                  <ErrorText>
                    <ErrorMessage name="cib_nirf_file" />
                  </ErrorText>
                )}
              </ButtonOutFile>

              <div style={{ marginLeft: 10 }} />
              <ButtonOutFile>
                <div style={{ marginLeft: 10, marginTop: 3 }}>
                  <TitleInput> {t("proof-residence")} </TitleInput>
                  <Field
                    type="file"
                    id="address_file"
                    name="address_file"
                    accept="image/*"
                    onChange={handleFileChangeAddress}
                  />
                </div>
                {registerError.some(
                  (error: any) => error.field === "address_file"
                ) ? (
                  registerError
                    .filter((error: any) => error.field === "address_file")
                    .map((error: any, index: any) => (
                      <ErrorText key={index}>
                        <p>{error.msgs}</p>
                      </ErrorText>
                    ))
                ) : (
                  <ErrorText>
                    <ErrorMessage name="address_file" />
                  </ErrorText>
                )}
              </ButtonOutFile>
            </div>

            <div
              style={{
                display: "flex",
              }}
            >
              <ButtonOutFile>
                <div style={{ marginLeft: 10, marginTop: 3 }}>
                  <TitleInput> {t("image-property")} </TitleInput>
                  <Field
                    type="file"
                    id="images_file"
                    name="images_file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                  {registerError.some(
                    (error: any) => error.field === "images_file"
                  ) ? (
                    registerError
                      .filter((error: any) => error.field === "images_file")
                      .map((error: any, index: any) => (
                        <ErrorText key={index}>
                          <p>{error.msgs}</p>
                        </ErrorText>
                      ))
                  ) : (
                    <ErrorText>
                      <ErrorMessage name="images_file" />
                    </ErrorText>
                  )}
                </div>
              </ButtonOutFile>
              <div style={{ marginLeft: 10 }} />
              <ButtonOutFile>
                <div style={{ marginLeft: 10, marginTop: 3 }}>
                  <TitleInput> {t("deed-file")} </TitleInput>
                  <Field
                    type="file"
                    id="deed_file"
                    name="deed_file"
                    accept="image/*"
                    onChange={handleFileChangeDeed}
                  />
                  {registerError.some(
                    (error: any) => error.field === "deed_file"
                  ) ? (
                    registerError
                      .filter((error: any) => error.field === "deed_file")
                      .map((error: any, index: any) => (
                        <ErrorText key={index}>
                          <p>{error.msgs}</p>
                        </ErrorText>
                      ))
                  ) : (
                    <ErrorText>
                      <ErrorMessage name="deed_file" />
                    </ErrorText>
                  )}
                </div>
              </ButtonOutFile>
            </div>
            <ErrorText>
              <p>{mensageFiles}</p>
            </ErrorText>
            <Buttons>
              <ButtonContainer>
                <Button
                  type="submit"
                  style={{
                    backgroundColor: colors.primary,
                    color: "white",
                    fontFamily: "Josefin Bold",
                    fontSize: "18px",
                    marginBottom: "180px",
                  }}
                >
                  {isLoading ? (
                    <BeatLoader className="spinner" size={8} color={"#fff"} />
                  ) : (
                    "Salvar"
                  )}
                </Button>
              </ButtonContainer>
            </Buttons>
          </Form>
        </Formik>
        {/* <ContainerInputs>
     

   

   



          <InputContainer>
            <TitleInput>Descrição</TitleInput>
            <InputWithIcon icon={RiLockPasswordLine} iconSize="23px" />
          </InputContainer>
        </ContainerInputs> */}
      </Container>

      <Footer
        companyName="Ecolife"
        address="Caruaru - PE"
        email="contato@suaempresa.com"
        phone="(11) 1234-5678"
        socialMedia={socialMediaLinks}
      />
    </div>
  );
}
