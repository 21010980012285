import React from "react";
import styled from "styled-components";
import colors from "../../utils/colors";

interface ModalProps {
  onClose: () => void;
  onConfirm: () => void;
}

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
`;

const ModalTitle = styled.h2`
  font-family: "Josefin Bold";
  color: ${colors.black};
  font-size: 16px;
  margin-bottom: 10px;
`;

const ModalText = styled.p`
  margin-bottom: 20px;
  font-family: "Josefin Sans";
  color: ${colors.black};
  font-size: 14px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-left: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Josefin Bold";
  color: ${colors.black};
`;

const CheckProducts: React.FC<ModalProps> = ({ onClose, onConfirm }) => {
  return (
    <ModalContainer>
      <ModalContent>
        <ModalTitle>
          Atenção, Você só pode adicionar itens de um estabelecimento por vez
        </ModalTitle>
        <ModalText>Deseja esvaziar a sacola e adicionar este item?</ModalText>
        <ButtonGroup>
          <Button onClick={onClose}>Sair</Button>
          <Button onClick={onConfirm}>Esvaziar sacola e adicionar</Button>
        </ButtonGroup>
      </ModalContent>
    </ModalContainer>
  );
};

export default CheckProducts;
