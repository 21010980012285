import React from "react";
import { ResponsiveButton } from "./styled";

interface CustomButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  color?: string;
}

export const CustomButton: React.FC<CustomButtonProps> = ({ text, color }) => {
  return (
    <div>
      <ResponsiveButton color={color} type="submit">
        {text}
      </ResponsiveButton>
    </div>
  );
};
