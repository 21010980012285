import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const ContainerCard = styled.div`
  margin: 30px;
  display: flex;
  flex-wrap: wrap;
`;

export const ContentCard = styled.div`
  margin-right: 10px;
  margin-bottom: 30px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 colunas com largura igual */
  margin-left: 30px;
  min-height: 56.8vh;
`;

export const ContentModalOne = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentModalTwo = styled.div`
  background-color: #48c85d;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  border-radius: 5px;
  margin-bottom: 20px;
  height: 40px;
`;

export const ContentModalTwoTitle = styled.h1`
  text-align: center;
  align-self: center;
  justify-self: center;
  color: #000;
  font-weight: bold;
  padding: 10px;
`;

export const ContentModalTwoSubTitle = styled.p`
  color: #48c85d;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const ContentModalThree = styled.div`
  border-width: 1px;
  border-color: #000;
  padding: 5px;
  max-height: 21px;
  width: 20px;
  justify-content: center;
  align-items: center;
`;

export const ContentModalThreeTitle = styled.p`
  font-size: 9px;
  text-align: center;
  font-weight: bold;
  justify-content: center;
  align-items: center;
`;
