import TitleComponent from "../../components/title/title";
import { CiHome } from "react-icons/ci";
import { Container, ContainerCard, ContentCard, GridContainer } from "./style";
import Navbar from "../../components/navbar/navbar";
import Card from "../../components/card/card";
import casa from "../../../src/assets/images/casa.png";
import axios from "axios";
import { baseBuyer, baseSeller } from "../../utils/server/api";
import { useEffect, useState } from "react";
import { config } from "../../utils/server/header";
import { useNavigate } from "react-router-dom";
import { Title } from "../choice/style";
import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";
import { useFavoritesData } from "../../hooks/serviceFavorite";

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin:
    "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

export default function Favorites() {
  const { data, isLoading } = useFavoritesData();
  const typeUser = localStorage.getItem("userType");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleDetails = (item: any) => {
    const queryString = `?item=${encodeURIComponent(JSON.stringify(item))}`;

    if (typeUser === "vendedor") {
      navigate(`/detailsPropertySeller${queryString}`);
    } else {
      navigate(`/detailsPropertyBuy${queryString}`);
    }
  };

  return (
    <Container>
      <Navbar />

      <TitleComponent icon={CiHome} text={t("favorites")} />
      <GridContainer>
        {!isLoading && (
          <>
            {data && data?.length === 0 && <Title>{t("no-favorites")}</Title>}
            {data?.map((item: any, index: any) => (
              <ContentCard key={index}>
                <Card
                  image={item?.product?.image}
                  title={item?.product?.name}
                  description={item?.product?.description}
                  hectares={item?.product?.hectares_amount}
                  is_favorite={true}
                  onClick={() => handleDetails(item)}
                />
              </ContentCard>
            ))}
          </>
        )}

        {isLoading && <span>Carregando...</span>}
      </GridContainer>
      <Footer
        companyName="Ecolife"
        address="Caruaru - PE"
        email="contato@suaempresa.com"
        phone="(11) 1234-5678"
        socialMedia={socialMediaLinks}
      />
    </Container>
  );
}
