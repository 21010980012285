import styled from "styled-components";
import colors from "../../utils/colors";

export const Container = styled.div`
  display: flex;
  /* border: 1px solid ${colors.gray}; */
  width: 98%;
  height: 100px;
  display: flex;
  margin: 10px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 40px;
  //justify-content: space-around;
`;

export const Content = styled.div`
  width: 20%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const Text = styled.h1`
  font-family: "Josefin Bold";
  color: ${colors.text};
  font-size: 18px;
  margin-top: 5px;
`;

export const VerticalLine = styled.div`
  height: 30%; /* Altura da linha em relação ao container pai */
  border-left: 1px solid #000; /* Cor e largura da linha */
`;
