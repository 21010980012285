import Navbar from "../../components/navbar/navbar";
import TitleComponent from "../../components/title/title";
import { CiHome } from "react-icons/ci";
import {
  CardWrapper,
  Container,
  ContainerCard,
  ContentCard,
  Input,
  SearchButton,
  SearchContainer,
} from "./style";
import { FaSearch } from "react-icons/fa";
import Card from "../../components/card/card";
import casa from "../../assets/images/casa.png";
import { useEffect, useState } from "react";
import { config } from "../../utils/server/header";
import axios from "axios";
import { baseBuyer, baseEngineer } from "../../utils/server/api";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import { usePropertyDataSearch } from "../../hooks/servideSearch";
const LOCATION = {
  latitude: -8.3523948,
  longitude: -36.0291571,
  raio: 150000,
};

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin:
    "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

export default function Search() {
  const location = useLocation();
  const [product, setProduct] = useState<any[]>([]);
  const [originalProduct, setOriginalProduct] = useState<any[]>([]);
  const navigate = useNavigate();
  const typeUser = localStorage.getItem("userType");
  const token = localStorage.getItem("token");
  const searchParams = new URLSearchParams(location.search);
  const item = JSON.parse(decodeURIComponent(searchParams.get("item") || "{}"));
  const [searchTerm, setSearchTerm] = useState(item);
  const [loading, setLoading] = useState(false);
  const [userLocation, setUserLocation] = useState<any>({
    latitude: null,
    longitude: null,
  });
  const { data, isLoading } = usePropertyDataSearch(item);

  const handleDetails = (item: any) => {
    const queryString = `?item=${encodeURIComponent(JSON.stringify(item))}`;

    if (typeUser === "vendedor" && token) {
      navigate(`/detailsPropertySeller${queryString}`);
    } else if (typeUser === "cliente") {
      navigate(`/detailsPropertyBuy${queryString}`);
    } else if (typeUser === "engenheiro") {
      const news = { ...item, news: true };
      const string = `?item=${encodeURIComponent(JSON.stringify(news))}`;
      navigate(`/detailsPropertyEngineer${string}`);
    } else {
      navigate(`/detailsPropertyBuy${queryString}`);
    }
  };

  return (
    <Container>
      <Navbar />

      <TitleComponent icon={CiHome} text="Todas as Propriedades" />
      <ContainerCard>
        {isLoading ? (
          <h1>carregando...</h1>
        ) : (
          <>
            {data?.length === 0 ? (
              <h1>não encontrado</h1>
            ) : (
              data?.map((item: any, index: any) => (
                <ContentCard key={index}>
                  <Card
                    image={item.image}
                    title={item.name}
                    description={item.description}
                    hectares={item.hectares_amount}
                    onClick={() => handleDetails(item)}
                  />
                </ContentCard>
              ))
            )}
          </>
        )}
      </ContainerCard>

      <Footer
        companyName="Ecolife"
        address="Caruaru - PE"
        email="contato@suaempresa.com"
        phone="(11) 1234-5678"
        socialMedia={socialMediaLinks}
      />
    </Container>
  );
}
