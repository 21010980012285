import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MiniRetangulo, Quantidade } from "./style";

interface AmountProps {
  price: number;
  onSomaTotalChange: (novoTotal: number) => void;
  quantidade: number;
  setQuantidade: (novaQuantidade: number) => void;
}

const Amount: React.FC<AmountProps> = ({
  price,
  onSomaTotalChange,
  quantidade,
  setQuantidade,
}) => {
  const [quantidadeLocal, setQuantidadeLocal] = useState(quantidade);

  useEffect(() => {
    setQuantidadeLocal(quantidade);
  }, [quantidade]);

  const handleMais = () => {
    setQuantidadeLocal((prevQuantidade) => {
      const novaQuantidade = prevQuantidade + 1;
      onSomaTotalChange(price * novaQuantidade);
      setQuantidade(novaQuantidade);
      return novaQuantidade;
    });
  };

  const handleMenos = () => {
    if (quantidadeLocal > 0) {
      setQuantidadeLocal((prevQuantidade) => {
        const novaQuantidade = prevQuantidade - 1;
        onSomaTotalChange(price * novaQuantidade);
        setQuantidade(novaQuantidade); // Atualiza a quantidade no componente pai
        return novaQuantidade;
      });
    }
  };

  return (
    <MiniRetangulo>
      <button onClick={handleMenos}>-</button>
      <Quantidade>{quantidadeLocal}</Quantidade>
      <button onClick={handleMais}>+</button>
    </MiniRetangulo>
  );
};

export default Amount;
