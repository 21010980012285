import styled from "styled-components";
import br from "../../assets/images/brasil.png";
import us from "../../assets/images/estados-unidos.png";
import { FaMapMarkedAlt, FaWindowClose } from "react-icons/fa";
import { FaHouse, FaHeart, FaCartShopping, FaNewspaper } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BsFillBuildingFill } from "react-icons/bs";

interface SidebarContainerProps {
  isOpenMenu: boolean;
}

const SidebarContainer = styled.div<SidebarContainerProps>`
  width: ${({ isOpenMenu }) => (isOpenMenu ? "250px" : "0")};
  position: fixed;
  z-index: 1;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;

  button {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #48c85d;
    display: block;
    transition: 0.3s;
  }

  button:hover {
    color: #42a551;
  }
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  color: #48c85d; // Cor do ícone, ajuste conforme necessário

  &:hover {
    color: #42a551; // Cor do ícone ao passar o mouse, ajuste conforme necessário
  }
`;

const CloseIcon = styled(FaWindowClose)`
  font-size: 24px; // Ajuste o tamanho do ícone conforme necessário
`;

export const TranslateImage = styled.img`
  width: 30px;
  height: auto;
`;

const StyledButton = styled.button`
  display: flex;
  flex-direction: row; // Garante que os itens sejam dispostos horizontalmente
  justify-content: space-between;
  align-items: center; // Alinha os itens verticalmente no centro
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #48c85d;
  transition: 0.3s;
  width: 100%; // Certifique-se de que o botão tenha largura suficiente

  &:hover {
    color: #42a551;
  }
`;

export const NavLinks = styled.ul`
  display: flex;
  flex-direction: column; // Garante que os itens sejam dispostos horizontalmente
  list-style: none;
  gap: 1rem;
  width: 100%;
  text-decoration: none;
  color: #48c85d;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  border-top: white 1px solid;

  :hover {
    color: #42a551;
    transition: 0.4s;
  }
`;

export const NavItem = styled.li`
  display: flex;
  width: auto;
  justify-content: start;
  align-items: start;
  padding-top: 1rem;
  gap: 0.5rem;
  cursor: pointer;
  text-decoration: none;
  font-size: 20px;
  color: #48c85d;
  transition: 0.3s;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface SidebarProps {
  isOpenMenu: boolean;
  toggle: () => void;
  openModal: () => void;
  changeLanguage: (lng: string) => void;
}

const Sidebar = ({
  isOpenMenu,
  toggle,
  changeLanguage,
  openModal,
}: SidebarProps) => {
  const navigate = useNavigate();
  const type = localStorage.getItem("userType");
  const token = localStorage.getItem("token");
  const { t } = useTranslation();
  const handleClickClose = () => {
    toggle();
  };
  const handleLanguageChange = (lng: string) => {
    changeLanguage(lng);
  };

  return (
    <SidebarContainer isOpenMenu={isOpenMenu}>
      <CloseButton onClick={handleClickClose}>
        <CloseIcon />
      </CloseButton>
      <NavLinks>
        <NavItem
          onClick={() => {
            token
              ? type === "vendedor"
                ? navigate("/mySpaceSeller")
                : type === "engenheiro"
                ? navigate("/mySpaceEngineer")
                : navigate("/mySpaceBuy")
              : navigate("/homeBuy");
          }}
        >
          <p>{t("my-space")}</p>
          <BsFillBuildingFill />
        </NavItem>
        <NavItem
          onClick={() => {
            token
              ? type === "vendedor"
                ? navigate("/homeSeller")
                : type === "engenheiro"
                ? (window.location.href = "/homeEngineer")
                : navigate("/homeBuy")
              : navigate("/homeBuy");
            console.log("token", token);
          }}
        >
          <p> Home</p>
          <FaHouse />
        </NavItem>
        {type === "vendedor" && token && (
          <NavItem onClick={() => navigate("/registerProperty")}>
            <p>{t("register-property")}</p>
            <BsFillBuildingFill />
          </NavItem>
        )}

        {type === "cliente" && token && (
          <NavItem onClick={() => navigate("/map")}>
            <p> {t("map")} </p>
            <FaMapMarkedAlt />
          </NavItem>
        )}

        <NavItem onClick={() => navigate("/news")}>
          <p>{t("news")}</p>
          <FaNewspaper />
        </NavItem>

        {type === "vendedor" || (type === "engenheiro" && token) ? null : (
          <>
            <NavItem onClick={token ? () => navigate("/favorites") : openModal}>
              {t("favorites")}
              <FaHeart />
            </NavItem>
            <NavItem onClick={token ? () => navigate("/cart") : openModal}>
              <p>{t("cart")}</p>
              <FaCartShopping />
            </NavItem>
          </>
        )}

        <NavItem onClick={() => handleLanguageChange("pt")}>
          <p>{t("portuguese")}</p>
          <TranslateImage src={br} alt="Bandeira do brasil" />
        </NavItem>

        <NavItem onClick={() => handleLanguageChange("en")}>
          <p>{t("english")}</p>
          <TranslateImage src={us} alt="Bandeira Americana" />
        </NavItem>
      </NavLinks>
    </SidebarContainer>
  );
};

export default Sidebar;
