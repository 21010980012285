import React from "react";
import styled from "styled-components";
import colors from "../../utils/colors";
import { useTranslation } from "react-i18next";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  imageUrl: string;
}

const ModalOverlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

const ModalTitle = styled.h2`
  margin-bottom: 10px;
  font-family: "Josefin Bold";
  color: #000;
`;

const ModalImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

const CloseButton = styled.button`
  background-color: ${colors.primary};
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 4px;

  font-family: "Josefin Bold";
  color: #fff;
`;

const ModalImages: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  imageUrl,
}) => {
  const { t } = useTranslation();
  return (
    <ModalOverlay isOpen={isOpen}>
      <ModalContent>
        <ModalTitle>{title}</ModalTitle>
        <ModalImage src={imageUrl} alt="Modal Image" />
        <CloseButton onClick={onClose}>{t("close")}</CloseButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ModalImages;
