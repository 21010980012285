import { useQuery } from "@tanstack/react-query";
import { baseBuyer } from "../../src/utils/server/api";
import axios from "axios";

const fetchProperty = async () => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const response = await axios.get(`${baseBuyer}/product`, config);

  return response.data;
};

export function usePropertyData() {
  const query = useQuery({
    queryKey: ["property"],
    queryFn: fetchProperty,
  });

  return query;
}
