import { useQuery } from "@tanstack/react-query";
import { baseBuyer } from "../../src/utils/server/api";
import axios from "axios";

const fetchProperty = async () => {
 try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(`${baseBuyer}/order`, config)
    return response.data;
 } catch (error) {
    console.error(error);
 }
};

// Corrigindo a função para ser um Hook personalizado
export function useBuyPropertyData() {
 const query = useQuery({
    queryFn: fetchProperty,
    queryKey: ["buyProperty"],
 });

 return query;
}
