import TitleComponent from "../../components/title/title";
import { CiHome } from "react-icons/ci";
import { Container, ContainerCard, ContentCard, GridContainer } from "./style";
import Navbar from "../../components/navbar/navbar";
import Card from "../../components/card/card";
import casa from "../../../src/assets/images/casa.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseBuyer } from "../../utils/server/api";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";
import { usePropertyData } from "../../hooks/serviceProduct";

const token = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // Defina o tipo de conteúdo conforme necessário
  },
};

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin:
    "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

export default function News() {
  const { data, isLoading } = usePropertyData();
  const navigate = useNavigate();
  const typeUser = localStorage.getItem("userType");
  const token = localStorage.getItem("token");
  const { t } = useTranslation();
  const handleDetails = (item: any) => {
    const queryString = `?item=${encodeURIComponent(JSON.stringify(item))}`;

    if (typeUser === "vendedor" && token) {
      navigate(`/detailsPropertySeller${queryString}`);
    } else if (typeUser === "cliente") {
      navigate(`/detailsPropertyBuy${queryString}`);
    } else if (typeUser === "engenheiro") {
      const news = { ...item, news: true };
      const string = `?item=${encodeURIComponent(JSON.stringify(news))}`;
      navigate(`/detailsPropertyEngineer${string}`);
    } else {
      navigate(`/detailsPropertyBuy${queryString}`);
    }
  };

  return (
    <Container>
      <Navbar />

      <TitleComponent icon={CiHome} text={t("news")} />
      <GridContainer>
        {!isLoading && (
          <>
            {data?.map((item: any, index: any) => (
              <ContentCard key={index}>
                <Card
                  image={item?.image}
                  title={item?.name}
                  description={item?.description}
                  hectares={item?.hectares_amount}
                  onClick={() => handleDetails(item)}
                />
              </ContentCard>
            ))}
          </>
        )}
        {isLoading && <span>Carregando...</span>}
      </GridContainer>

      <Footer
        companyName="Ecolife"
        address="Caruaru - PE"
        email="contato@suaempresa.com"
        phone="(11) 1234-5678"
        socialMedia={socialMediaLinks}
      />
    </Container>
  );
}
