import React, { useEffect } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import "./App.css";
import AppRoutes from "./routes/routes";
import "../src/utils/i18n/i18n";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { AuthProvider } from "./context/AuthContext";
import { CartProvider } from "./context/CartContext";
function App() {
  useEffect(() => {
    // Ajuste o zoom para 90% ao carregar a página
    (document.body.style as any).zoom = "90%";
  }, []); // Executa apenas uma vez após a montagem do componente

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <CartProvider>
          <ChakraProvider>
            <AppRoutes />
          </ChakraProvider>
        </CartProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
