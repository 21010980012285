import styled from "styled-components";
import Select from "react-select";

export const Container = styled.div`
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const MarginBottom = styled.div`
  margin-bottom: 20px;
`;

export const SelectContainer = styled.div`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;

  width: 578px;
  height: 50px;

  @media only screen and (max-width: 480px) {
    width: 100%;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }

  &:focus {
    outline: none;
  }
`;

export const Content = styled.div`
  flex-direction: row;
  display: flex;

  @media only screen and (max-width: 768px) {
    /* Estilos específicos para tablets */
    /* Por exemplo, reduzir a largura do Container */
    width: 80%;
    flex-direction: column;
  }

  @media only screen and (max-width: 914px) {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 480px) {
    /* Estilos específicos para celulares */
    /* Por exemplo, reduzir ainda mais a largura do Container */
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const SelectDrop = styled(Select)`
  width: 578px;
  height: 50px;
`;

export const Space = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100vh; /* 100% da altura da tela */
`;

export const Img = styled.img`
  width: 30%;
  height: 50%;
  margin-left: 5px;
  cursor: pointer;

  @media only screen and (min-width: 425px) {
    margin-top: 1rem;
    height: 35%; /* Altura desejada da linha */
    width: 100%; /* A largura será 100% do contêiner pai */
  }

  @media only screen and (min-width: 768px) {
    margin-top: 2rem;
    height: 30%; /* Altura desejada da linha */
    width: 100%; /* A largura será 100% do contêiner pai */
  }

  @media only screen and (min-width: 1024px) {
    height: 45%; /* Altura desejada da linha */
    width: 100%; /* A largura será 100% do contêiner pai */
  }
  @media only screen and (min-width: 1440px) {
    height: 50%; /* Altura desejada da linha */
    width: 100%; /* A largura será 100% do contêiner pai */
  }
  @media only screen and (min-width: 1441px) {
    height: 50%; /* Altura desejada da linha */
    width: 100%; /* A largura será 100% do contêiner pai */
  }
`;

export const ButtonImg = styled.button`
  font-family: "Josefin Sans";
  color: #fff;
  font-size: 18px;
  margin-top: 15px;
  background-color: #48c85d;
  border-radius: 8px;
  height: 60px;
  left: 0;
  top: 0;
  width: 550px;
  border: 0;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @media only screen and (min-width: 425px) {
    height: 7%;
    width: 100%;
  }

  @media only screen and (min-width: 768px) {
    height: 7%;
    width: 100%;
  }

  @media only screen and (min-width: 1024px) {
    height: 10%;
    width: 100%;
  }
  @media only screen and (min-width: 1440px) {
    height: 7%;
    width: 100%;
  }

  @media only screen and (min-width: 1441px) {
    height: 5%;
    width: 90%;
  }
`;

export const DivImg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

export const Icon = styled.span<{ iconSize?: string }>`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: ${({ iconSize }) => iconSize || "16px"};
`;

export const LinhaVertical = styled.div`
  height: 90vh; /* Ajuste a altura da linha conforme necessário */
  width: 0.2px;
  background-color: #c4c4c4; /* Cor da linha */
  margin-top: 5vh;
  border-radius: 4px;

  @media only screen and (max-width: 768px) {
    width: 80%;
    flex-direction: column;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 914px) {
    width: 80%;
    height: 0.5px;
    flex-direction: column;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 480px) {
    width: 80%;
    flex-direction: column;
  }
`;

export const Title = styled.h1`
  font-family: "Josefin Bold";
  color: #515151;
  font-size: 25px;
  margin-top: 90px;
  margin-bottom: 20px;

  @media only screen and (max-width: 480px) {
    font-size: 20px;
    margin-top: 600px;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 20px;
    margin-top: 650px;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 915px) {
    font-size: 20px;
    margin-top: 650px;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 768px) and (max-height: 1024px) {
    margin-bottom: 50px;
  }
`;

export const ErrorText = styled.div`
  color: red;
  margin-top: 5px;
  font-family: "Josefin Bold";
  font-size: 12px;
`;

export const TitleInput = styled.h1`
  font-family: "Josefin Bold";
  color: #515151;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const ButtonEnter = styled.button`
  background-color: #48c85d;
  border-radius: 8px;
  height: 45px;
  left: 0;
  top: 0;
  width: 350px;
  border: 0;
  margin-bottom: 20px;
`;

export const TitleButtonEnter = styled.h1`
  font-family: "Josefin Bold";
  color: #fff;
  font-size: 18px;
`;
