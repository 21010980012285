import React, { useEffect, useState } from "react";
import {
  ButtonEnter,
  ButtonRegister,
  Container,
  Content,
  ForgotPasswordLinkContainer,
  InputContainer,
  LinhaVertical,
  Space,
  TitleButtonEnter,
  TitleButtonRegister,
  TitleInput,
  Title,
  MarginBottom,
  Column,
  ErrorText,
  DivImg,
  Img,
  ButtonImg,
} from "./style";
import { useTranslation } from "react-i18next";
import { BeatLoader } from "react-spinners";
import {
  Formik,
  Field,
  Form,
  FormikHelpers,
  FieldInputProps,
  ErrorMessage,
} from "formik";
import { MdOutlineEmail } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { useAuth } from "../../context/AuthContext";
import InputWithIcon from "../../components/input/input";
import logo from "../../assets/images/Logo_Ecolife_onda__1_-removebg-preview.png";
import { ContainerMargin } from "../cart/style";
import { Center } from "@chakra-ui/react";

import authValidation from "../../utils/validation/authValidation";
import { ToastContainer } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function ForgetPassword() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { forgetPassword, warning, forgetPasswordSeller } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const userType = localStorage.getItem("userType");

  const changeLanguage = (lng: string) => {
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng); // Atualiza o idioma no i18n
    //i18n.changeLanguage(lg);
  };
  useEffect(() => {
    const fetchLanguage = async () => {
      const storedLanguage = await localStorage.getItem("language");
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage); // Atualiza o idioma no i18n
      }
    };

    fetchLanguage();
  }, []);

  const initialValues = {
    email: "",
  };

  const handleSubmit = async (
    values: typeof initialValues,
    actions: FormikHelpers<typeof initialValues>
  ) => {
    try {
      setIsLoading(true);
      if (userType === "cliente") {
        await forgetPassword(values);
        setIsLoading(false);
      } else {
        await forgetPasswordSeller(values);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Erro:", (error as Error).message);
    }
  };
  return (
    <Container>
      <Content>
        <Space onClick={() => navigate("/")}>
          <DivImg>
            <Img src={logo} alt="Minha Imagem" />
            <ButtonImg onClick={() => navigate("/")}>
              {t("voltar para tela inicial")}
            </ButtonImg>
          </DivImg>
        </Space>
        <LinhaVertical />
        <Space>
          <Title>Recuperar Senha</Title>
          <ContainerMargin>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              <Form>
                <TitleInput>E-mail</TitleInput>
                <Field
                  type="text"
                  name="email"
                  render={({ field }: { field?: FieldInputProps<any> }) => (
                    <InputWithIcon
                      icon={<MdOutlineEmail />}
                      iconSize="23px"
                      field={field}
                    />
                  )}
                />
                <ErrorText>
                  <ErrorMessage name="email" />
                </ErrorText>

                <MarginBottom />

                {warning && <ErrorText>{warning}</ErrorText>}
                <MarginBottom />
                <Center>
                  <ButtonEnter type="submit">
                    {isLoading ? (
                      <BeatLoader className="spinner" size={8} color={"#fff"} />
                    ) : (
                      <TitleButtonEnter>Enviar</TitleButtonEnter>
                    )}
                  </ButtonEnter>
                </Center>
              </Form>
            </Formik>
          </ContainerMargin>
        </Space>
      </Content>
      <ToastContainer />
    </Container>
  );
}
