import React, { useEffect, useState } from "react";
import {
  ButtonEnter,
  Container,
  Content,
  LinhaVertical,
  Space,
  TitleButtonEnter,
  TitleInput,
  Title,
  MarginBottom,
  ErrorText,
  SelectContainer,
  DivImg,
  Img,
  ButtonImg,
} from "./style";
import { useAuth } from "../../../context/AuthContext";
import {
  Formik,
  Field,
  Form,
  FieldInputProps,
  ErrorMessage,
  FormikHelpers,
} from "formik";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { FaRegAddressCard } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { CiPhone } from "react-icons/ci";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { MdCorporateFare } from "react-icons/md";
import { FaWpforms } from "react-icons/fa";

import InputWithIcon from "../../../components/input/input";
import logo from "../../../assets/images/Logo_Ecolife_onda__1_-removebg-preview.png";
import ButtonSelect from "../../../components/button-select/ButtonSelect";
import { Center } from "@chakra-ui/react";
import registerValidationPF from "../../../utils/validation/registerValidationPF";
import registerValidationPJ from "../../../utils/validation/registerValidationPJ";
import { BeatLoader } from "react-spinners";
import MaskedInput from "../../../components/input/inputMask";
import { useNavigate } from "react-router-dom";
export default function RegisterBuy() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { register, registerError } = useAuth();
  const [selectedOption, setSelectedOption] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const handleSelectChange = (option: number) => {
    setSelectedOption(option);
  };

  const handleSubmit = async (
    values: typeof initialValues,
    actions: FormikHelpers<typeof initialValues>
  ) => {
    try {
      const data = {
        type: values.type,
        name: values.name,
        surname: values.surname,
        document: values.document.replace(/[^0-9]/g, ""),
        gender: values.gender,
        phone: values.phone.replace(/[^0-9]/g, ""),
        cnpj: values.cnpj.replace(/[^0-9]/g, ""),
        corporate_name: values.corporate_name,
        state_registration: values.state_registration,
        email: values.email,
        password: values.password_hash,
        password_hash: values.password_hash,
        confirm_password: values.confirm_password,
      };
      setIsLoading(true);
      await register(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Erro ao cadastrar:", (error as Error).message);
    }
    // Aqui você pode realizar a lógica de envio para o contexto

    // Reinicie o estado do Formik
    // actions.resetForm();
  };

  useEffect(() => {
    // Use useEffect apenas para atualizar selectedOption
    // Não atualize initialValues diretamente aqui
  }, [selectedOption]);

  const initialValues = {
    type: selectedOption,
    name: "",
    surname: "",
    document: "",
    gender: "",
    phone: "",
    cnpj: "",
    corporate_name: "",
    state_registration: "",
    email: "",
    password_hash: "",
    confirm_password: "",
  };

  return (
    <Container>
      <Content>
        <Space onClick={() => navigate("/")}>
          <DivImg>
            <Img src={logo} alt="Minha Imagem" />
            <ButtonImg onClick={() => navigate("/")}>
              {t("voltar para tela inicial")}
            </ButtonImg>
          </DivImg>
        </Space>
        <LinhaVertical />

        <Space>
          <Title>{t("comment-buyer")}</Title>
          <ButtonSelect onSelectChange={handleSelectChange} />

          <Formik
            initialValues={initialValues}
            validationSchema={
              selectedOption === 1 ? registerValidationPF : registerValidationPJ
            }
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            <Form>
              <TitleInput> {t("name")}</TitleInput>
              <Field
                type="text"
                name="name"
                render={({ field }: { field?: FieldInputProps<any> }) => (
                  <InputWithIcon
                    icon={<FaWpforms />}
                    iconSize="23px"
                    field={field}
                  />
                )}
              />

              {registerError.some((error: any) => error.field === "name") ? (
                // Se houver um erro específico, exiba apenas o erro personalizado
                registerError
                  .filter((error: any) => error.field === "name")
                  .map((error: any, index: any) => (
                    <ErrorText key={index}>
                      <p>{error.msgs}</p>
                    </ErrorText>
                  ))
              ) : (
                // Caso contrário, exiba o erro do Formik
                <ErrorText>
                  <ErrorMessage name="name" />
                </ErrorText>
              )}

              <MarginBottom />
              {selectedOption === 1 && (
                <>
                  <TitleInput> {t("surname")}</TitleInput>
                  <Field
                    type="text"
                    name="surname"
                    render={({ field }: { field?: FieldInputProps<any> }) => (
                      <InputWithIcon
                        icon={<HiOutlineUserCircle />}
                        iconSize="23px"
                        field={field}
                      />
                    )}
                  />
                  <ErrorText>
                    <ErrorMessage name="surname" />
                  </ErrorText>
                </>
              )}

              <MarginBottom />

              {selectedOption === 1 && (
                <>
                  <TitleInput>CPF</TitleInput>
                  <Field
                    type="text"
                    name="document"
                    render={({ field }: { field?: FieldInputProps<any> }) => (
                      <MaskedInput
                        field={field}
                        icon={<FaRegAddressCard />}
                        mask="999.999.999-99"
                        iconSize="23px"
                        placeholder=""
                      />
                    )}
                  />

                  {registerError.some(
                    (error: any) => error.field === "document"
                  ) ? (
                    // Se houver um erro específico, exiba apenas o erro personalizado
                    registerError
                      .filter((error: any) => error.field === "document")
                      .map((error: any, index: any) => (
                        <ErrorText key={index}>
                          <p>{error.msgs}</p>
                        </ErrorText>
                      ))
                  ) : (
                    // Caso contrário, exiba o erro do Formik
                    <ErrorText>
                      <ErrorMessage name="document" />
                    </ErrorText>
                  )}
                </>
              )}

              <MarginBottom />

              <TitleInput> {t("phone")}</TitleInput>
              <Field
                type="text"
                name="phone"
                render={({ field }: { field?: FieldInputProps<any> }) => (
                  <MaskedInput
                    field={field}
                    icon={<CiPhone />}
                    mask="(99) 99999-9999"
                    iconSize="23px"
                    placeholder=""
                  />
                )}
              />

              {registerError.some((error: any) => error.field === "phone") ? (
                // Se houver um erro específico, exiba apenas o erro personalizado
                registerError
                  .filter((error: any) => error.field === "phone")
                  .map((error: any, index: any) => (
                    <ErrorText key={index}>
                      <p>{error.msgs}</p>
                    </ErrorText>
                  ))
              ) : (
                // Caso contrário, exiba o erro do Formik
                <ErrorText>
                  <ErrorMessage name="phone" />
                </ErrorText>
              )}

              <MarginBottom />

              {selectedOption === 2 && (
                <>
                  <TitleInput>CNPJ</TitleInput>
                  <Field
                    type="text"
                    name="cnpj"
                    render={({ field }: { field?: FieldInputProps<any> }) => (
                      <MaskedInput
                        field={field}
                        icon={<FaRegAddressCard />}
                        mask="99.999.999/9999-99"
                        iconSize="23px"
                        placeholder=""
                      />
                    )}
                  />

                  {registerError.some(
                    (error: any) => error.field === "cnpj"
                  ) ? (
                    // Se houver um erro específico, exiba apenas o erro personalizado
                    registerError
                      .filter((error: any) => error.field === "cnpj")
                      .map((error: any, index: any) => (
                        <ErrorText key={index}>
                          <p>{error.msgs}</p>
                        </ErrorText>
                      ))
                  ) : (
                    // Caso contrário, exiba o erro do Formik
                    <ErrorText>
                      <ErrorMessage name="cnpj" />
                    </ErrorText>
                  )}
                </>
              )}

              <MarginBottom />

              {selectedOption === 2 && (
                <>
                  <TitleInput> {t("corporate-reason")}</TitleInput>
                  <Field
                    type="text"
                    name="corporate_name"
                    render={({ field }: { field?: FieldInputProps<any> }) => (
                      <InputWithIcon
                        icon={<MdCorporateFare />}
                        iconSize="23px"
                        field={field}
                      />
                    )}
                  />

                  {registerError.some(
                    (error: any) => error.field === "corporate_name"
                  ) ? (
                    // Se houver um erro específico, exiba apenas o erro personalizado
                    registerError
                      .filter((error: any) => error.field === "corporate_name")
                      .map((error: any, index: any) => (
                        <ErrorText key={index}>
                          <p>{error.msgs}</p>
                        </ErrorText>
                      ))
                  ) : (
                    // Caso contrário, exiba o erro do Formik
                    <ErrorText>
                      <ErrorMessage name="corporate_name" />
                    </ErrorText>
                  )}
                </>
              )}

              <MarginBottom />

              {selectedOption === 2 && (
                <>
                  <TitleInput> {t("state-registration")}</TitleInput>
                  <Field
                    type="number"
                    name="state_registration"
                    render={({ field }: { field?: FieldInputProps<any> }) => (
                      <InputWithIcon
                        icon={<MdCorporateFare />}
                        iconSize="23px"
                        field={field}
                        onChange={(e) => {
                          if (field) {
                            const numericValue = e.target.value.replace(
                              /\D/g,
                              ""
                            );
                            field.onChange({
                              target: { value: numericValue, name: field.name },
                            });
                          }
                        }}
                        onBlur={(e) => {
                          if (field) {
                            const numericValue = e.target.value.replace(
                              /\D/g,
                              ""
                            );
                            field.onChange({
                              target: { value: numericValue, name: field.name },
                            });
                          }
                        }}
                      />
                    )}
                  />

                  {registerError.some(
                    (error: any) => error.field === "state_registration"
                  ) ? (
                    // Se houver um erro específico, exiba apenas o erro personalizado
                    registerError
                      .filter(
                        (error: any) => error.field === "state_registration"
                      )
                      .map((error: any, index: any) => (
                        <ErrorText key={index}>
                          <p>{error.msgs}</p>
                        </ErrorText>
                      ))
                  ) : (
                    // Caso contrário, exiba o erro do Formik
                    <ErrorText>
                      <ErrorMessage name="state_registration" />
                    </ErrorText>
                  )}
                </>
              )}

              <MarginBottom />

              <TitleInput>{t("email")}</TitleInput>
              <Field
                type="text"
                name="email"
                render={({ field }: { field?: FieldInputProps<any> }) => (
                  <InputWithIcon
                    icon={<MdOutlineEmail />}
                    iconSize="23px"
                    field={field}
                  />
                )}
              />

              {registerError.some((error: any) => error.field === "email") ? (
                // Se houver um erro específico, exiba apenas o erro personalizado
                registerError
                  .filter((error: any) => error.field === "email")
                  .map((error: any, index: any) => (
                    <ErrorText key={index}>
                      <p>{error.msgs}</p>
                    </ErrorText>
                  ))
              ) : (
                // Caso contrário, exiba o erro do Formik
                <ErrorText>
                  <ErrorMessage name="email" />
                </ErrorText>
              )}

              <MarginBottom />
              <TitleInput>{t("password")}</TitleInput>
              <Field
                type="password"
                name="password_hash"
                render={({ field }: { field: FieldInputProps<any> }) => (
                  <InputWithIcon
                    icon={<RiLockPasswordLine />}
                    iconSize="23px"
                    field={field}
                    type="password"
                  />
                )}
              />

              <ErrorText>
                <ErrorMessage name="password" />
              </ErrorText>

              <MarginBottom />

              <TitleInput>{t("confirm-password")}</TitleInput>
              <Field
                type="text"
                name="confirm_password"
                render={({ field }: { field?: FieldInputProps<any> }) => (
                  <InputWithIcon
                    icon={<RiLockPasswordLine />}
                    iconSize="23px"
                    field={field}
                    type="password"
                  />
                )}
              />

              <ErrorText>
                <ErrorMessage name="confirm_password" />
              </ErrorText>

              <MarginBottom />

              <Center>
                <ButtonEnter type="submit">
                  {isLoading ? (
                    <BeatLoader className="spinner" size={8} color={"#fff"} />
                  ) : (
                    <TitleButtonEnter>{t("register")}</TitleButtonEnter>
                  )}
                </ButtonEnter>
              </Center>
            </Form>
          </Formik>
        </Space>
      </Content>
      <ToastContainer />
    </Container>
  );
}
