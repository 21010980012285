import styled from "styled-components";
import InputMask from "react-input-mask";

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const Icon = styled.span<{ iconSize?: string }>`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: ${({ iconSize }) => iconSize || "16px"};
`;

export const InputField = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-left: 35px; /* Ajuste o valor conforme necessário para dar espaço ao ícone */
  width: 578px;
  height: 50px;

  @media only screen and (max-width: 480px) {
    width: 100%;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }

  &:focus {
    outline: none;
  }
`;

export const InputFieldMask = styled(InputMask)`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-left: 35px; /* Ajuste o valor conforme necessário para dar espaço ao ícone */
  width: 578px;
  height: 50px;

  @media only screen and (max-width: 480px) {
    width: 100%;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }

  &:focus {
    outline: none;
  }
`;
