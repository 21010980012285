import Card from "../../../components/card/card";
import Navbar from "../../../components/navbar/navbar";
import TitleComponent from "../../../components/title/title";
import {
  Container,
  ContainerCard,
  ContentCard,
  ContentModalOne,
  ContentModalThree,
  ContentModalThreeTitle,
  ContentModalTwo,
  ContentModalTwoSubTitle,
  ContentModalTwoTitle,
  GridContainer,
} from "./style";
import casa from "../../../../src/assets/images/casa.png";
import { CiHome } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/footer/footer";
import { useTranslation } from "react-i18next";
import { useBuyPropertyData } from "../../../hooks/serviceMySpaceBuy";
import { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { FaCheck, FaStar } from "react-icons/fa";
import axios from "axios";
import { baseBuyer } from "../../../utils/server/api";
import { config } from "../../../utils/server/header";

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin:
    "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

export default function MySpaceBuy() {
  const { data, isLoading } = useBuyPropertyData();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const handleCardClick = async (item: any) => {
    console.log(item, "detalhes");

    try {
      const response = await axios.get(
        `${baseBuyer}/order/${item.order_id}`,
        config()
      );
      console.log(response.data, "response");
      setSelectedItem(response.data);
      onOpen();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <Modal isCentered size="xl" isOpen={isOpen} onClose={onClose} motionPreset="scale" >
        <ModalOverlay opacity={1} style={{ width: "100%", height: "100%" }}/>
        <ModalContent>
          <ModalHeader>Detalhes do pedido</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedItem && (
              <>
                <ContentModalOne>
                  <FaStar />
                  <p style={{ marginLeft: "5px" }}>
                    Número do pedido #{selectedItem[0].id}
                  </p>
                </ContentModalOne>

                <ContentModalOne
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <FaStar />
                  <p style={{ marginLeft: "5px" }}>
                    {" "}
                    Realizado em{" "}
                    {selectedItem[0].started_at
                      ? selectedItem[0].started_at
                      : "----------"}
                  </p>
                </ContentModalOne>
                <ContentModalTwo>
                  <ContentModalTwoTitle>
                    Recebemos seu pedido
                  </ContentModalTwoTitle>
                </ContentModalTwo>
                <ContentModalTwoSubTitle>
                  {selectedItem[0].company?.business_name}
                </ContentModalTwoSubTitle>
                <ContentModalOne
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <ContentModalThree>
                    <ContentModalThreeTitle>
                      {selectedItem[0].orderProducts[0].quantity}
                    </ContentModalThreeTitle>{" "}
                  </ContentModalThree>
                  <p style={{ marginLeft: "5px" }}>
                    {selectedItem[0].orderProducts[0].product_name}
                  </p>
                </ContentModalOne>
                <p>Subtotal: {selectedItem[0].subtotalAsCurrency}</p>
                <p>Total: {selectedItem[0].totalAsCurrency}</p>
                <p>Status: {selectedItem[0].statusText}</p>
              </>
            )}
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Container>
        <Navbar />

        <TitleComponent icon={CiHome} text={t("my-space")} />
        {!isLoading && (
          <>
            <GridContainer>
              {data?.map((item: any, _: any) =>
                item.orderProducts.map((item: any, i: any) => (
                  <ContentCard key={i}>
                    <Card
                      image={item?.imageUrl}
                      title={item?.product_name}
                      description={item?.product_description}
                      hectares={item?.hectares_amount}
                      status={item?.status}
                      onClick={() => handleCardClick(item)}
                    />
                  </ContentCard>
                ))
              )}
            </GridContainer>
          </>
        )}
        {isLoading && (
          <GridContainer>
            <p>{t("Carregando ...")}</p>
          </GridContainer>
        )}

        <Footer
          companyName="Ecolife"
          address="Caruaru - PE"
          email="contato@suaempresa.com"
          phone="(11) 1234-5678"
          socialMedia={socialMediaLinks}
        />
      </Container>
    </div>
  );
}
