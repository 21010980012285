import { Route, Routes, Navigate } from "react-router-dom";

import Choice from "../screens/choice/choice";
import LoginBuy from "../screens/login/login-buy/login-buy";
import LoginSeller from "../screens/login/login-seller/login-seller";
import LoginEngineer from "../screens/login/login-engineer/login-engineer";
import RegisterBuy from "../screens/register/register-buy/register-buy";
import RegisterSeller from "../screens/register/register-seller/register-seller";
import RegisterEngineer from "../screens/register/register-engineer/register-engineer";
import HomeBuy from "../screens/home/home-buy/home-buy";
import HomeSeller from "../screens/home/home-seller/home-seller";
import HomeEngineer from "../screens/home/home-engineer/home-engineer";
import DetailsPropertyBuy from "../screens/details-property/buy/details-property";
import DetailsPropertySeller from "../screens/details-property/seller/details-property";
import DetailsPropertyEngineer from "../screens/details-property/engineer/details-property";
import Cart from "../screens/cart/cart";
import ProfileBuy from "../screens/profile/profile-buy/profile-buy";
import ProfileSeller from "../screens/profile/profile-seller/profile-seller";
import News from "../screens/news/news";
import Favorites from "../screens/favorites/favorites";
import Settings from "../screens/settings/settings";
import Search from "../screens/search/search";
import Map from "../screens/map/map";
import MySpaceBuy from "../screens/my-space/buy/my-space";
import MySpaceSeller from "../screens/my-space/seller/my-space";
import MySpaceEngineer from "../screens/my-space/engineer/my-space";
import RegisterProperty from "../screens/register-property/register-property";
import PrivateRoute from "./PrivateRoute";
import CreditCardPage from "../screens/credit-card/credit-card";
import ForgetPassword from "../screens/forget-password/forget-password";
import AllProducts from "../screens/allProducts/all-products";
import TypeOfBankDetails from "../screens/typeOfBankDetails/typeOfBankDetails";
import PixForm from "../screens/typeOfBankDetails/pix/pixForm";
import TedForm from "../screens/typeOfBankDetails/ted/tedForm";

import CheckProperty from "../screens/check-property-engineer/check-property";
import AllProductsEngineer from "../screens/allProducts/allProductsEngineer/all-products";
export default function AppRoutes() {
  const token = localStorage.getItem("token");
  const userType = localStorage.getItem("userType");
  return (
    <Routes>
      <Route
        path="/"
        element={
          // token ? (
          //   userType === "cliente" ? (
          <HomeBuy />
          //     ) : userType === "vendedor" ? (
          //       <HomeSeller />
          //     ) : (
          //       <Navigate to="/" />
          //     )
          //   ) : userType === "" ? (
          //     <Choice />
          //   ) : (
          //     <Choice />
          //   )
        }
      />
      <Route path="/loginSeller" element={<LoginSeller />} />
      <Route path="/loginEngineer" element={<LoginEngineer />} />
      <Route path="/loginBuy" element={<LoginBuy />} />
      <Route path="/registerBuy" element={<RegisterBuy />} />
      <Route path="/registerSeller" element={<RegisterSeller />} />
      <Route path="/registerEngineer" element={<RegisterEngineer />} />
      <Route path="/choice" element={<Choice />} />
      <Route path="/forgetPassword" element={<ForgetPassword />} />
      <Route path="/news" element={<News />} />
      <Route path="/favorites" element={<Favorites />} />
      <Route path="/search" element={<Search />} />
      <Route path="/map" element={<Map />} />
      <Route path="/detailsPropertyBuy" element={<DetailsPropertyBuy />} />
      <Route path="/allProducts" element={<AllProducts />} />
      <Route path="/allProductsEngineer" element={<AllProductsEngineer />} />
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/homeBuy" element={<HomeBuy />} />
        <Route path="/profile" element={<ProfileBuy />} />
        <Route path="/typeOfBankDetails" element={<TypeOfBankDetails />} />
        <Route path="/pixForm" element={<PixForm />} />
        <Route path="/tedForm" element={<TedForm />} />
        <Route path="/homeSeller" element={<HomeSeller />} />
        <Route path="/homeEngineer" element={<HomeEngineer />} />
        <Route
          path="/detailsPropertySeller"
          element={<DetailsPropertySeller />}
        />
        <Route
          path="/detailsPropertyEngineer"
          element={<DetailsPropertyEngineer />}
        />
        <Route path="/cart" element={<Cart />} />
        <Route path="/profileSeller" element={<ProfileSeller />} />

        <Route path="/settings" element={<Settings />} />

        <Route path="/mySpaceBuy" element={<MySpaceBuy />} />
        <Route path="/mySpaceSeller" element={<MySpaceSeller />} />
        <Route path="/mySpaceEngineer" element={<MySpaceEngineer />} />
        <Route path="/registerProperty" element={<RegisterProperty />} />
        <Route path="/creditCard" element={<CreditCardPage />} />
        <Route path="/checkProperty" element={<CheckProperty />} />
      </Route>
    </Routes>
  );
}
