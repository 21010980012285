import styled from "styled-components";
import colors from "../../../utils/colors";
export const Container = styled.div`
  width: 100%;
`;

export const ContainerTitle = styled.div`
  display: flex;
  margin: 30px;
`;

export const Title = styled.h1`
  font-family: "Josefin Bold";
  color: #000;
  font-size: 22px;
`;

export const ContainerBanner = styled.div`
  margin: 30px;
`;

export const Banner = styled.img`
  max-width: 100%;
  min-width: 600px;
  height: auto;
  display: block; /* Isso evita que a imagem adote margens indesejadas */
  margin: 0 auto; /* Centraliza a imagem se estiver dentro de um contêiner flexível */
  border-radius: 8px;
`;

export const ContainerContent = styled.div`
  margin: 30px;

  align-items: center;
  flex-direction: column;
`;

export const ContainerMargin = styled.div`
  margin: 30px;
`;

export const Text = styled.p`
  font-family: "Josefin Bold";
  color: #000;
  font-size: 22px;
`;

export const Button = styled.button`
  background-color: ${colors.primary};
  border-radius: 8px;
  height: 35px;
  width: 80%;

  border-right-color: "#000";

  font-family: "Josefin Bold";
  color: #fff;
`;

export const ButtonBlue = styled.button`
  background-color: blue;
  border-radius: 8px;
  height: 35px;
  width: 80%;
  border-right-color: "#000";
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Josefin Bold";
  color: #fff;
`;

export const TitleInformation = styled.h1`
  font-family: "Josefin Bold";
  color: #515151;
  font-size: 22px;
`;

export const TextInformation = styled.h1`
  font-family: "Josefin Sans";
  color: #000;
  font-size: 15px;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #000;
`;

export const Price = styled.h1`
  font-family: "Josefin Bold";
  color: #000;
  font-size: 16px;
`;

export const SubPrice = styled.h1`
  font-family: "Josefin Bold";
  color: #000;
  font-size: 18px;
  margin-bottom: 30px;
`;
export const DescriptionPrice = styled.p`
  font-family: "Josefin Bold";
  color: #000;
  font-size: 14px;
`;

export const ContainerFlex = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

export const MiniRetangulo = styled.div`
  width: 100px;
  height: 40px;
  border: 1px solid ${colors.primary};
  border-radius: 4px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
`;

export const Quantidade = styled.span`
  font-weight: bold;
`;

export const SomaTotal = styled.span`
  font-weight: bold;
`;

export const MarginRight = styled.div`
  margin-right: 20px;
`;

export const ContentInformation = styled.div`
  display: flex;
  min-height: 56.8vh;
`;

export const ContainerInformation = styled.div`
  flex-direction: column;
  width: 40%;
`;
