import styled from "styled-components";

export const ProfilePic = styled.img`
  width: 100px; /* Ajuste conforme necessário */
  height: 100px; /* Ajuste conforme necessário */
  border-radius: 50%;
  object-fit: cover;
`;

export const Name = styled.div`
  font-size: 20px; /* Ajuste conforme necessário */
  margin-top: 10px;

  font-family: "Josefin Bold";
`;

export const Surname = styled.p`
  font-size: 14px;
  font-family: "Josefin Sans";
`;

export const OrderButtons = styled.div`
  margin-top: 20px;
  flex-direction: column;
  display: grid;
`;

// Form pix style

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 65.7vh;
  padding-top: 1rem;
`;

export const Title = styled.h1`
  font-family: "Josefin Sans";
  font-size: 14px;
  color: #000;
  margin-bottom: 2px;
  font-size: 22px;
  margin-top: 10px;
  font-weight: bold;
  text-decoration: underline;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  gap: 1rem;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const ButtonBack = styled.button`
  display: inline-block;
  background-color: #b3aaaa;
  color: white;
  font-size: 1em;
  padding: 0.5em 1em;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #9f9696;
  }

  @media (max-width: 768px) {
    padding: 0.5em 1em;
  }
`;

export const SubmitButton = styled.button`
  width: 100%;
  display: inline-block;
  background-color: #48c85d;
  color: white;
  font-size: 1em;
  padding: 0.5em 1em;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2f853a;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0.5em 1em;
  }
`;

export const CustomError = styled.p`
  font-family: "Josefin Sans";
  color: red;
  font-size: 16px;
  margin-top: 5px;
`;
