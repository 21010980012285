import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import colors from "../../utils/colors";
import banner from "../../assets/images/carrossel1.png"; // Importe a imagem diretamente
import banner1 from "../../assets/images/carrossel2.png";
const SlideImage = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 70%;
  height: auto; /* Defina a altura desejada para os slides */
`;

const Sliders = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Habilita o autoplay
    autoplaySpeed: 3000, // Define o intervalo de tempo em milissegundos (3 segundos)
  };

  return (
    <Slider {...settings}>
      <div>
        <SlideImage src={banner} alt="Slide 1" />
      </div>
      <div>
        <SlideImage src={banner1} alt="Slide 2" />
      </div>
    </Slider>
  );
};

export default Sliders;
