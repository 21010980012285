import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const TitleInput = styled.h1`
  font-family: "Josefin Bold";
  color: #515151;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 30px;
`;

export const Input = styled.input`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  margin-right: 8px;
  font-size: 14px;
  width: 90%;

  ::placeholder {
    font-family: "Josefin Sans";
    color: "#515151";
  }
`;

export const SearchButton = styled.button`
  background-color: #4caf50; /* Cor verde */
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px;
  width: 10%;
  justify-content: center;
  display: flex;
  align-items: center;
`;

export const Placeholder = styled.span`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-family: "Josefin Sans";
  color: "#515151";
  pointer-events: none;
`;

export const ContainerCard = styled.div`
  margin: 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  min-height: 53.4vh;
`;

export const CardWrapper = styled.div`
  display: inline-block; /* Faz os itens ficarem na mesma linha */
  margin-right: 16px; /* Adiciona um espaçamento entre os itens */
`;

export const ContentCard = styled.div`
  margin-right: 10px;
  margin-bottom: 30px;
`;
