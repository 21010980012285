import NavBar from "../../components/navbar/navbar";
import axios from "axios";
import InputWithIcon from "../../components/input/input";
import {
  Container,
  MarginBottom,
  StyledInputWithIcon,
  TitleInput,
} from "./style";
import { MdOutlineEmail } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { HiOutlineUserCircle, HiPhone, HiDocument } from "react-icons/hi2";
import { FaUserCircle } from "react-icons/fa";
import { Button } from "../../components/button/button";
import { useAuth } from "../../context/AuthContext";
import { Formik, Field, Form, FieldInputProps } from "formik";
import { baseBuyer, baseEngineer, baseSeller } from "../../utils/server/api";
import { useEffect, useState } from "react";
import { Center } from "@chakra-ui/react";
import { useMeData } from "../../hooks/serviceMe";
import { useNavigate } from "react-router-dom";
import { ErrorText } from "../register/register-buy/style";

export default function Settings() {
  const { data, isLoading } = useMeData();
  const type = localStorage.getItem("userType");
  const navigate = useNavigate();
  const { register, registerError } = useAuth();
  const [error, setError] = useState([]);

  useEffect(() => {
    console.log(data, "componente montado");
  }, []);

  const initialValues = {
    email: data?.email || "",
    name: data?.name || "",
    surname: data?.surname || "",
    cnpj: data?.cnpj || "",
    state_registration: data?.state_registration || "",
    documentText: data?.documentText || "",
    rg: data?.rg || "",
    phoneText: data?.phoneText || "",
    business_name: data?.business_name || "",
    corporate_name: data?.corporate_name || "",
    old_password: "",
    new_password: "",
    confirm_password: "",
  };

  const handleSubmit = async (values: typeof initialValues) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Defina o tipo de conteúdo conforme necessário
      },
    };

    const data = {
      email: values.email,
      name: values.name,
      surname: values.surname,
      business_name: values.business_name,
      corporate_name: values.corporate_name,
      old_password: values.old_password,
      new_password: values.new_password,
      confirm_password: values.confirm_password,
    };
    try {
      console.log(values);
      if (type === "vendedor") {
        const response = await axios.put(`${baseSeller}/me`, data, config);
        if (response.data.errors && Array.isArray(response.data.errors)) {
          console.log(response.data.errors);
          setError(response.data.errors);
        } else {
          console.log("Nenhum erro encontrado na resposta");
          navigate("/profile");
        }
      } else if (type === "cliente") {
        const response = await axios.put(`${baseBuyer}/me`, data, config);
        if (response.data.errors && Array.isArray(response.data.errors)) {
          console.log(response.data.errors);
          setError(response.data.errors);
        } else {
          console.log("Nenhum erro encontrado na resposta");
          navigate("/profile");
        }
      } else {
        const response = await axios.put(`${baseEngineer}/me`, data, config);
        if (response.data.errors && Array.isArray(response.data.errors)) {
          console.log(response.data.errors);
          setError(response.data.errors);
        } else {
          console.log("Nenhum erro encontrado na resposta");
          navigate("/profile");
        }
      }
    } catch (error) {
      console.error("Erro ao editar:", (error as Error).message);
    }
  };
  return (
    <div>
      <NavBar />
      <Container>
        <FaUserCircle size={100} />
        <MarginBottom />
        <MarginBottom />
        {isLoading && <Center>Carregando...</Center>}
        {!isLoading && (
          <>
            {" "}
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              <Form>
                {type === "vendedor" && (
                  <>
                    <MarginBottom />
                    {!!data?.corporate_name && type === "vendedor" ? (
                      <>
                        <TitleInput>Razão Social</TitleInput>
                        <Field
                          type="text"
                          name={data.corporate_name ? "corporate_name" : ""}
                          render={({
                            field,
                          }: {
                            field?: FieldInputProps<any>;
                          }) => (
                            <InputWithIcon
                              icon={<HiOutlineUserCircle />}
                              iconSize="23px"
                              field={field}
                            />
                          )}
                        />
                        <MarginBottom />
                      </>
                    ) : (
                      <>
                        <TitleInput>Nome</TitleInput>
                        <Field
                          type="text"
                          name={data?.business_name ? "business_name" : ""}
                          render={({
                            field,
                          }: {
                            field?: FieldInputProps<any>;
                          }) => (
                            <InputWithIcon
                              icon={<HiOutlineUserCircle />}
                              iconSize="23px"
                              field={field}
                            />
                          )}
                        />
                        <MarginBottom />
                      </>
                    )}
                  </>
                )}
                {type === "cliente" && (
                  <>
                    <TitleInput>Nome</TitleInput>
                    <Field
                      type="text"
                      name={data?.corporate_name ? "corporate_name" : "name"}
                      render={({ field }: { field?: FieldInputProps<any> }) => (
                        <InputWithIcon
                          icon={<HiOutlineUserCircle />}
                          iconSize="23px"
                          field={field}
                        />
                      )}
                    />
                    <MarginBottom />
                    {!!data?.surname && type === "cliente" && (
                      <>
                        <TitleInput>Sobrenome</TitleInput>
                        <Field
                          type="text"
                          name={data?.surname ? "surname" : ""}
                          render={({
                            field,
                          }: {
                            field?: FieldInputProps<any>;
                          }) => (
                            <InputWithIcon
                              icon={<HiOutlineUserCircle />}
                              iconSize="23px"
                              field={field}
                            />
                          )}
                        />
                        <MarginBottom />
                      </>
                    )}
                    {!!data?.corporate_name && type === "cliente" && (
                      <>
                        <TitleInput>Razão Social</TitleInput>
                        <Field
                          type="text"
                          name={data?.corporate_name ? "corporate_name" : ""}
                          render={({
                            field,
                          }: {
                            field?: FieldInputProps<any>;
                          }) => (
                            <InputWithIcon
                              icon={<HiOutlineUserCircle />}
                              iconSize="23px"
                              field={field}
                            />
                          )}
                        />
                        <MarginBottom />
                      </>
                    )}
                  </>
                )}
                {type === "engenheiro" && (
                  <>
                    <TitleInput>Nome</TitleInput>
                    <Field
                      type="text"
                      name="name"
                      render={({ field }: { field?: FieldInputProps<any> }) => (
                        <InputWithIcon
                          icon={<HiOutlineUserCircle />}
                          iconSize="23px"
                          field={field}
                        />
                      )}
                    />
                    <MarginBottom />

                    <TitleInput>Sobrenome</TitleInput>
                    <Field
                      type="text"
                      name="surname"
                      render={({ field }: { field?: FieldInputProps<any> }) => (
                        <InputWithIcon
                          icon={<HiOutlineUserCircle />}
                          iconSize="23px"
                          field={field}
                        />
                      )}
                    />
                    <MarginBottom />

                    <TitleInput>RG</TitleInput>
                    <Field
                      type="text"
                      name={data?.rg ? "rg" : ""}
                      render={({ field }: { field?: FieldInputProps<any> }) => (
                        <InputWithIcon
                          icon={<HiOutlineUserCircle />}
                          iconSize="23px"
                          field={field}
                          disabled
                        />
                      )}
                    />
                    <MarginBottom />
                  </>
                )}

                <TitleInput>Telefone</TitleInput>
                <Field
                  type="text"
                  name={data?.phoneText ? "phoneText" : ""}
                  render={({ field }: { field?: FieldInputProps<any> }) => (
                    <InputWithIcon
                      icon={<HiPhone />}
                      iconSize="23px"
                      field={field}
                    />
                  )}
                />
                <MarginBottom />
                {!!data?.cnpj || !!data?.documentText ? (
                  <>
                    <TitleInput>Cnpj/Cpf</TitleInput>
                    <Field
                      type="text"
                      name={data?.cnpj ? "cnpj" : "documentText"}
                      render={({ field }: { field?: FieldInputProps<any> }) => (
                        <StyledInputWithIcon
                          icon={<HiDocument />}
                          iconSize="23px"
                          field={field}
                          disabled
                        />
                      )}
                    />
                    <MarginBottom />
                  </>
                ) : null}

                {!!data?.state_registration ? (
                  <>
                    <TitleInput>Inscricão estadual</TitleInput>
                    <Field
                      type="text"
                      name={
                        data?.state_registration ? "state_registration" : ""
                      }
                      render={({ field }: { field?: FieldInputProps<any> }) => (
                        <StyledInputWithIcon
                          icon={<HiDocument />}
                          iconSize="23px"
                          field={field}
                          disabled
                        />
                      )}
                    />
                    <MarginBottom />
                  </>
                ) : null}

                <TitleInput>E-mail</TitleInput>
                <Field
                  type="email"
                  name="email"
                  render={({ field }: { field?: FieldInputProps<any> }) => (
                    <InputWithIcon
                      icon={<MdOutlineEmail />}
                      iconSize="23px"
                      field={field}
                    />
                  )}
                />
                {error.some((error: any) => error.field === "email") &&
                  // Se houver um erro específico, exiba apenas o erro personalizado
                  error
                    .filter((error: any) => error.field === "email")
                    .map((error: any, index: any) => (
                      <ErrorText key={index}>
                        <p>{error.msgs}</p>
                      </ErrorText>
                    ))}
                <MarginBottom />
                <TitleInput>Senha antiga</TitleInput>
                <Field
                  type="password"
                  name="old_password"
                  render={({ field }: { field: FieldInputProps<any> }) => (
                    <InputWithIcon
                      icon={<RiLockPasswordLine />}
                      iconSize="23px"
                      field={field}
                      type="password"
                    />
                  )}
                />
                {error.some((error: any) => error.field === "old_password") &&
                  // Se houver um erro específico, exiba apenas o erro personalizado
                  error
                    .filter((error: any) => error.field === "old_password")
                    .map((error: any, index: any) => (
                      <ErrorText key={index}>
                        <p>{error.msgs}</p>
                      </ErrorText>
                    ))}
                <MarginBottom />
                <TitleInput>Nova Senha</TitleInput>
                <Field
                  type="password"
                  name="new_password"
                  render={({ field }: { field: FieldInputProps<any> }) => (
                    <InputWithIcon
                      icon={<RiLockPasswordLine />}
                      iconSize="23px"
                      field={field}
                      type="password"
                    />
                  )}
                />
                {error.some((error: any) => error.field === "new_password") &&
                  // Se houver um erro específico, exiba apenas o erro personalizado
                  error
                    .filter((error: any) => error.field === "new_password")
                    .map((error: any, index: any) => (
                      <ErrorText key={index}>
                        <p>{error.msgs}</p>
                      </ErrorText>
                    ))}
                <MarginBottom />
                <TitleInput>Confirme sua nova senha</TitleInput>
                <Field
                  type="password"
                  name="confirm_password"
                  render={({ field }: { field: FieldInputProps<any> }) => (
                    <InputWithIcon
                      icon={<RiLockPasswordLine />}
                      iconSize="23px"
                      field={field}
                      type="password"
                    />
                  )}
                />
                {error.some(
                  (error: any) => error.field === "confirm_password"
                ) &&
                  // Se houver um erro específico, exiba apenas o erro personalizado
                  error
                    .filter((error: any) => error.field === "confirm_password")
                    .map((error: any, index: any) => (
                      <ErrorText key={index}>
                        <p>{error.msgs}</p>
                      </ErrorText>
                    ))}
                <MarginBottom />
                <Center>
                  <Button
                    colorButton="#48C85D"
                    colorText="white"
                    title="Atualizar"
                  />
                </Center>
              </Form>
            </Formik>
          </>
        )}

        {/* <ContainerInputs>
          <InputContainer>
            <TitleInput>Nome</TitleInput>
            <InputWithIcon icon={HiOutlineUserCircle} iconSize="23px" />
          </InputContainer>

          <InputContainer>
            <TitleInput>Email</TitleInput>
            <InputWithIcon icon={MdOutlineEmail} iconSize="23px" />
          </InputContainer>

          <InputContainer>
            <TitleInput>CPF</TitleInput>
            <InputWithIcon icon={FaWpforms} iconSize="23px" />
          </InputContainer>

          <InputContainer>
            <TitleInput>Telefone</TitleInput>
            <InputWithIcon icon={CiPhone} iconSize="23px" />
          </InputContainer>

          <InputContainer>
            <TitleInput>Senha Antiga</TitleInput>
            <InputWithIcon icon={RiLockPasswordLine} iconSize="23px" />
          </InputContainer>

          <InputContainer>
            <TitleInput>Senha Nova</TitleInput>
            <InputWithIcon icon={RiLockPasswordLine} iconSize="23px" />
          </InputContainer>
          <InputContainer>
            <TitleInput>Confirme nova senha</TitleInput>
            <InputWithIcon icon={RiLockPasswordLine} iconSize="23px" />
          </InputContainer>
        </ContainerInputs> */}
        {/* <Buttons>
          <Button colorButton="#48C85D" colorText="white" title="Atualizar" />
          <div style={{ marginLeft: "10px" }} />
          <Button
            colorButton="#EE7575"
            colorText="white"
            title="Excluir Conta"
          />
        </Buttons> */}
      </Container>
    </div>
  );
}
