import { FaHouse } from "react-icons/fa6";
import { Container, Text, Title } from "./style";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function MoreCard() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const typeUser = localStorage.getItem("userType");
  return (
    <Container>
      <FaHouse size={30} color="#2a9d8f" />
      <Title>{t("warning-footer")}</Title>
      <Text
        onClick={() =>
          typeUser !== "engenheiro"
            ? navigate("/allProducts")
            : (window.location.href = "/allProductsEngineer")
        }
      >
        {t("view-more")}
      </Text>
    </Container>
  );
}
