import NavBar from "../../../components/navbar/navbar";
import { Container, Name, OrderButtons, ProfilePic, Surname } from "./style";
import profile from "../../../assets/images/new.png";
import { ButtonOutline } from "../../../components/button/button";
import { MdOutlineSettings } from "react-icons/md";
import { BsBank } from "react-icons/bs";
import { IoIosHeartEmpty } from "react-icons/io";
import { IoLogOutOutline } from "react-icons/io5";
import { useAuth } from "../../../context/AuthContext";
import { FaCreditCard, FaUserCircle } from "react-icons/fa";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseBuyer, baseSeller } from "../../../utils/server/api";
import Footer from "../../../components/footer/footer";
import { useTranslation } from "react-i18next";
import { useMeData } from "../../../hooks/serviceMe";

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin:
    "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

export default function Profile() {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const { data, isLoading } = useMeData();
  const [type, setType] = useState<any>();

  useEffect(() => {
    const userType = localStorage.getItem("userType");
    setType(userType);
  }, []);

  return (
    <div>
      <NavBar />
      <Container>
        <FaUserCircle size={100} />
        {!isLoading && (
          <>
            {data?.corporate_name ? (
              <Name>{data?.corporate_name}</Name>
            ) : (
              <Name>
                {type === "cliente"
                  ? data?.name
                  : type === "vendedor"
                  ? data?.business_name
                  : data?.fullName}
              </Name>
            )}

            <Surname>{data?.email}</Surname>
            <OrderButtons>
              <ButtonOutline
                onClick={() => (window.location.href = "/settings")}
                icon={MdOutlineSettings}
                title={t("settings")}
              />
              {type === "cliente" && (
                <ButtonOutline
                  icon={FaCreditCard}
                  onClick={() => (window.location.href = "/creditCard")}
                  title="Pagamentos"
                />
              )}
              {type === "vendedor" && (
                <ButtonOutline
                  icon={BsBank}
                  onClick={() => (window.location.href = "/typeOfBankDetails")}
                  title="Dados bancários"
                />
              )}

              <ButtonOutline
                icon={IoLogOutOutline}
                title={t("logout")}
                onClick={logout}
              />
            </OrderButtons>
          </>
        )}

        {isLoading && <p>{t("Carregando ...")}</p>}
      </Container>

      <Footer
        companyName="Ecolife"
        address="Caruaru - PE"
        email="contato@suaempresa.com"
        phone="(11) 1234-5678"
        socialMedia={socialMediaLinks}
      />
    </div>
  );
}
