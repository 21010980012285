const colors = {
  primary: "#48C85D",
  secondary: "#F5F5F5",
  black: "#000",
  gray: "#8F8F8F",
  text: "#34495e",
  textGary: "#515151",
  warning: "#F5C723",
  danger: "#EE7575",
  success: "#52B788",
};

export default colors;
