import styled from "styled-components";
import colors from "../../utils/colors";

// export const NavbarContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 10px;
//   border-bottom: 1px solid #ccc;
// `;

// // export const Logo = styled.img`
// //   width: 55px;
// //   height: 40px;
// // `;

// export const ButtonLogin = styled.button`
//   background-color: ${colors.primary};
//   border-radius: 4px;
//   width: 100px;
//   height: 30px;
// `;

// export const TextLogin = styled.p`
//   font-family: "Josefin Bold";
//   font-size: 16px;
//   color: #fff;
// `;

// export const UserInfo = styled.div`
//   display: flex;
//   align-items: center;
// `;

// export const ProfileImage = styled.img`
//   width: 30px;
//   height: 30px;
//   border-radius: 50%;
//   margin-right: 8px;
// `;

// export const OptionsContainer = styled.div`
//   display: flex;
//   justify-content: space-around;
//   padding: 10px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Efeito de elevação (box shadow) */
//   background-color: ${colors.primary};
// `;

// export const Option = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: white;
//   border-radius: 4px;
//   width: 210px;
//   height: 30px;
// `;

// export const OptionText = styled.span`
//   font-family: "Josefin Bold";
//   font-size: 16px;
//   margin-left: 5px;
//   padding-top: 5px;
// `;

// export const TitleInput = styled.h1`
//   font-family: "Josefin Bold";
//   color: #515151;
// `;

// export const InputContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-bottom: 25px;
// `;

// export const SearchContainer = styled.div`
//   display: flex;
//   align-items: center;
//   margin: 30px;
// `;

// export const Input = styled.input`
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   padding: 8px;
//   margin-right: 8px;
//   font-size: 14px;
//   width: 90%;

//   ::placeholder {
//     font-family: "Josefin Sans";
//     color: "#515151";
//   }
// `;

// // export const SearchButton = styled.button`
// //   background-color: #4caf50; /* Cor verde */
// //   color: #fff;
// //   border: none;
// //   border-radius: 4px;
// //   padding: 8px;
// //   width: 10%;
// //   justify-content: center;
// //   display: flex;
// //   align-items: center;
// // `;

// export const Placeholder = styled.span`
//   position: absolute;
//   top: 50%;
//   left: 10px;
//   transform: translateY(-50%);
//   font-family: "Josefin Sans";
//   color: "#515151";
//   pointer-events: none;
// `;

// export const LanguageText = styled.p`
//   font-family: "Josefin Bold";
//   color: #000;
// `;

// export const Language = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-left: 15px;
// `;

// export const Br = styled.img`
//   width: 40px;
//   height: 40px;
//   margin-right: 5px;
// `;

// export const Us = styled.img`
//   width: 40px;
//   height: 40px;
//   margin-left: 5px;
// `;

// New navBar

export const Nav = styled.nav`
  font-family: "Josefin Bold";
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  background-color: #2a9d8f;
  font-size: 16px;
`;

export const Logo = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-left: 5rem;
  margin-right: 3rem;
  width: 10%;
  cursor: pointer;

  @media only screen and (min-width: 320px) and (max-width: 425px) {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    width: 30%;
  }

  @media only screen and (min-width: 426px) and (max-width: 768px) {
    margin-left: 3rem;
    margin-right: 2rem;
    width: 25%;
  }

  @media screen and (min-width: 769px) and (max-width: 1024px) {
    width: 15%;
  }
  @media screen and ((min-width: 1025px) and (max-width: 1440px)) {
    width: 15%;
  }
  @media screen and ((min-width: 1441px) and (max-width: 1920px)) {
    width: 13%;
  }

  @media screen and ((min-width: 1921px) and (max-width: 2560px)) {
    width: 10%;
  }

  @media screen and ((min-width: 2561px) and (max-width: 3440px)) {
    width: 7%;
  }
`;

export const LogoImage = styled.img`
  width: auto;
  height: auto;
`;

export const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
`;

export const SearchDivContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
`;

export const SearchDiv = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  width: 35%;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    width: 70%;
  }
`;

export const SearchBar = styled.input`
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  width: 70%;

  @media only screen and (min-width: 320px) and (max-width: 425px) {
    width: 100%;
  }

  @media only screen and (min-width: 426px) and (max-width: 768px) {
    width: 80%;
  }
`;

export const SearchButton = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  background-color: white;
  color: #48c85d;
  border-radius: 0.5rem;
  transition-duration: 0.4s;
  cursor: pointer;

  &:hover {
    color: #48c85d;
    background-color: #e7e2e2;
  }
`;

export const WelcomeMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  gap: 0.5rem;
  margin-bottom: 1rem;

  p {
    color: white;
  }
  @media (max-width: 768px) {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    width: 41%;
  }
`;

export const WelcomeText = styled.span`
  color: white;
  font-size: 1rem;

  @media screen and (max-width: 1024px) {
    width: 35%;
  }
`;

export const SignInButton = styled.button`
  cursor: pointer;
  background-color: #48c85d;
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.4s;
  border-radius: 0.5rem;
  width: auto;
  height: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: #42a551;
    color: white;
  }

  /* @media screen and (max-width: 1223px) {
    width: 55%;
  }

  @media screen and (min-width: 1024px) and (max-width: 1223px) {
    width: 65%;
  }

  @media screen and (min-width: 1224px) {
    width: auto;
  } */
`;

export const OtherButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12%;
  gap: 1rem;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    width: 25%;
  }
  @media screen and ((min-width: 1025px) and (max-width: 1440px)) {
    width: 18%;
  }
  @media screen and ((min-width: 1441px) and (max-width: 1920px)) {
    width: 13%;
  }

  @media screen and ((min-width: 1921px) and (max-width: 2560px)) {
    width: 10%;
  }

  @media screen and ((min-width: 2561px) and (max-width: 3440px)) {
    width: 7%;
  }
`;

export const CustomButton = styled.button`
  display: flex;
  border: none;
  width: auto;
  color: white;
  cursor: pointer;
  justify-content: center;
  align-items: center;

  p {
    font-size: 2rem;
    width: auto;

    &:hover {
      color: #e7e2e2;
      transition-duration: 0.4s;
    }
  }
`;

export const TranslateImage = styled.img`
  width: 70px;
  height: auto;
`;

export const NavLinksHamburger = styled.ul`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  list-style: none;
  gap: 1rem;
  width: 100%;

  border-top: white 1px solid;

  :hover {
    color: #e7e2e2;
    transition-duration: 0.4s;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

export const NavItemHamburgerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const NavItemHamburger = styled.li`
  display: flex;
  width: auto;
  justify-content: center;
  align-items: start;
  padding-top: 1rem;
  color: white;
  cursor: pointer;
`;
export const WelcomeMessageHamburger = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
  display: none;
  p {
    color: white;
  }
  @media (max-width: 768px) {
    display: block;
  }
`;

export const NavLinks = styled.ul`
  display: flex;
  justify-content: space-around;
  list-style: none;
  gap: 1rem;
  width: 100%;
  border-top: white 1px solid;

  :hover {
    color: #e7e2e2;
    transition-duration: 0.4s;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  display: flex;
  width: auto;
  justify-content: center;
  align-items: start;
  padding-top: 1rem;
  gap: 0.5rem;
  color: white;
  cursor: pointer;
`;

export const Button = styled.button`
  background-color: ${colors.primary};
  border-radius: 8px;
  height: 35px;
  width: 80%;
  border-right-color: "#000";

  font-family: "Josefin Bold";
  color: #fff;
`;

export const Title = styled.h1`
  font-family: "Josefin Bold";
  color: #000;
  font-size: 22px;
`;

export const MarginRight = styled.div`
  margin-right: 20px;
`;

export const TextInformation = styled.h1`
  font-family: "Josefin Sans";
  color: #000;
  font-size: 15px;
`;
