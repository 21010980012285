import React, { useState } from "react";
import {
  ButtonEnter,
  ButtonRegister,
  Container,
  Content,
  ForgotPasswordLinkContainer,
  InputContainer,
  LinhaVertical,
  Space,
  TitleButtonEnter,
  TitleButtonRegister,
  TitleInput,
  Title,
  MarginBottom,
  Column,
  ErrorText,
  DivImg,
  Img,
  ButtonImg,
} from "./style";
import { useTranslation } from "react-i18next";
import { BeatLoader } from "react-spinners";
import {
  Formik,
  Field,
  Form,
  FormikHelpers,
  FieldInputProps,
  ErrorMessage,
} from "formik";
import { MdOutlineEmail } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { useAuth } from "../../../context/AuthContext";
import InputWithIcon from "../../../components/input/input";
import logo from "../../../assets/images/Logo_Ecolife_onda__1_-removebg-preview.png";
import { ContainerMargin } from "../../cart/style";
import { Center } from "@chakra-ui/react";

import authValidation from "../../../utils/validation/authValidation";
import { ToastContainer } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
export default function LoginBuy() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { login } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    email: "",
    password: "",
  };
  const handleLogin = () => {
    window.location.href = "/homeBuy";
  };

  const handleRegister = () => {
    window.location.href = "/registerBuy";
  };

  const handleSubmit = async (
    values: typeof initialValues,
    actions: FormikHelpers<typeof initialValues>
  ) => {
    try {
      setIsLoading(true);
      await login(values);
      setIsLoading(false);
    } catch (error) {
      console.error("Erro ao fazer login:", (error as Error).message);
    }
  };
  return (
    <Container>
      <Content>
        <Space onClick={() => navigate("/")}>
          <DivImg>
            <Img src={logo} alt="Minha Imagem" />
            <ButtonImg onClick={() => navigate("/")}>
              {t("voltar para tela inicial")}
            </ButtonImg>
          </DivImg>
        </Space>
        <LinhaVertical />
        <Space>
          <Title>{t("buy-title")}</Title>
          <ContainerMargin>
            <Formik
              validationSchema={authValidation}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <Form>
                <TitleInput>E-mail</TitleInput>
                <Field
                  type="text"
                  name="email"
                  render={({ field }: { field?: FieldInputProps<any> }) => (
                    <InputWithIcon
                      icon={<MdOutlineEmail />}
                      iconSize="23px"
                      field={field}
                    />
                  )}
                />
                <ErrorText>
                  <ErrorMessage name="email" />
                </ErrorText>
                <MarginBottom />
                <TitleInput>{t("password")}</TitleInput>
                <Field
                  type="password"
                  name="password"
                  render={({ field }: { field: FieldInputProps<any> }) => (
                    <InputWithIcon
                      icon={<RiLockPasswordLine />}
                      iconSize="23px"
                      field={field}
                      type="password"
                    />
                  )}
                />
                <ErrorText>
                  <ErrorMessage name="password" />
                </ErrorText>

                <MarginBottom />

                <Column>
                  <ForgotPasswordLinkContainer href="/forgetPassword">
                    {t("remember")}
                  </ForgotPasswordLinkContainer>
                </Column>
                <Center>
                  <ButtonEnter type="submit">
                    {isLoading ? (
                      <BeatLoader className="spinner" size={8} color={"#fff"} />
                    ) : (
                      <TitleButtonEnter>{t("login")}</TitleButtonEnter>
                    )}
                  </ButtonEnter>
                </Center>
                <Center>
                  <ButtonRegister onClick={handleRegister}>
                    <TitleButtonRegister>
                      {t("new-account")}
                    </TitleButtonRegister>
                  </ButtonRegister>
                </Center>
              </Form>
            </Formik>
          </ContainerMargin>
        </Space>
      </Content>
      <ToastContainer />
    </Container>
  );
}
