import { useQuery } from "@tanstack/react-query";
import { baseSeller } from "../../src/utils/server/api";
import axios from "axios";

const fetchBank = async (name: string) => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(`${baseSeller}/bank?name=${name}`, config);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

// Corrigindo a função para ser um Hook personalizado
export function useSellerBankData(name: string) {
  const query = useQuery({
    queryFn: () => fetchBank(name),
    queryKey: ["sellerBank", name],
  });

  return query;
}
