import NavBar from "../../components/navbar/navbar";
import {
  ButtonContainer,
  ButtonOutFile,
  Buttons,
  Container,
  ContainerInputs,
  ContainerInputsRow,
  InputContainer,
  ProfilePic,
  TitleInput,
  Button,
} from "./style";
import profile from "../../assets/images/new.png";
import InputWithIcon from "../../components/input/input";
import { MdOutlineEmail, MdOutlinePriceCheck } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { CiPhone } from "react-icons/ci";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { MdCorporateFare } from "react-icons/md";
import { FaWpforms } from "react-icons/fa";
import { CiHome } from "react-icons/ci";
import { GiWoodStick } from "react-icons/gi";
import { ButtonOutlineNoIcon } from "../../components/button/button";
import TitleComponent from "../../components/title/title";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import {
  Formik,
  Field,
  Form,
  FieldInputProps,
  ErrorMessage,
  FormikHelpers,
} from "formik";
import { ToastContainer } from "react-toastify";
import { IoDocumentTextOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { SelectContainer } from "../register/register-buy/style";
import axios from "axios";
import { baseBuyer, baseEngineer, baseSeller } from "../../utils/server/api";
import propertyValidation from "../../utils/validation/propertyValidation";
import { ErrorText } from "../login/login-seller/style";
import MaskedInput from "../../components/input/inputMask";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import colors from "../../utils/colors";
import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";
import { useMeData } from "../../hooks/serviceMe";
import { MarginBottom } from "../login/login-buy/style";
import ModalImages from "../../components/modal-image/modal-image";
import { useLocation } from "react-router-dom";
const token = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data", // Defina o tipo de conteúdo conforme necessário
  },
};

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin:
    "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

export default function CheckProperty() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const item = JSON.parse(decodeURIComponent(searchParams.get("item") || "{}"));
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [imageTitle, setImageTitle] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOpenModal = (
    url: string,
    title: string,
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (e) {
      e.preventDefault();
    }
    setImageUrl(url);
    setImageTitle(title);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const initialValues = {
    id: item.id,
    cib_nirf_number: item.cib_nirf_number,
    address: item.address,
    deed_number: item.deed_number,
    hectares_amount: item.hectares_amount,
    price: item.price,
  };

  const handleSubmit = async (values: any) => {
    if (!modalOpen) {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data", // Defina o tipo de conteúdo conforme necessário
        },
      };

      try {
        const response = await axios.post(
          `${baseEngineer}/product/oversight?id=${item.id}`,
          values,
          config
        );
        console.log("res", response.data);
        navigate("/mySpaceEngineer");
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      <div style={{ marginBottom: "150px" }}>
        <NavBar />
      </div>

      <Container>
        <TitleComponent icon={CiHome} text="Analise a propriedade" />

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          <Form>
            <TitleInput> CIB/NRF</TitleInput>
            <Field
              type="text"
              name="cib_nirf_number"
              render={({ field }: { field?: FieldInputProps<any> }) => (
                <InputWithIcon
                  icon={<GiWoodStick />}
                  iconSize="23px"
                  field={field}
                />
              )}
            />
            <MarginBottom />
            <TitleInput> Endereço</TitleInput>
            <Field
              type="text"
              name="address"
              render={({ field }: { field?: FieldInputProps<any> }) => (
                <InputWithIcon
                  icon={<GiWoodStick />}
                  iconSize="23px"
                  field={field}
                />
              )}
            />

            <MarginBottom />
            <TitleInput> Número da Escritura</TitleInput>
            <Field
              type="text"
              name="deed_number"
              render={({ field }: { field?: FieldInputProps<any> }) => (
                <InputWithIcon
                  icon={<GiWoodStick />}
                  iconSize="23px"
                  field={field}
                />
              )}
            />

            <MarginBottom />
            <TitleInput> Hectares</TitleInput>
            <Field
              type="text"
              name="hectares_amount"
              render={({ field }: { field?: FieldInputProps<any> }) => (
                <InputWithIcon
                  icon={<GiWoodStick />}
                  iconSize="23px"
                  field={field}
                />
              )}
            />

            <MarginBottom />
            <TitleInput> Valor</TitleInput>
            <Field
              type="text"
              name="price"
              render={({ field }: { field?: FieldInputProps<any> }) => (
                <InputWithIcon
                  icon={<GiWoodStick />}
                  iconSize="23px"
                  field={field}
                />
              )}
            />

            <div style={{ marginLeft: -30 }}>
              <TitleComponent icon={CiHome} text={t("file")} />
            </div>

            <div>
              <ButtonContainer>
                <ButtonOutlineNoIcon
                  onClick={() => handleOpenModal(item?.cib_nirfImage, "CIB")}
                  title="CIB"
                />
                <ButtonOutlineNoIcon
                  onClick={() =>
                    handleOpenModal(
                      item?.addressImage,
                      `${t("proof-residence")}`
                    )
                  }
                  title={t("proof-residence")}
                />
              </ButtonContainer>
              <ButtonContainer>
                <ButtonOutlineNoIcon
                  onClick={() =>
                    handleOpenModal(item?.deedImage, `${t("deed-file")}`)
                  }
                  title={t("deed-file")}
                />
                <ButtonOutlineNoIcon
                  onClick={() =>
                    handleOpenModal(item?.image, `${t("image-property")}`)
                  }
                  title={t("image-property")}
                />
              </ButtonContainer>
            </div>

            <Buttons>
              <ButtonContainer>
                <Button
                  type="submit"
                  style={{
                    backgroundColor: colors.primary,
                    color: "white",
                    fontFamily: "Josefin Bold",
                    fontSize: "18px",
                    marginBottom: "180px",
                  }}
                >
                  {isLoading ? (
                    <BeatLoader className="spinner" size={8} color={"#fff"} />
                  ) : (
                    "Enviar dados revisados"
                  )}
                </Button>
              </ButtonContainer>
            </Buttons>
          </Form>
        </Formik>
      </Container>
      <ModalImages
        isOpen={modalOpen}
        onClose={handleCloseModal}
        title={imageTitle}
        imageUrl={imageUrl}
      />
      <Footer
        companyName="Ecolife"
        address="Caruaru - PE"
        email="contato@suaempresa.com"
        phone="(11) 1234-5678"
        socialMedia={socialMediaLinks}
      />
    </div>
  );
}
