import styled from "styled-components";

interface HamburgerIconProps {
  isOpenMenu: boolean;
  onClick: () => void;
}

const HamburgerIcon = styled.div<HamburgerIconProps>`
  width: 30px;
  height: 22.5px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: none; /* Ocultar por padrão em telas maiores */

  @media (max-width: 768px) {
    /* Defina a largura máxima para o tamanho de tela de dispositivos móveis */
    display: block; /* Exibir apenas em telas menores que 768px */
  }

  &:hover span {
    background-color: #e7e2e2;
    transition-duration: 0.4s;
  }

  span {
    display: block;
    position: absolute;
    height: 4.5px;
    width: 100%;
    background: #fff;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
      transform: ${({ isOpenMenu }) =>
        isOpenMenu ? "rotate(-45deg)" : "rotate(0deg)"};
    }

    &:nth-child(2) {
      top: 9px;
      opacity: ${({ isOpenMenu }) => (isOpenMenu ? "0" : "1")};
    }

    &:nth-child(3) {
      top: 18px;
      transform: ${({ isOpenMenu }) =>
        isOpenMenu ? "rotate(45deg)" : "rotate(0deg)"};
    }
  }
`;

interface HamburgerProps {
  isOpenMenu: boolean;
  toggleSidebar: () => void;
}

const Hamburger = ({ isOpenMenu, toggleSidebar }: HamburgerProps) => {
  const handleClickOpen = () => {
    toggleSidebar();
  };
  return (
    <HamburgerIcon isOpenMenu={isOpenMenu} onClick={handleClickOpen}>
      <span></span>
      <span></span>
      <span></span>
    </HamburgerIcon>
  );
};

export default Hamburger;
