import { useQuery } from "@tanstack/react-query";
import { baseBuyer } from "../../src/utils/server/api";
import axios from "axios";

const fetchProperty = async (searchTerm: string) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const response = await axios.get(
    `${baseBuyer}/product?name=${searchTerm}`,
    config
  );

  return response.data;
};

export function usePropertyDataSearch(searchTerm: string) {
  const query = useQuery({
    queryKey: ["property", searchTerm],
    queryFn: () => fetchProperty(searchTerm),
  });

  return query;
}
