import NavBar from "../../../components/navbar/navbar";
import FormikSelect from "../../../components/select/select";
import CustomInput from "../../../components/customInput/customInput";
import Footer from "../../../components/footer/footer";
import * as Yup from "yup";
import {
  Container,
  ButtonContainer,
  Title,
  ButtonBack,
  SubmitButton,
} from "./style";
import { useTranslation } from "react-i18next";
import { Form, Formik, Field, FieldProps } from "formik";
import { useNavigate } from "react-router-dom";
import { useMeDataExpand } from "../../../hooks/serviceMe";
import { useMeSellerPutDataBank } from "../../../hooks/serviceMeSellerPutDataBank";
import { useSellerBankData } from "../../../hooks/serviceGetBank";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteGroup,
} from "@choc-ui/chakra-autocomplete";
import { useState } from "react";
import { FormLabel } from "@chakra-ui/react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

const TedFormSchema = Yup.object().shape({
  bank_id: Yup.string().required("Banco é obrigatório"),
  agency: Yup.string()
    .matches(/^\d+$/, "Agência deve conter apenas números")
    .required("Agência é obrigatória")
    .max(4, "Agência inválida"),
  agency_digit: Yup.string()
    .matches(/^\d+$/, "Agência deve conter apenas números")
    .max(1, "Dígito inválida")
    .notRequired(),
  bank_account: Yup.string()
    .matches(/^\d+$/, "Agência deve conter apenas números")
    .required("Conta é obrigatória")
    .max(12, "Conta inválida"),
  account_type: Yup.string().required("Tipo de conta é obrigatório"),
});

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin:
    "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

export default function TedForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mutate } = useMeSellerPutDataBank();
  const { data: meData, isLoading: isMeLoading } = useMeDataExpand();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const initialValues = {
    bank_id: meData?.bank?.name || "",
    agency: meData?.agency || "",
    agency_digit: meData?.agency_digit || "",
    bank_account: meData?.bank_account || "",
    account_type: meData?.account_type || "",
  };

  const optionsAccount = [
    { value: "1", label: "Conta Corrente" },
    { value: "2", label: "Conta Salário" },
    { value: "3", label: "Poupança" },
    { value: "4", label: "Conta de Pagamento" },
  ];

  const onSubmit = (values: any) => {
    const data = {
      bank_id: values.bank_id,
      agency: values.agency,
      agency_digit: values.agency_digit,
      bank_account: values.bank_account,
      account_type: values.account_type,
    };

    mutate(data, {
      onSuccess: () => {
        setModalMessage("Cadastro de dados realizado com sucesso");
        setIsModalVisible(true);
      },
      onError: () => {
        setModalMessage("Falha ao realizar o cadastro de dados");
        setIsModalVisible(true);
      },
    });
  };

  const [searchTerm, setSearchTerm] = useState("");
  const { data: sellerBankData, isLoading: isSellerBankLoading } =
    useSellerBankData(searchTerm);

  const handleSearchTermChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div>
      <NavBar />
      <Container>
        {!isMeLoading ? (
          <>
            <Formik
              initialValues={initialValues}
              validationSchema={TedFormSchema}
              onSubmit={onSubmit}
            >
              {({ values, setFieldValue }) => (
                <>
                  <Form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                      marginTop: "20px",
                      marginBottom: "20px",
                      padding: "20px",
                      borderRadius: "10px",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "#fff",
                      minWidth: "20%",
                    }}
                  >
                    <Title>Dados Bancários - TED</Title>

                    <Field name="bank_id">
                      {({ field }: FieldProps) => (
                        <>
                          <AutoComplete
                            onChange={(value) =>
                              setFieldValue("bank_id", value)
                            }
                            openOnFocus
                            rollNavigation
                            listAllValuesOnFocus
                            freeSolo
                          >
                            <FormLabel
                              fontSize="16px"
                              fontFamily={"Josefin Sans"}
                              fontWeight="bold"
                            >
                              Banco
                            </FormLabel>

                            <AutoCompleteInput
                              variant="filled"
                              name="bank_id"
                              onBlur={field?.onBlur}
                              onChange={handleSearchTermChange}
                              bgColor={"white"}
                              border={"2px solid gray"}
                              placeholder="Digite o nome do seu Banco..."
                              _hover={{ background: "white" }}
                              _focus={{ border: "2px solid #48c85d" }}
                              padding={"20px"}
                              fontSize={"16px"}
                              borderRadius={"8px"}
                              width={"100%"}
                            />
                            <AutoCompleteList
                              border={"1px solid gray"}
                              bg={"gray"}
                            >
                              <AutoCompleteGroup>
                                {!isSellerBankLoading &&
                                  sellerBankData?.map((option: any) => (
                                    <AutoCompleteItem
                                      key={`option-${option?.id}`}
                                      value={`${option?.id}`}
                                      label={option?.name}
                                      textTransform="capitalize"
                                    />
                                  ))}
                              </AutoCompleteGroup>
                            </AutoCompleteList>
                          </AutoComplete>
                        </>
                      )}
                    </Field>

                    <CustomInput
                      htmlFor="agency"
                      name="agency"
                      label="Agência"
                      placeholder="Digite sua agência"
                      mask={""}
                    />

                    <CustomInput
                      htmlFor="agency_digit"
                      name="agency_digit"
                      label="Dígito da agência"
                      placeholder="Dígito da sua agência"
                      mask={""}
                    />

                    <CustomInput
                      htmlFor="bank_account"
                      name="bank_account"
                      label="Conta + Dígito"
                      placeholder="Informe sua conta"
                      mask={""}
                    />

                    <FormikSelect
                      id="account_type"
                      label="Tipo de Conta"
                      options={optionsAccount}
                      name="account_type"
                    />

                    <ButtonContainer>
                      <ButtonBack
                        type="button"
                        onClick={(event) => {
                          event.preventDefault();
                          navigate("/typeOfBankDetails");
                        }}
                      >
                        {" "}
                        {t("back")}{" "}
                      </ButtonBack>
                      <div>
                        <SubmitButton type="submit">
                          {" "}
                          {t("to-save")}
                        </SubmitButton>
                      </div>
                    </ButtonContainer>
                  </Form>
                </>
              )}
            </Formik>
          </>
        ) : null}

        {isModalVisible && (
          <Modal
            isOpen={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            size="lg"
            isCentered
          >
            <ModalOverlay style={{ width: "100%", height: "100%" }} />
            <ModalContent>
              <ModalCloseButton />
              <ModalHeader> </ModalHeader>
              <ModalBody>
                <Text fontSize="lg">{modalMessage}</Text>
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => setIsModalVisible(false)}>Fechar</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}

        {isMeLoading && <p>{t("Carregando ...")}</p>}
      </Container>

      <Footer
        companyName="Ecolife"
        address="Caruaru - PE"
        email="contato@suaempresa.com"
        phone="(11) 1234-5678"
        socialMedia={socialMediaLinks}
      />
    </div>
  );
}
