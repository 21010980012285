import {
  CardContainer,
  CardContent,
  CardImage,
  Description,
  Favorite,
  GreenBox,
  Title,
  TitleStatus,
} from "./style";

import { MdFavoriteBorder } from "react-icons/md";
import { FaHeart } from "react-icons/fa";

import axios from "axios";
import { baseBuyer } from "../../utils/server/api";
import { useEffect, useState } from "react";

const token = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
};

interface CardProps {
  item?: any;
  image: any;
  title: string;
  description: string;
  hectares: number;
  onClick?: (item: any) => void;
  is_favorite?: boolean;
  status?: any;
}

const Card: React.FC<CardProps> = ({
  item,
  image,
  title,
  description,
  hectares,
  is_favorite,
  onClick,
  status,
}) => {
  const limitedDescription =
    description?.length > 50 ? `${description.slice(0, 50)}...` : description;
  const limitedtitle = title?.length > 25 ? `${title.slice(0, 25)}...` : title;
  const [active, setActive] = useState(is_favorite);
  const favorite = async (id: any) => {
    try {
      const response = await axios.post(
        `${baseBuyer}/product/favorite`,
        { product_id: id },
        config
      );

      if (response.data.active === true) {
        setActive(true);
      }

      console.log(response.data);
    } catch (error) {
      console.log(error);
      console.error("Erro ao favoritar", (error as Error).message);
    }
  };

  useEffect(() => {
    console.log("item card", status);
    // console.log("item card", item);
    // console.log("image", token);
  }, []);
  return (
    <CardContainer>
      <CardImage onClick={onClick} src={image} alt="Card" />
      {/* <Favorite onClick={() => favorite(item?.id)}>
        {active ? (
          <FaHeart size={18} color="red" />
        ) : (
          <MdFavoriteBorder size={18} />
        )}
      </Favorite> */}

      <CardContent onClick={onClick}>
        <Title>{limitedtitle}</Title>
        <Description>{limitedDescription}</Description>
        {status && <TitleStatus>status: {status}</TitleStatus>}
        {hectares && <GreenBox>{hectares}c</GreenBox>}
      </CardContent>
    </CardContainer>
  );
};

export default Card;
