// NavBar.tsx
// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { BsFillBuildingFill } from "react-icons/bs";
// import {
//   FaHeart,
//   FaJournalWhills,
//   FaNewspaper,
//   FaPaperPlane,
// } from "react-icons/fa";
// import { FaBagShopping, FaHouse, FaSpaceAwesome } from "react-icons/fa6";
// import { useNavigate } from "react-router-dom";
// import { FaMapMarkedAlt } from "react-icons/fa";
// import { useTranslation } from "react-i18next";
// import styled from "styled-components";
// import { baseBuyer, baseSeller } from "../../utils/server/api";
// import { config } from "../../utils/server/header";
// import {
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalFooter,
//   ModalBody,
//   ModalCloseButton,
// } from "@chakra-ui/react";
// import {
//   Br,
//   Button,
//   Language,
//   LanguageText,
//   MarginRight,
//   TextInformation,
//   Title,
//   Us,
// } from "./style";
// import { useMeData } from "../../hooks/serviceMe";
// const ic_logo = require("../../assets/images/Logo_Wide_Branca_Nav.png");
// const br = require("../../assets/images/brasil.png");
// const us = require("../../assets/images/estados-unidos.png");

// interface Window {
//   RequestFileSystem?: any;
//   webkitRequestFileSystem?: any;
// }

// // Estilos dos componentes
// const Container = styled.div`
//   /* display: flex; */
//   align-items: center;
//   /* justify-content: space-between; */
//   padding: 10px 20px;
//   background-color: #2a9d8f;
//   color: #fff;
// `;

// const Content = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 25px;
//   margin-left: 2rem;
//   @media screen and (min-width: 1024px) {
//     margin-left: 1rem;
//     gap: 1rem;
//   }
// `;

// const Logo = styled.img`
//   width: 180px;
//   height: 45px;

//   &:hover {
//     opacity: 1px;
//   }
// `;

// const SearchBar = styled.div`
//   display: flex;
// `;

// const UserActions = styled.div`
//   display: flex;
//   align-items: center;
// `;

// const Divider = styled.hr`
//   width: 100%;
//   border: 0.5 solid #f1faee;
//   margin-bottom: 10px;
// `;

// const MenuOptions = styled.div`
//   flex: 1;
//   display: flex;
//   justify-content: space-between;
// `;

// const Welcome = styled.div`
//   margin-left: 24px;
//   font-family: "Josefin Bold";
//   font-size: 15px;
// `;

// const DivOptions = styled.div`
//   display: flex;
//   //align-items: center;
// `;

// const OptionText = styled.span`
//   font-family: "Josefin Bold";
//   color: #fff;
//   font-size: 18px;
//   margin-left: 10px;
//   cursor: pointer;
// `;

// const Pointer = styled.span`
//   cursor: pointer;
//   background-color: #48c85d; // Cor de fundo do botão
//   border: none; // Remove a borda padrão
//   color: white; // Cor do texto
//   padding: 10px 20px; // Espaçamento interno
//   text-align: center; // Alinhamento do texto
//   text-decoration: none; // Remove a decoração do texto
//   display: inline-block; // Permite definir largura e altura
//   font-size: 16px; // Tamanho da fonte
//   transition-duration: 0.4s; // Duração da transição
//   border-radius: 12px; // Bordas arredondadas

//   &:hover {
//     background-color: #42a551; // Cor de fundo ao passar o mouse
//     color: white;
//   }

//   @media screen and (min-width: 768px) {
//     padding: 5px 15px;
//     font-size: 12px;
//   }

//   @media screen and (min-width: 1024px) {
//     padding: 5px 15px;
//     font-size: 12px;
//   }
//   @media screen and (min-width: 1440px) {
//     padding: 10px 20px;
//     font-size: 14px;
//   }
//   @media screen and (min-width: 1441px) {
//     padding: 10px 20px;
//     font-size: 16px;
//   }
// `;

// const NavBar: React.FC = () => {
//   const navigate = useNavigate();
//   const { i18n, t } = useTranslation();
//   const [searchTerm, setSearchTerm] = useState("");
//   const type = localStorage.getItem("userType");
//   const token = localStorage.getItem("token");
//   const [isIncognito, setIsIncognito] = useState(false);

//   const { data, isLoading } = useMeData();

//   const [isOpen, setIsOpen] = useState(false);

//   const changeLanguage = (lng: string) => {
//     localStorage.setItem("language", lng);
//     i18n.changeLanguage(lng); // Atualiza o idioma no i18n
//     //i18n.changeLanguage(lg);
//   };

//   const openModal = () => {
//     setIsOpen(true);
//   };

//   const onClose = () => {
//     setIsOpen(false);
//   };

//   useEffect(() => {
//     console.log("data", data);
//     const fetchLanguage = async () => {
//       const storedLanguage = await localStorage.getItem("language");
//       if (storedLanguage) {
//         i18n.changeLanguage(storedLanguage); // Atualiza o idioma no i18n
//       }
//     };

//     fetchLanguage();
//   }, []);

//   const handleSearch = () => {
//     const queryString = `?item=${encodeURIComponent(
//       JSON.stringify(searchTerm)
//     )}`;

//     navigate(`/search${queryString}`);
//   };
//   return (
//     <Container>
//       <Content>
//         <a href="/">
//           <Logo
//             src={ic_logo}
//             alt="Logo"
//             onClick={() => {
//               token
//                 ? type === "vendedor"
//                   ? navigate("/homeSeller")
//                   : navigate("/homeBuy")
//                 : navigate("/homeBuy");
//             }}
//           />
//         </a>

//         <SearchBar>
//           <input
//             style={{
//               width: "450px",
//               height: "40px",
//               borderRadius: "5px",
//               padding: "8px",
//               color: "#000",
//             }}
//             type="text"
//             placeholder={t("search")}
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//             onKeyDown={(e) => {
//               if (e.key === "Enter") {
//                 handleSearch();
//               }
//             }}
//           />
//           <button
//             onClick={handleSearch}
//             style={{
//               width: "70px",
//               backgroundColor: "#fff",
//               color: "#1ba21b",
//               borderRadius: "5px",
//               height: "40px",
//               marginLeft: "10px",
//               fontWeight: "bold",
//             }}
//           >
//             {t("search")}
//           </button>
//           <Welcome>
//             {!isLoading && (
//               <>
//                 <span>
//                   {t("welcome-web")}, <br />{" "}
//                   {!token && (
//                     <Pointer
//                       onClick={() => {
//                         token ? navigate("/profile") : navigate("/choice");
//                       }}
//                     >
//                       entre ou cadastre-se
//                     </Pointer>
//                   )}
//                   {token && type === "cliente" ? (
//                     data && (
//                       <Pointer
//                         onClick={() => {
//                           token ? navigate("/profile") : navigate("/choice");
//                         }}
//                       >
//                         {data?.corporate_name
//                           ? data?.corporate_name
//                           : data?.name + " " + data?.surname}
//                       </Pointer>
//                     )
//                   ) : token && type === "vendedor" ? (
//                     <Pointer
//                       onClick={() => {
//                         token ? navigate("/profile") : navigate("/choice");
//                       }}
//                     >
//                       {data?.corporate_name
//                         ? data?.corporate_name
//                         : data?.business_name}
//                     </Pointer>
//                   ) : token && type === "engenheiro" ? (
//                     <Pointer
//                       onClick={() => {
//                         token ? navigate("/profile") : navigate("/choice");
//                       }}
//                     >
//                       {data?.fullName}
//                     </Pointer>
//                   ) : null}
//                 </span>
//               </>
//             )}
//             {isLoading && <span>Carregando...</span>}
//           </Welcome>
//         </SearchBar>

//         <UserActions>
//           {type === "vendedor" || (type === "engenheiro" && token) ? null : (
//             <>
//               <button
//                 onClick={token ? () => navigate("/favorites") : openModal}
//                 style={{ marginRight: "15px" }}
//               >
//                 <FaHeart size={30} />
//               </button>
//               <button onClick={token ? () => navigate("/cart") : openModal}>
//                 <FaBagShopping size={30} />
//               </button>
//             </>
//           )}

//           <Language>
//             <a href="#" onClick={() => changeLanguage("pt")}>
//               <Br src={br} alt="Brasil" />
//             </a>
//             <a href="#" onClick={() => changeLanguage("en")}>
//               <Us src={us} alt="Estados Unidos" />
//             </a>
//           </Language>
//         </UserActions>
//       </Content>
//       <Divider />
//       <MenuOptions>
//         <DivOptions
//           onClick={() => {
//             token
//               ? type === "vendedor"
//                 ? navigate("/mySpaceSeller")
//                 : type === "engenheiro"
//                 ? navigate("/mySpaceEngineer")
//                 : navigate("/mySpaceBuy")
//               : navigate("/homeBuy");
//           }}
//         >
//           <BsFillBuildingFill size={19} />
//           <OptionText>{t("my-space")}</OptionText>
//         </DivOptions>

//         <DivOptions
//           onClick={() => {
//             token
//               ? type === "vendedor"
//                 ? navigate("/homeSeller")
//                 : type === "engenheiro"
//                 ? (window.location.href = "/homeEngineer")
//                 : navigate("/homeBuy")
//               : navigate("/homeBuy");
//           }}
//         >
//           <FaHouse size={19} />
//           <OptionText>Home</OptionText>
//         </DivOptions>
//         {type === "vendedor" && token && (
//           <DivOptions onClick={() => navigate("/registerProperty")}>
//             <BsFillBuildingFill size={19} />
//             <OptionText>{t("register-property")}</OptionText>
//           </DivOptions>
//         )}

//         {type === "cliente" && token && (
//           <DivOptions onClick={() => navigate("/map")}>
//             <FaMapMarkedAlt size={19} />
//             <OptionText>{t("map")}</OptionText>
//           </DivOptions>
//         )}

//         <DivOptions onClick={() => navigate("/news")}>
//           <FaNewspaper size={19} />
//           <OptionText>{t("news")}</OptionText>
//         </DivOptions>
//       </MenuOptions>

//       <Modal isOpen={isOpen} onClose={onClose}>
//         <ModalOverlay style={{ width: "100%", height: "100%" }} />
//         <ModalContent>
//           <ModalHeader>
//             <Title>Atenção</Title>
//           </ModalHeader>
//           <ModalCloseButton />
//           <ModalBody>
//             <TextInformation>
//               {" "}
//               Para acessar essa funcionalidade é necessário estar logado. Faça o
//               login ou se cadastre na plataforma!
//             </TextInformation>
//           </ModalBody>

//           <ModalFooter>
//             <Button onClick={onClose}>Fechar</Button>
//             <MarginRight />
//             <Button onClick={() => navigate("/choice")}>
//               Entrar ou Cadastrar
//             </Button>
//           </ModalFooter>
//         </ModalContent>
//       </Modal>
//     </Container>
//   );
// };

// Navbar.js
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMeData } from "../../hooks/serviceMe";
import ic_logo from "../../assets/images/Logo_Wide_Branca_Nav.png";
import br from "../../assets/images/brasil.png";
import us from "../../assets/images/estados-unidos.png";
import { FaCartShopping, FaHouse, FaNewspaper } from "react-icons/fa6";
import { BsFillBuildingFill } from "react-icons/bs";
import { FaHeart, FaMapMarkedAlt } from "react-icons/fa";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import {
  Button,
  Logo,
  LogoImage,
  MarginRight,
  Nav,
  NavItem,
  NavLinks,
  OtherButtons,
  SearchBar,
  SearchBarContainer,
  SearchButton,
  SearchDiv,
  SearchDivContainer,
  SignInButton,
  TextInformation,
  Title,
  CustomButton,
  TranslateImage,
  WelcomeMessage,
  WelcomeText,
  NavLinksHamburger,
  NavItemHamburger,
  WelcomeMessageHamburger,
  NavItemHamburgerContainer,
} from "./style";
import Hamburger from "../hamburgerMenu/hamburgerMenu";
import Sidebar from "../sideBar/siderBar";

const NavBar: React.FC = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const type = localStorage.getItem("userType");
  const token = localStorage.getItem("token");
  const [isIncognito, setIsIncognito] = useState(false);
  const { data, isLoading } = useMeData();
  const [isOpen, setIsOpen] = useState(false);

  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const toggleSidebar = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const changeLanguage = (lng: string) => {
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng); // Atualiza o idioma no i18n
    //i18n.changeLanguage(lg);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchLanguage = async () => {
      const storedLanguage = await localStorage.getItem("language");
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage); // Atualiza o idioma no i18n
      }
    };

    fetchLanguage();
  }, []);

  const handleSearch = () => {
    const queryString = `?item=${encodeURIComponent(
      JSON.stringify(searchTerm)
    )}`;

    navigate(`/search${queryString}`);
  };

  return (
    <>
      <Nav>
        <Sidebar
          isOpenMenu={isOpenMenu}
          toggle={toggleSidebar}
          changeLanguage={changeLanguage}
          openModal={openModal}
        />
        <SearchBarContainer>
          <Logo>
            <LogoImage
              src={ic_logo}
              alt="Logo"
              onClick={() => {
                token
                  ? type === "vendedor"
                    ? navigate("/homeSeller")
                    : navigate("/homeBuy")
                  : navigate("/homeBuy");
              }}
            />
          </Logo>
          <SearchDivContainer>
            <SearchDiv>
              <SearchBar
                type="text"
                placeholder={t("your-search")}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              <SearchButton onClick={handleSearch}>{t("search")}</SearchButton>
            </SearchDiv>
            <WelcomeMessage>
              <WelcomeText>{t("welcome-web")},</WelcomeText>

              {!isLoading && (
                <>
                  {!token && (
                    <SignInButton
                      onClick={() => {
                        token ? navigate("/profile") : navigate("/choice");
                      }}
                    >
                      {t("log-in-or-register")}
                    </SignInButton>
                  )}

                  {token && type === "cliente" ? (
                    data && (
                      <SignInButton
                        onClick={() => {
                          token ? navigate("/profile") : navigate("/choice");
                        }}
                      >
                        {truncateText(
                          data?.corporate_name
                            ? data?.corporate_name
                            : data?.name + " " + data?.surname,
                          20
                        )}
                      </SignInButton>
                    )
                  ) : token && type === "vendedor" ? (
                    <SignInButton
                      onClick={() => {
                        token ? navigate("/profile") : navigate("/choice");
                      }}
                    >
                      {truncateText(
                        data?.corporate_name
                          ? data?.corporate_name
                          : data?.business_name,
                        20
                      )}
                    </SignInButton>
                  ) : token && type === "engenheiro" ? (
                    <SignInButton
                      onClick={() => {
                        token ? navigate("/profile") : navigate("/choice");
                      }}
                    >
                      {truncateText(data?.fullName, 20)}
                    </SignInButton>
                  ) : null}
                </>
              )}
              {isLoading && <p>Carregando...</p>}
            </WelcomeMessage>
            <OtherButtons>
              {type === "vendedor" ||
              (type === "engenheiro" && token) ? null : (
                <>
                  <CustomButton
                    onClick={token ? () => navigate("/favorites") : openModal}
                  >
                    <p>
                      <FaHeart />
                    </p>
                  </CustomButton>
                  <CustomButton
                    onClick={token ? () => navigate("/cart") : openModal}
                  >
                    <p>
                      <FaCartShopping />
                    </p>
                  </CustomButton>
                </>
              )}

              <CustomButton onClick={() => changeLanguage("pt")}>
                <TranslateImage src={br} alt="Bandeira do brasil" />
              </CustomButton>
              <CustomButton onClick={() => changeLanguage("en")}>
                <TranslateImage src={us} alt="Bandeira Americana" />
              </CustomButton>
            </OtherButtons>
          </SearchDivContainer>
        </SearchBarContainer>
        <NavLinksHamburger>
          <NavItemHamburgerContainer>
            <NavItemHamburger>
              <WelcomeMessageHamburger>
                <WelcomeText>{t("welcome-web")}, </WelcomeText>

                {!isLoading && (
                  <>
                    {!token && (
                      <SignInButton
                        onClick={() => {
                          token ? navigate("/profile") : navigate("/choice");
                        }}
                      >
                        {t("log-in-or-register")}
                      </SignInButton>
                    )}

                    {token && type === "cliente" ? (
                      data && (
                        <SignInButton
                          onClick={() => {
                            token ? navigate("/profile") : navigate("/choice");
                          }}
                        >
                          {truncateText(
                            data?.corporate_name
                              ? data?.corporate_name
                              : data?.name + " " + data?.surname,
                            20
                          )}
                        </SignInButton>
                      )
                    ) : token && type === "vendedor" ? (
                      <SignInButton
                        onClick={() => {
                          token ? navigate("/profile") : navigate("/choice");
                        }}
                      >
                        {truncateText(
                          data?.corporate_name
                            ? data?.corporate_name
                            : data?.business_name,
                          20
                        )}
                      </SignInButton>
                    ) : token && type === "engenheiro" ? (
                      <SignInButton
                        onClick={() => {
                          token ? navigate("/profile") : navigate("/choice");
                        }}
                      >
                        {truncateText(data?.fullName, 20)}
                      </SignInButton>
                    ) : null}
                  </>
                )}
                {isLoading && <p>Carregando...</p>}
              </WelcomeMessageHamburger>
            </NavItemHamburger>
            <NavItemHamburger>
              <Hamburger
                isOpenMenu={isOpenMenu}
                toggleSidebar={toggleSidebar}
              />
            </NavItemHamburger>
          </NavItemHamburgerContainer>
        </NavLinksHamburger>
        <NavLinks>
          <NavItem
            onClick={() => {
              token
                ? type === "vendedor"
                  ? navigate("/mySpaceSeller")
                  : type === "engenheiro"
                  ? navigate("/mySpaceEngineer")
                  : navigate("/mySpaceBuy")
                : navigate("/homeBuy");
            }}
          >
            <BsFillBuildingFill size={19} />

            <p>{t("my-space")}</p>
          </NavItem>
          <NavItem
            onClick={() => {
              token
                ? type === "vendedor"
                  ? navigate("/homeSeller")
                  : type === "engenheiro"
                  ? (window.location.href = "/homeEngineer")
                  : navigate("/homeBuy")
                : navigate("/homeBuy");
              console.log("token", token);
            }}
          >
            <FaHouse size={19} />
            <p>Home</p>
          </NavItem>

          {type === "vendedor" && token && (
            <NavItem onClick={() => navigate("/registerProperty")}>
              <BsFillBuildingFill size={19} />
              <p>{t("register-property")}</p>
            </NavItem>
          )}

          {type === "cliente" && token && (
            <NavItem onClick={() => navigate("/map")}>
              <FaMapMarkedAlt size={19} />
              <p>{t("map")}</p>
            </NavItem>
          )}

          <NavItem onClick={() => navigate("/news")}>
            <FaNewspaper size={19} />
            <p>{t("news")}</p>
          </NavItem>
        </NavLinks>
      </Nav>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay style={{ width: "100%", height: "100%" }} />

        <ModalContent>
          <ModalHeader>
            <Title>Atenção</Title>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <TextInformation>
              Para acessar essa funcionalidade é necessário estar logado. Faça o
              login ou se cadastre na plataforma!
            </TextInformation>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>Fechar</Button>
            <MarginRight />

            <Button onClick={() => navigate("/choice")}>
              Entrar ou Cadastrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default NavBar;

// import React, { useEffect, useState } from "react";
// import styled from "styled-components";
// import ic_logo from "../../assets/images/logo.png";
// import colors from "../../utils/colors";
// import { config } from "../../utils/server/header";
// import axios from "axios";
// import { baseBuyer, baseSeller } from "../../utils/server/api";
// import { FaUserCircle } from "react-icons/fa";

// const NavbarContainer = styled.nav`
//   background-color: ${colors.primary};
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   z-index: 999;
// `;

// const NavbarContent = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 10px 20px;
// `;

// const LogoImage = styled.img`
//   height: 50px;
//   margin-left: 35px;
// `;

// const NavMenu = styled.ul`
//   list-style: none;
//   margin: 0;
//   padding: 0;
// `;

// const NavMenuItem = styled.li`
//   display: inline-block;
//   margin-left: 20px;
//   position: relative;
// `;

// const NavMenuItemButton = styled.li`
//   display: inline-block;
//   margin-left: 20px;
//   position: relative;
//   background-color: #fff;
//   border-radius: 4px;
//   width: 100px;
//   justify-content: center;
//   align-items: center;
// `;

// const NavLinkButton = styled.a`
//   color: ${colors.primary};
//   text-decoration: none;

//   font-family: "Josefin Bold";
//   text-align: center;
//   margin-left: 24px;
//   justify-content: center;
//   align-items: center;
// `;

// const NavLink = styled.a`
//   color: #fff;
//   text-decoration: none;
//   position: relative;
//   font-family: "Josefin Bold";
// `;

// const StyledNavLink = styled.a`
//   color: #fff;
//   text-decoration: none;
//   display: flex;
//   font-family: "Josefin Bold";
// `;

// const SubMenu = styled.ul<{ isOpen: boolean }>`
//   position: absolute;
//   z-index: 999;
//   top: calc(100% + 5px);
//   left: 0;
//   background-color: ${colors.primary};
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   padding: 10px;
//   width: 200px;
//   display: ${(props) => (props.isOpen ? "block" : "none")};
//   list-style: none;
// `;

// const SubMenuItem = styled.li`
//   margin-top: 10px;
//   z-index: 999;
// `;

// const NavMenuItemWithSubMenu = styled(NavMenuItem)`
//   &:hover ${SubMenu} {
//     display: block;
//   }
// `;

// const Navbar = () => {
//   const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
//   const type = localStorage.getItem("userType");
//   const token = localStorage.getItem("token");
//   const [user, setUser] = useState<any>();

//   const me = async () => {
//     try {
//       if (type === "cliente") {
//         const response = await axios.get(`${baseBuyer}/me`, config);
//         setUser(() => ({ ...response.data }));
//       } else {
//         const response = await axios.get(`${baseSeller}/me`, config);
//         setUser(() => ({ ...response.data }));
//       }
//     } catch (error) {
//       // Trate os erros aqui
//       console.error(error);
//     }
//   };

//   useEffect(() => {
//     me();
//   }, []);

//   const handleSubMenuToggle = () => {
//     setIsSubMenuOpen(!isSubMenuOpen);
//   };

//   return (
//     <NavbarContainer>
//       <NavbarContent>
//         <div
//           style={{
//             borderRadius: 8,
//             backgroundColor: "#fff",
//             width: "140px",
//             paddingTop: "5px",
//             justifyContent: "center",
//             alignItems: "center",
//             height: "60px",
//           }}
//         >
//           <LogoImage src={ic_logo} alt="Logo" />
//         </div>

//         <NavMenu>
//           <NavMenuItem>
//             <NavLink
//               href={
//                 token
//                   ? type === "vendedor"
//                     ? "/homeSeller"
//                     : "/homeBuy"
//                   : "/homeBuy"
//               }
//             >
//               Home
//             </NavLink>
//           </NavMenuItem>
//           {token && type === "vendedor" && (
//             <NavMenuItem>
//               <NavLink href="/registerProperty">Cadastrar Propriedade</NavLink>
//             </NavMenuItem>
//           )}

//           {type !== "vendedor" && (
//             <NavMenuItemWithSubMenu onClick={handleSubMenuToggle}>
//               <NavLink href="#">Buscar Propriedade</NavLink>
//               <SubMenu isOpen={isSubMenuOpen}>
//                 <SubMenuItem>
//                   <NavLink href="/map">Mapa</NavLink>
//                 </SubMenuItem>
//                 <SubMenuItem>
//                   <NavLink href="/search">Buscar pelo nome</NavLink>
//                 </SubMenuItem>
//               </SubMenu>
//             </NavMenuItemWithSubMenu>
//           )}
//           {token && type === "cliente" && (
//             <NavMenuItem>
//               <NavLink href="/favorites">Favoritos</NavLink>
//             </NavMenuItem>
//           )}
//           {type === "cliente" && (
//             <NavMenuItem>
//               <NavLink href="/mySpaceBuy">Meu Espaço</NavLink>
//             </NavMenuItem>
//           )}

//           {type === "vendedor" && (
//             <NavMenuItem>
//               <NavLink href="/mySpaceSeller">Meu Espaço</NavLink>
//             </NavMenuItem>
//           )}

//           <NavMenuItem>
//             <NavLink href="/news">Novidades</NavLink>
//           </NavMenuItem>
//           {token && (
//             <NavMenuItem style={{ marginTop: "3px" }}>
//               <StyledNavLink href="/profile">
//                 <FaUserCircle
//                   size={15}
//                   style={{ marginRight: "5px", marginTop: "3px" }}
//                 />
//                 {type === "cliente" && `${user?.name + " " + user?.surname}`}
//                 {type === "vendedor" && `${user?.business_name}`}
//               </StyledNavLink>
//             </NavMenuItem>
//           )}
//           {!token && (
//             <NavMenuItemButton>
//               <NavLinkButton href="/choice">Entrar</NavLinkButton>
//             </NavMenuItemButton>
//           )}
//         </NavMenu>
//       </NavbarContent>
//     </NavbarContainer>
//   );
// };

// export default Navbar;
