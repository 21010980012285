import React from "react";
import { useField } from "formik";
import { CustomError, Label, SelectContainer, StyledSelect } from "./style"; // Importe o StyledSelect

interface Option {
  value: string;
  label: string;
}

interface FormikSelectProps {
  options: Option[];
  name: string;
  placeholder?: string;
  label: string;
  id?: string;
}

const FormikSelect: React.FC<FormikSelectProps> = ({
  options,
  name,
  placeholder = "Selecione...",
  label,
  id,
}) => {
  const [field, meta, helpers] = useField(name);

  return (
    <SelectContainer>
      <Label htmlFor={id}>{label}</Label>
      <StyledSelect
        id={id}
        {...field}
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
          helpers.setValue(event.target.value)
        }
      >
        <option value="">{placeholder}</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
      {meta.touched && meta.error ? (
        <CustomError>{meta.error}</CustomError>
      ) : null}
    </SelectContainer>
  );
};

export default FormikSelect;
