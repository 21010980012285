import Navbar from "../../../components/navbar/navbar";
import { CiHome } from "react-icons/ci";
import {
  ContainerCard,
  Title,
  ContainerTitle,
  Container,
  CardWrapper,
  ButtonContainer,
  GridContainer,
} from "./style";
import Card from "../../../components/card/card";
import { baseBuyer } from "../../../utils/server/api";
import casa from "../../../assets/images/casa.png";
import HomeButtons from "../../../components/button-home/button-home";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import Footer from "../../../components/footer/footer";
import Sliders from "../../../components/slider/slider";
import PropertySlider from "../../../components/slider-property/sliderProperty";
import Flys from "../../../components/flys/flys";
import MoreCard from "../../../components/more-card/more-card";
import { useTranslation } from "react-i18next";
const token = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // Defina o tipo de conteúdo conforme necessário
  },
};

const banner = require("../../../assets/images/carrossel2.png");
const HomeBuy: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [property, setProperty] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 4; // Número de cards por página

  const handleProperty = async () => {
    try {
      const response = await axios.get(`${baseBuyer}/product/best-seller`);

      setProperty(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const limitedItems = property.slice(0, 5);

  useEffect(() => {
    handleProperty();
  }, []);
  const handleDetails = (item: any) => {
    const queryString = `?item=${encodeURIComponent(JSON.stringify(item))}`;

    navigate(`/detailsPropertyBuy${queryString}`);
  };

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = property.slice(indexOfFirstCard, indexOfLastCard);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const socialMediaLinks = {
    facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
    site: "https://lifecon.com.br/",
    instagram: "https://www.instagram.com/lifecon_tecnologia/",
    linkedin:
      "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
  };
  return (
    <Container>
      <Navbar />
      <div
        style={{
          width: "95%",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "1rem",
          margin: "0 auto",
        }}
      >
        <Sliders />
      </div>

      <Flys />
      <ContainerTitle>
        <CiHome size={25} />
        <Title>{t("in-spotlight")}</Title>
      </ContainerTitle>
      <GridContainer>
        {limitedItems.map((item: any, index) => (
          <ContainerCard key={index}>
            <Card
              image={item?.image}
              title={item?.name}
              description={item?.description}
              hectares={item?.hectares_amount}
              onClick={() => handleDetails(item)}
            />
          </ContainerCard>
        ))}

        {property.length > 5 && (
          <ContainerCard>
            <MoreCard />
          </ContainerCard>
        )}
      </GridContainer>

      {/* <HomeButtons
        titlePrimary="Meu Espaço"
        titleSecondary="Encontrar Propriedade"
        onClick={() => {
          window.location.href = "/mySpaceBuy";
        }}
        onClickSecondary={() => {
          window.location.href = "/search";
        }}
      /> */}

      <ContainerTitle>
        <CiHome size={25} />
        <Title>{t("opportunities")}</Title>
      </ContainerTitle>

      <GridContainer>
        {limitedItems.map((item: any, index) => (
          <ContainerCard key={index}>
            <Card
              image={item?.image}
              title={item?.name}
              description={item?.description}
              hectares={item?.hectares_amount}
              onClick={() => handleDetails(item)}
            />
          </ContainerCard>
        ))}

        {property.length > 5 && (
          <ContainerCard>
            <MoreCard />
          </ContainerCard>
        )}
      </GridContainer>
      {/* {property.map((item: any, index) => (
          <CardWrapper key={index}>
            <Card
              item={item}
              image={item?.image}
              title={item?.name}
              description={item?.description}
              hectares={item?.hectares_amount}
              onClick={() => handleDetails(item)}
            />
          </CardWrapper>
        ))} */}

      <div style={{ margin: 20 }}>
        <img src={banner} style={{ width: "100%" }} alt="casa" />
      </div>

      <Footer
        companyName="Ecolife"
        address="Caruaru - PE"
        email="contato@suaempresa.com"
        phone="(11) 1234-5678"
        socialMedia={socialMediaLinks}
      />
    </Container>
  );
};

export default HomeBuy;
