import styled from "styled-components";
import colors from "../../utils/colors";
export const CardContainer = styled.div`
  width: 90%; /* 90% da largura do contêiner pai */
  max-width: 400px; /* Máximo de 400px */
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 5px 4px 8px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  min-height: 300px;
  max-height: 300px;
  margin-bottom: 30px;

  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
`;

export const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 5px;
  font-family: "Josefin Bold";
`;

export const Description = styled.p`
  font-size: 14px;
  color: #555;
  margin-bottom: 16px;
  font-family: "Josefin Sans";
  white-space: normal;
  max-height: 5px;
`;

export const GreenBox = styled.div`
  background-color: ${colors.primary};
  color: #fff;
  padding: 8px;
  display: inline-block;
  margin-left: auto;
  border-radius: 4px;

  font-family: "Josefin Bold";
`;

export const Favorite = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TitleStatus = styled.h1`
  font-family: "Josefin Bold";
  color: ${colors.primary};
  font-size: 12px;
  text-transform: uppercase;
`;
