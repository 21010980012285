import styled from "styled-components";
import colors from "../../utils/colors";

interface ButtonProps {
  selected: boolean;
}

export const Container = styled.div`
  @media only screen and (max-width: 480px) {
    flex-direction: row;
    display: flex;
    margin-top: -30px;
  }
`;

export const Button = styled.button<ButtonProps>`
  border: 2px solid ${colors.primary};
  background-color: ${({ selected }) =>
    selected ? `${colors.primary}` : "transparent"};
  color: ${({ selected }) => (selected ? "#fff" : `${colors.primary}`)};
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-bottom: 40px;
  border-radius: 4px;
  height: 40px;
  width: 200px;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${colors.primary};
    color: #fff;
  }

  font-family: "Josefin Bold";
  font-size: 18px;
  text-align: center;

  @media only screen and (max-width: 480px) {
    height: 40px;
    width: 150px;
  }
`;
