import { ContainerTitle, Title } from "./style";

interface TitleProps {
  icon: React.ComponentType<any>;
  text: string;
}

export default function TitleComponent(props: TitleProps) {
  const { icon: Icon, text } = props;
  return (
    <ContainerTitle>
      <Icon size={25} />
      <Title>{text}</Title>
    </ContainerTitle>
  );
}
