import * as Yup from "yup";

const propertyValidation = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório"),
  description: Yup.string().required("Campo obrigatório"),
  category_id: Yup.number().required("Campo obrigatório"),
  hectares_amount: Yup.string().required("Campo obrigatório"),
  price: Yup.string().required("Campo obrigatório"),
  cib_nirf_number: Yup.string().required("Campo obrigatório"),
  deed_number: Yup.number().required("Campo obrigatório"),
  //   address: Yup.string().required("Campo obrigatório"),
});

export default propertyValidation;
