import Navbar from "../../../components/navbar/navbar";
import { CiHome } from "react-icons/ci";
import {
  ContainerCard,
  Title,
  ContainerTitle,
  Container,
  CardWrapper,
} from "./style";
import Card from "../../../components/card/card";

import casa from "../../../assets/images/casa.png";
import HomeButtons from "../../../components/button-home/button-home";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import axios from "axios";
import { baseEngineer, baseSeller } from "../../../utils/server/api";
import PropertySlider from "../../../components/slider-property/sliderProperty";
import Sliders from "../../../components/slider/slider";
import { GridContainer } from "../home-buy/style";
import MoreCard from "../../../components/more-card/more-card";
import Footer from "../../../components/footer/footer";
import { useTranslation } from "react-i18next";
import Flys from "../../../components/flys/flys";
import { useGeolocation } from "react-use";

const banner = require("../../../assets/images/carrossel2.png");
const LOCATION = {
  latitude: -8.3523948,
  longitude: -36.0291571,
  raio: "300000",
};

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin:
    "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

export default function HomeEngineer() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const geolocation = useGeolocation();
  const [property, setProperty] = useState([]);
  const [myProperty, setMyProperty] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userLocation, setUserLocation] = useState<any>({
    latitude: null,
    longitude: null,
  });

  const getStores = async (latitude: any, longitude: any) => {
    console.log("buscando");
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get(
        `${baseEngineer}/products-in-circle?latitude=${latitude}&longitude=${longitude}&raio=${LOCATION.raio}`,
        config
      );
      setMyProperty(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDetails = (item: any) => {
    const queryString = `?item=${encodeURIComponent(JSON.stringify(item))}`;

    navigate(`/detailsPropertyEngineer${queryString}`);
  };

  useEffect(() => {
    // Função para obter a localização atual do usuário
    const getCurrentLocation = () => {
      setLoading(true);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setUserLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
            setLoading(false);
          },
          (error) => {
            console.error("Error getting geolocation:", error);
          }
        );
      } else {
        console.error("Geolocation not supported by this browser.");
        setLoading(false);
      }
    };

    // Chamar a função para obter a localização atual do usuário
    getCurrentLocation();

    // Definir intervalo para atualizar a localização periodicamente
    const locationInterval = setInterval(() => {
      getCurrentLocation();
    }, 2000); // Atualizar a localização a cada 2 segundos

    // Limpar o intervalo quando o componente for desmontado
    return () => clearInterval(locationInterval);
  }, []);

  useEffect(() => {
    // Função para buscar lojas com base na localização do usuário
    const getStores = async (latitude: any, longitude: any) => {
      console.log("Buscando lojas...");
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      try {
        const response = await axios.get(
          `${baseEngineer}/products-in-circle?latitude=${latitude}&longitude=${longitude}&raio=${LOCATION.raio}`,
          config
        );
        setMyProperty(response.data);
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    };

    // Verificar se a localização do usuário está disponível
    if (userLocation.latitude && userLocation.longitude) {
      // Chamar a função para buscar lojas com base na localização do usuário
      getStores(userLocation.latitude, userLocation.longitude);
    }
  }, [userLocation]);

  const limitedItems = myProperty.slice(0, 5);

  return (
    <Container>
      <Navbar />
      <div
        style={{
          width: "95%",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "2.5%",
          marginTop: "20px",
        }}
      >
        <Sliders />
      </div>

      <Flys />

      <ContainerTitle>
        <CiHome size={25} />
        <Title>{t("in-spotlight")}</Title>
      </ContainerTitle>

      <GridContainer>
        {limitedItems.length === 0 && (
          <p style={{ marginLeft: "30px" }}>Buscando pela sua localização...</p>
        )}
        {limitedItems.map((item: any, index) => (
          <ContainerCard key={index}>
            <Card
              image={item?.image}
              title={item?.name}
              description={item?.description}
              hectares={item?.hectares_amount}
              onClick={() => handleDetails(item)}
            />
          </ContainerCard>
        ))}

        {myProperty.length > 5 && (
          <ContainerCard>
            <MoreCard />
          </ContainerCard>
        )}
      </GridContainer>

      <div style={{ margin: 20 }}>
        <img src={banner} style={{ width: "100%" }} alt="casa" />
      </div>

      <Footer
        companyName="Ecolife"
        address="Caruaru - PE"
        email="contato@suaempresa.com"
        phone="(11) 1234-5678"
        socialMedia={socialMediaLinks}
      />
    </Container>
  );
}
