import styled from "styled-components";

export const ResponsiveButton = styled.button`
  width: 100%;
  display: inline-block;
  background-color: #48c85d;
  color: white;
  font-size: 1em;
  padding: 0.5em 1em;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2f853a;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0.5em 1em;
  }
`;
