import NavBar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import { Container, Name, OrderButtons, Surname } from "./style";
import { ButtonOutline } from "../../components/button/button";
import { MdOutlinePix } from "react-icons/md";
import { BsBank } from "react-icons/bs";
import { useAuth } from "../../context/AuthContext";
import { FaUserCircle } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMeData } from "../../hooks/serviceMe";
import { useNavigate } from "react-router-dom";

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin:
    "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

export default function TypeOfBankDetails() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logout } = useAuth();
  const { data, isLoading } = useMeData();
  const [type, setType] = useState<any>();

  useEffect(() => {
    const userType = localStorage.getItem("userType");
    setType(userType);
  }, []);

  return (
    <div>
      <NavBar />
      <Container>
        <FaUserCircle size={100} />
        {!isLoading && (
          <>
            {data?.corporate_name ? (
              <Name>{data?.corporate_name}</Name>
            ) : (
              <Name>
                {type === "cliente" ? data?.name : data?.business_name}
              </Name>
            )}

            <Surname>{data?.email}</Surname>
            <OrderButtons>
              <ButtonOutline
                onClick={() => navigate("/pixForm")}
                icon={MdOutlinePix}
                title="PIX"
              />

              <ButtonOutline
                icon={BsBank}
                title="TED"
                onClick={() => navigate("/tedForm")}
              />
            </OrderButtons>
          </>
        )}

        {isLoading && <p>{t("Carregando ...")}</p>}
      </Container>

      <Footer
        companyName="Ecolife"
        address="Caruaru - PE"
        email="contato@suaempresa.com"
        phone="(11) 1234-5678"
        socialMedia={socialMediaLinks}
      />
    </div>
  );
}
