import styled from "styled-components";
import InputWithIcon from "../../components/input/input";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  @media only screen and (min-width: 1024px) {
    margin-top: 150px;
  }
`;

export const ProfilePic = styled.img`
  width: 100px; /* Ajuste conforme necessário */
  height: 100px; /* Ajuste conforme necessário */
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 50px;
  margin-top: 100px;

  @media only screen and (min-width: 1024px) {
    margin-top: 150px;
  }
`;

export const TitleInput = styled.h1`
  font-family: "Josefin Bold";
  color: #515151;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const ContainerInputs = styled.div`
  margin-bottom: 25px;
`;

export const MarginBottom = styled.div`
  margin-bottom: 20px;
`;


export const StyledInputWithIcon = styled(InputWithIcon)`
 color: ${props => props.disabled ? 'gray' : 'black'};
`;
