import * as Yup from "yup";

const registerValidationPJ = Yup.object().shape({
  email: Yup.string()
    .email("Digite um e-mail válido")
    .required("Campo obrigatório"),
  password_hash: Yup.string()
    .min(6, "A senha deve ter pelo menos 6 caracteres")
    .required("Campo obrigatório"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password_hash")], "As senhas devem coincidir")
    .required("Campo obrigatório"),
  name: Yup.string().required("Campo obrigatório"),
  cnpj: Yup.string().required("Campo obrigatório"),
  phone: Yup.string().required("Campo obrigatório"),
  corporate_name: Yup.string().required("Campo obrigatório"),
  state_registration: Yup.string().required("Campo obrigatório"),
});

export default registerValidationPJ;
