import React, { useEffect, useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import axios from "axios";
import NavBar from "../../components/navbar/navbar";
import TitleComponent from "../../components/title/title";
import { CiHome } from "react-icons/ci";
import Card from "../../components/card/card";
import { ContainerCard, ContentCard, GridContainer } from "./style";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useGeolocation } from "react-use";
import { useNavigate } from "react-router-dom";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { config } from "../../utils/server/header";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";
import { baseBuyer } from "../../utils/server/api";
const LOCATION = {
  latitude: -8.3523948,
  longitude: -36.0291571,
  raio: "150000",
};

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin:
    "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

const Map = () => {
  const [loading, setLoading] = useState(false);
  const [property, setProperty] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [stores, setStores] = useState<
    Array<{ lat: any; long: any; name: string }>
  >([]);

  const [userLocation, setUserLocation] = useState({
    latitude: null as any | null,
    longitude: null as any | null,
  });

  const handleDetails = (item: any) => {
    const queryString = `?item=${encodeURIComponent(JSON.stringify(item))}`;

    navigate(`/detailsPropertyBuy${queryString}`);
  };

  const geolocation = useGeolocation();

  const handleProperty = async () => {
    try {
      const response = await axios.get("/product"); // Assuming baseBuyer is set globally
      setProperty(response.data);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleProperty();
    console.log(userLocation);
    setLoading(true);
    if (userLocation.latitude !== null && userLocation.longitude !== null) {
      getStores();
    }
  }, [userLocation]);

  useEffect(() => {
    if (
      geolocation.latitude !== undefined &&
      geolocation.longitude !== undefined
    ) {
      setUserLocation({
        latitude: geolocation.latitude,
        longitude: geolocation.longitude,
      });
    } else {
      console.error("Geolocation is not available or enabled on this device.");
    }
  }, [geolocation.latitude, geolocation.longitude]);

  const getStores = async () => {
    console.log("stories");
    const token = localStorage.getItem("token");
    console.log("token", token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Defina o tipo de conteúdo conforme necessário
      },
    };
    console.log("config", config);
    try {
      const response = await axios.get(
        `${baseBuyer}/product/products-in-circle?latitude=${String(
          userLocation.latitude
        )}&longitude=${String(userLocation.longitude)}&raio=${LOCATION.raio}`,
        config
      );
      setStores(response.data);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = async (value: any) => {
    const results = await geocodeByAddress(value.label);
    const latLng = await getLatLng(results[0]);
    setUserLocation({
      latitude: latLng.lat,
      longitude: latLng.lng,
    });
  };

  return (
    <div style={{ height: "100vh", position: "relative" }}>
      <div style={{ marginBottom: "10px" }}>
        <Navbar />
      </div>
      <GoogleMap
        center={{
          lat: userLocation.latitude || -8.3523948,
          lng: userLocation.longitude || -36.0291571,
        }}
        zoom={13}
        mapContainerStyle={{
          width: "100%",
          height: "55%",
        }}
      >
        {Array.isArray(stores) &&
          stores.map((item, index) => (
            <Marker
              key={index}
              position={{
                lat: parseFloat(item.lat),
                lng: parseFloat(item.long),
              }}
              title={item.name}
            />
          ))}
        <Marker
          position={{
            lat: userLocation.latitude || -8.3523948,
            lng: userLocation.longitude || -36.0291571,
          }}
        />
      </GoogleMap>

      <div
        style={{
          padding: 8,
          height: 60,
          flex: 1,
          position: "absolute",
          top: "22%",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 1,
          width: "90%",
        }}
      >
        <GooglePlacesAutocomplete
          selectProps={{
            placeholder: `${t("title-address")}`,
            onChange: handleSelect,
          }}
        />
      </div>

      <div>
        <TitleComponent icon={CiHome} text={t("map-description")} />

        <GridContainer>
          {stores.length === 0 && (
            <p
              style={{
                marginBottom: "30px",
                fontFamily: "Josefin Sans",
                fontSize: "18px",
                marginLeft: "8px",
              }}
            >
              {t("descroption-address")}
            </p>
          )}
          {stores.map((item: any, index) => (
            <ContentCard key={index}>
              <Card
                image={item?.image}
                title={item?.name}
                description={item?.description}
                hectares={item?.hectares_amount}
                onClick={() => handleDetails(item)}
              />
            </ContentCard>
          ))}
        </GridContainer>
      </div>
      <Footer
        companyName="Ecolife"
        address="Caruaru - PE"
        email="contato@suaempresa.com"
        phone="(11) 1234-5678"
        socialMedia={socialMediaLinks}
      />
    </div>
  );
};

export default Map;
