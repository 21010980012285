import styled from "styled-components";
import colors from "../../utils/colors";

export const MiniRetangulo = styled.div`
  width: 100px;
  height: 40px;
  border: 1px solid ${colors.primary};
  border-radius: 4px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
`;

export const Quantidade = styled.span`
  font-weight: bold;
`;

export const SomaTotal = styled.span`
  font-weight: bold;
`;
