import styled from "styled-components";
import colors from "../../utils/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-top: 50px;

  @media only screen and (min-width: 768px) {
    margin-top: 90px;
  }

  @media only screen and (min-width: 1024px) {
    margin-top: 90px;
    margin-bottom: 100px;
  }
  @media only screen and (min-width: 1440px) {
    margin-top: 90px;
  }
  @media only screen and (min-width: 1441px) {
    margin-top: 90px;
  }
`;

export const ProfilePic = styled.img`
  width: 100px; /* Ajuste conforme necessário */
  height: 100px; /* Ajuste conforme necessário */
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 50px;
  margin-top: 100px;
`;

export const TitleInput = styled.h1`
  font-family: "Josefin Bold";
  color: #515151;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const ContainerInputs = styled.div`
  margin-bottom: 25px;
  display: flex;
`;

export const ContainerInputsRow = styled.div`
  flex-direction: column;
  margin-right: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;

  margin-right: 20px;
`;

export const ButtonInfo = styled.button`
  margin-bottom: 10px;
`;

export const ButtonOutFile = styled.div`
  background-color: #fff;
  border-radius: 8px;
  height: 60px;
  left: 0;
  top: 0;
  width: 350px;
  margin-bottom: 20px;
  justify-content: center;
  border: 1px solid ${colors.primary};
  align-items: center;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  justify-content: center;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

export const Button = styled.button`
  background-color: ${colors.primary};
  border-radius: 8px;
  height: 45px;
  left: 0;
  top: 0;
  width: 350px;
  margin-bottom: 20px;
  justify-content: center;
  border: 1px solid white;
  align-items: center;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  justify-content: center;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;
