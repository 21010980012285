// AuthContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import axios from "axios";
import { baseBuyer, baseEngineer, baseSeller } from "../utils/server/api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

interface AuthContextProps {
  children: ReactNode;
}

interface AuthContextType {
  isAuthenticated: boolean;
  login: (data: any) => void;
  loginSeller: (data: any) => void;
  loginEngineer: (data: any) => void;
  register: (data: any) => void;
  registerSeller: (data: any) => void;
  registerEngineer: (data: any) => void;
  forgetPassword: (data: any) => void;
  forgetPasswordSeller: (data: any) => void;
  logout: () => void;
  me: () => void;
  user: any;
  warning: string;
  registerError: any;
}

const token = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // Defina o tipo de conteúdo conforme necessário
  },
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthContextProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [registerError, setRegisterError] = useState([]);
  const [warning, setWarning] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Obtém a função de navegação
  useEffect(() => {
    // me();
    const checkAuthStatus = () => {
      const token = localStorage.getItem("token");
      setIsAuthenticated(!!token); // Update the authentication status based on the presence of a token
    };

    checkAuthStatus();
  }, [user]);
  const login = async (data: any) => {
    try {
      const response = await axios.post(`${baseBuyer}/login`, {
        ...data,
        grant_type: "user_credentials",
        client_id: "android",
        client_secret: "testpass",
        "Access-Control-Allow-Origin": "*",
      });

      toast.success("Sucesso", {
        position: toast.POSITION.TOP_CENTER,
      });

      localStorage.setItem("token", response.data.access_token);

      navigate("/homeBuy");
    } catch (error) {
      toast.error("Erro ao fazer login, tente novamente mais tarde", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("Erro ao fazer login:", error);
    }
  };

  const loginSeller = async (data: any) => {
    try {
      const response = await axios.post(`${baseSeller}/login`, {
        ...data,
        grant_type: "user_credentials",
        client_id: "android",
        client_secret: "testpass",
      });

      toast.success("Sucesso", {
        position: toast.POSITION.TOP_CENTER,
      });

      localStorage.setItem("token", response.data.access_token);

      if (response.data.result === false) {
        toast.error("Erro ao fazer login, tente novamente mais tarde", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        navigate("/homeSeller");
      }
    } catch (error) {
      toast.error("Usuário ou senha inválidos", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("Erro ao fazer login:", (error as Error).message);
    }
  };

  const loginEngineer = async (data: any) => {
    try {
      const response = await axios.post(`${baseEngineer}/login`, {
        ...data,
        grant_type: "user_credentials",
        client_id: "android",
        client_secret: "testpass",
      });

      toast.success("Sucesso", {
        position: toast.POSITION.TOP_CENTER,
      });

      localStorage.setItem("token", response.data.access_token);

      if (response.data.result === false) {
        toast.error("Erro ao fazer login, tente novamente mais tarde", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        navigate("/homeEngineer");
      }
    } catch (error) {
      toast.error("Usuário ou senha inválidos", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("Erro ao fazer login:", (error as Error).message);
    }
  };

  const register = async (data: any) => {
    console.log(data);
    try {
      const response = await axios.post(`${baseBuyer}/signup`, {
        ...data,
      });

      console.log(response.data);

      if (response.data.errors && Array.isArray(response.data.errors)) {
        console.log(response.data.errors);
        setRegisterError(response.data.errors);
      } else {
        console.log("Nenhum erro encontrado na resposta");
      }

      if (response.data.result === false) {
        toast.error("Erro ao fazer login, tente novamente mais tarde", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        navigate("/loginBuy");
      }
    } catch (error) {
      toast.error("Erro ao fazer login, tente novamente mais tarde", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const registerSeller = async (data: any) => {
    console.log(data);
    try {
      const response = await axios.post(`${baseSeller}/signup`, {
        ...data,
      });

      console.log(response.data);

      if (response.data.errors && Array.isArray(response.data.errors)) {
        console.log(response.data.errors);
        setRegisterError(response.data.errors);
      } else {
        console.log("Nenhum erro encontrado na resposta");
      }

      if (response.data.result === false) {
        toast.error("Erro ao fazer login, tente novamente mais tarde", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        navigate("/loginSeller");
      }
      // navigate("/loginSeller");
    } catch (error) {
      toast.error("Erro ao fazer login, tente novamente mais tarde", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const registerEngineer = async (data: any) => {
    console.log(data);
    try {
      const response = await axios.post(`${baseEngineer}/signup`, {
        ...data,
      });

      console.log(response.data);

      if (response.data.errors && Array.isArray(response.data.errors)) {
        console.log(response.data.errors);
        setRegisterError(response.data.errors);
      } else {
        console.log("Nenhum erro encontrado na resposta");
      }

      if (response.data.result === false) {
        toast.error("Erro ao fazer login, tente novamente mais tarde", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        navigate("/loginEngineer");
      }
    } catch (error) {
      toast.error("Erro ao fazer login, tente novamente mais tarde", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const me = async () => {
    try {
      const response = await axios.get(`${baseBuyer}/me`, config);
      setUser(() => ({ ...response.data }));
    } catch (error) {
      // Trate os erros aqui
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userType");
    await navigate("/choice");
  };

  const forgetPassword = async (data: any) => {
    try {
      const response = await axios.post(`${baseBuyer}/request-password-reset`, {
        data,
        config,
      });
      console.log(response.data.msg);
      if (response.data.result === false) {
        toast.error("Erro", {
          position: toast.POSITION.TOP_CENTER,
        });
        setWarning(response.data.msg);
      } else {
        toast.success("Sucesso", {
          position: toast.POSITION.TOP_CENTER,
        });
        setWarning(
          "Verifique sua caixa de entrada para o link de redefinição de senha. "
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const forgetPasswordSeller = async (data: any) => {
    try {
      const response = await axios.post(
        `${baseSeller}/request-password-reset`,
        {
          data,
          config,
        }
      );
      console.log(response.data);
      if (response.data.result === false) {
        toast.error("Erro", {
          position: toast.POSITION.TOP_CENTER,
        });
        setWarning(response.data.msg);
      } else {
        toast.success("Sucesso", {
          position: toast.POSITION.TOP_CENTER,
        });
        setWarning(
          "Verifique sua caixa de entrada para o link de redefinição de senha. "
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        loginSeller,
        loginEngineer,
        logout,
        register,
        registerSeller,
        registerEngineer,
        forgetPassword,
        forgetPasswordSeller,
        me,
        registerError,
        warning,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
