import TitleComponent from "../../../components/title/title";
import { CiHome } from "react-icons/ci";
import { Container, ContainerCard, ContentCard, GridContainer } from "./style";
import Navbar from "../../../components/navbar/navbar";
import Card from "../../../components/card/card";
import casa from "../../../src/assets/images/casa.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { baseBuyer, baseEngineer } from "../../../utils/server/api";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/footer/footer";
const LOCATION = {
  latitude: -8.3523948,
  longitude: -36.0291571,
  raio: "150000",
};
const token = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json", // Defina o tipo de conteúdo conforme necessário
  },
};

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin:
    "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

export default function AllProductsEngineer() {
  const navigate = useNavigate();
  const [property, setProperty] = useState([]);
  const typeUser = localStorage.getItem("userType");
  const [loading, setLoading] = useState(false);
  const [userLocation, setUserLocation] = useState<any>({
    latitude: null,
    longitude: null,
  });
  const handleDetails = (item: any) => {
    const queryString = `?item=${encodeURIComponent(JSON.stringify(item))}`;

    navigate(`/detailsPropertyEngineer${queryString}`);
  };

  useEffect(() => {
    // Função para obter a localização atual do usuário
    const getCurrentLocation = () => {
      setLoading(true);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setUserLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
            setLoading(false);
          },
          (error) => {
            console.error("Error getting geolocation:", error);
          }
        );
      } else {
        console.error("Geolocation not supported by this browser.");
        setLoading(false);
      }
    };

    // Chamar a função para obter a localização atual do usuário
    getCurrentLocation();

    // Definir intervalo para atualizar a localização periodicamente
    const locationInterval = setInterval(() => {
      getCurrentLocation();
    }, 2000); // Atualizar a localização a cada 2 segundos

    // Limpar o intervalo quando o componente for desmontado
    return () => clearInterval(locationInterval);
  }, []);

  useEffect(() => {
    // Função para buscar lojas com base na localização do usuário
    const getStores = async (latitude: any, longitude: any) => {
      console.log("Buscando lojas...");
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      try {
        const response = await axios.get(
          `${baseEngineer}/products-in-circle?latitude=${latitude}&longitude=${longitude}&raio=${LOCATION.raio}`,
          config
        );
        setProperty(response.data);
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    };

    // Verificar se a localização do usuário está disponível
    if (userLocation.latitude && userLocation.longitude) {
      // Chamar a função para buscar lojas com base na localização do usuário
      getStores(userLocation.latitude, userLocation.longitude);
    }
  }, [userLocation]);
  return (
    <Container>
      <Navbar />

      <TitleComponent icon={CiHome} text="Todas as propriedades" />
      <GridContainer>
        {property.length === 0 && (
          <p style={{ marginLeft: "10px" }}>Buscando pela sua localização...</p>
        )}
        {property.map((item: any, index) => (
          <ContentCard key={index}>
            <Card
              image={item?.image}
              title={item?.name}
              description={item?.description}
              hectares={item?.hectares_amount}
              onClick={() => handleDetails(item)}
            />
          </ContentCard>
        ))}
      </GridContainer>
      <Footer
        companyName="Ecolife"
        address="Caruaru - PE"
        email="contato@suaempresa.com"
        phone="(11) 1234-5678"
        socialMedia={socialMediaLinks}
      />
    </Container>
  );
}
