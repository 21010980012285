import Card from "../../../components/card/card";
import Navbar from "../../../components/navbar/navbar";
import TitleComponent from "../../../components/title/title";
import {
  Container,
  ContainerCard,
  ContentCard,
  GridContainer,
  Title,
} from "./style";
import casa from "../../../../src/assets/images/casa.png";
import { CiHome } from "react-icons/ci";
import { baseSeller } from "../../../utils/server/api";
import { useEffect, useState } from "react";
import axios from "axios";
import { config } from "../../../utils/server/header";
import Footer from "../../../components/footer/footer";
import { useTranslation } from "react-i18next";
import { useSellerPropertyData } from "../../../hooks/serviceMySpaceSeller";
import { useNavigate } from "react-router-dom";

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin:
    "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

export default function MySpaceSeller() {
  const { data, isLoading } = useSellerPropertyData();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleDetails = (item: any) => {
    const queryString = `?item=${encodeURIComponent(JSON.stringify(item))}`;

    navigate(`/detailsPropertySeller${queryString}`);
  };

  useEffect(() => {
    console.log(data);
  }, []);

  return (
    <Container>
      <Navbar />

      <TitleComponent icon={CiHome} text={t("my-space")} />
      {!isLoading && (
        <>
          <GridContainer>
            {data?.length === 0 && (
              <Title>Nenhuma propriedade encontrada!</Title>
            )}
            {data?.map((item: any, index: any) => (
              <ContentCard key={index}>
                <Card
                  image={item?.image}
                  title={item?.name}
                  description={item?.description}
                  hectares={item?.hectares_amount}
                  status={item?.statusAsText}
                  onClick={() => handleDetails(item)}
                />
              </ContentCard>
            ))}
          </GridContainer>
        </>
      )}
      {isLoading && (
        <GridContainer>
          <p>{t("Carregando ...")}</p>
        </GridContainer>
      )}

      <Footer
        companyName="Ecolife"
        address="Caruaru - PE"
        email="contato@suaempresa.com"
        phone="(11) 1234-5678"
        socialMedia={socialMediaLinks}
      />
    </Container>
  );
}
