import Card from "../../../components/card/card";
import Navbar from "../../../components/navbar/navbar";
import TitleComponent from "../../../components/title/title";
import {
  Container,
  ContainerCard,
  ContentCard,
  GridContainer,
  Title,
} from "./style";
import casa from "../../../../src/assets/images/casa.png";
import { CiHome } from "react-icons/ci";
import { baseEngineer } from "../../../utils/server/api";
import { useEffect, useState } from "react";
import axios from "axios";
import { config } from "../../../utils/server/header";
import { useNavigate } from "react-router-dom";
import Footer from "../../../components/footer/footer";
import { useTranslation } from "react-i18next";
import { useGeolocation } from "react-use";
const LOCATION = {
  latitude: -8.3523948,
  longitude: -36.0291571,
  raio: "150000",
};

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin:
    "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

export default function MySpaceEngineer() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [myProperty, setMyProperty] = useState([]);
  const geolocation = useGeolocation();
  const [userLocation, setUserLocation] = useState({
    latitude: null as any | null,
    longitude: null as any | null,
  });

  const getStores = async () => {
    console.log("stories");
    const token = localStorage.getItem("token");
    console.log("token", token);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Defina o tipo de conteúdo conforme necessário
      },
    };
    console.log("config", config);
    try {
      const response = await axios.get(
        `${baseEngineer}/product/engineer-property`,
        config
      );
      setMyProperty(response.data);
      console.log("engenheiro", response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDetails = (item: any) => {
    const queryString = `?item=${encodeURIComponent(JSON.stringify(item))}`;

    navigate(`/checkProperty${queryString}`);
  };

  useEffect(() => {
    getStores();
  }, []);

  return (
    <Container>
      <Navbar />

      <TitleComponent icon={CiHome} text={t("my-space")} />

      <>
        <GridContainer>
          {myProperty?.length === 0 && (
            <Title>Nenhuma propriedade encontrada!</Title>
          )}
          {myProperty?.map((item: any, index: any) => (
            <ContentCard key={index}>
              <Card
                image={item?.image}
                title={item?.name}
                description={item?.description}
                hectares={item?.hectares_amount}
                status={item?.statusAsText}
                onClick={() => handleDetails(item)}
              />
            </ContentCard>
          ))}
        </GridContainer>
      </>

      <Footer
        companyName="Ecolife"
        address="Caruaru - PE"
        email="contato@suaempresa.com"
        phone="(11) 1234-5678"
        socialMedia={socialMediaLinks}
      />
    </Container>
  );
}
