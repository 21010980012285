import styled from "styled-components";
import colors from "../../utils/colors";

export const Container = styled.div`
  width: 90%; /* 90% da largura do contêiner pai */
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 5px 4px 8px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  min-height: 300px;
  margin-bottom: 30px;
  elevation: 5px;
  display: flex; /* Adicione display flex para alinhar os itens */
  flex-direction: column; /* Ajuste para uma direção de coluna */
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

export const Title = styled.h1`
  font-family: "Josefin Bold";
  color: #000;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
`;

export const Text = styled.p`
  font-family: "Josefin Bold";
  color: ${colors.danger};
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
  cursor: pointer;
`;
