import styled from "styled-components";

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const Label = styled.label`
  font-family: "Josefin Sans";
  font-size: 16px;
  margin-top: 5px;
  font-weight: bold;
`;

export const StyledSelect = styled.select`
  width: 100%;
  padding: 10px;
  border: 2px solid gray;
  border-radius: 8px;
  outline: none;
  font-size: 16px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #48c85d;
    /* border-color: #2f853a; */
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CustomError = styled.p`
  font-family: "Josefin Sans";
  color: red;
  font-size: 16px;
  margin-top: 5px;
`;
