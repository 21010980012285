import NavBar from "../../../components/navbar/navbar";
import FormikSelect from "../../../components/select/select";
import Footer from "../../../components/footer/footer";
import CustomInput from "../../../components/customInput/customInput";
import * as Yup from "yup";
import { Container, ButtonContainer, Title, ButtonBack } from "./style";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMeData } from "../../../hooks/serviceMe";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../../components/customButton/customButton";
import { useMeSellerPutDataBank } from "../../../hooks/serviceMeSellerPutDataBank";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

const PixFormSchema = Yup.object().shape({
  transfer_pix_type: Yup.string().required("Tipo de chave PIX é obrigatório"),
  transfer_pix_key: Yup.string()
    .required("Chave PIX é obrigatória")
    .when("selectOption", (selectOption, schema) => {
      // Garante que selectOption seja uma string
      const option = String(selectOption);

      switch (option) {
        case "1":
          return schema.matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, "CPF inválido");
        case "2":
          return schema.matches(
            /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/,
            "CNPJ inválido"
          );
        case "4":
          return schema.matches(/^\(\d{2}\) \d{5}-\d{4}$/, "Telefone inválido");
        case "3":
          return schema.email("Email inválido");
        case "5":
          return schema.matches(/^[A-Za-z0-9]{20}$/, "Chave EVP inválida");
        default:
          return schema;
      }
    }),
});

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin:
    "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

export default function PixForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mutate } = useMeSellerPutDataBank();
  const { data: meData, isLoading: isMeLoading } = useMeData();
  const [type, setType] = useState<any>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    const userType = localStorage.getItem("userType");
    setType(userType);
  }, []);

  const initialValues = {
    transfer_pix_type: meData?.transfer_pix_type || "",
    transfer_pix_key: meData?.transfer_pix_key || "",
  };

  const options = [
    { value: "1", label: "CPF" },
    { value: "2", label: "CNPJ" },
    { value: "3", label: "Email" },
    { value: "4", label: "Telefone" },
    { value: "5", label: "EVP (Chave Aleatória)" },
  ];

  function getMask(type: any) {
    switch (type) {
      case "1":
        return "999.999.999-99";
      case "2":
        return "99.999.999/9999-99";
      case "4":
        return "(99) 99999-9999";
      case "3":
        return undefined;
      case "5":
        return "********-****-****-****-************";
      default:
        return undefined;
    }
  }

  const onSubmit = (values: any) => {
    const data = {
      transfer_pix_type: values.transfer_pix_type,
      transfer_pix_key: values.transfer_pix_key,
    };
    mutate(data, {
      onSuccess: () => {
        setModalMessage("Cadastro de dados realizado com sucesso");
        setIsModalVisible(true);
      },
      onError: () => {
        setModalMessage("Falha ao realizar o cadastro de dados");
        setIsModalVisible(true);
      },
    });
  };

  return (
    <div>
      <NavBar />
      <Container>
        {!isMeLoading && (
          <>
            <Formik
              initialValues={initialValues}
              validationSchema={PixFormSchema}
              onSubmit={onSubmit}
            >
              {({ values, resetForm }) => {
                function resetKey() {
                  resetForm({
                    values: {
                      ...values,
                      transfer_pix_key: "",
                    },
                  });
                }

                return (
                  <>
                    <Form
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                        marginTop: "20px",
                        marginBottom: "20px",
                        padding: "20px",
                        borderRadius: "10px",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        backgroundColor: "#fff",
                        minWidth: "20%",
                      }}
                    >
                      <Title>Dados Bancários - PIX</Title>
                      <FormikSelect
                        id="transfer_pix_type"
                        label="Tipo de chave PIX"
                        options={options}
                        name="transfer_pix_type"
                      />
                      <CustomInput
                        htmlFor="transfer_pix_key"
                        name="transfer_pix_key"
                        label="Chave PIX"
                        placeholder="Insira sua chave PIX"
                        mask={getMask(values.transfer_pix_type) || ""}
                      />
                      <ButtonContainer>
                        <ButtonBack
                          color="#b3aaaa"
                          hoverColor=" #9f9696"
                          type="button"
                          onClick={(event) => {
                            event.preventDefault();
                            navigate("/typeOfBankDetails");
                          }}
                        >
                          {" "}
                          {t("back")}{" "}
                        </ButtonBack>

                        <CustomButton text={t("to-save")} type="submit" />
                        <Button onClick={resetKey}>Limpar</Button>
                      </ButtonContainer>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </>
        )}

        {isModalVisible && (
          <Modal
            isOpen={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            size="lg"
            isCentered
          >
            <ModalOverlay style={{ width: "100%", height: "100%" }} />
            <ModalContent>
              <ModalCloseButton />
              <ModalHeader> </ModalHeader>
              <ModalBody>
                <Text fontSize="lg">{modalMessage}</Text>
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => setIsModalVisible(false)}>Fechar</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}

        {isMeLoading && <p>{t("Carregando ...")}</p>}
      </Container>

      <Footer
        companyName="Ecolife"
        address="Caruaru - PE"
        email="contato@suaempresa.com"
        phone="(11) 1234-5678"
        socialMedia={socialMediaLinks}
      />
    </div>
  );
}
