import styled from "styled-components";

export const ContainerCard = styled.div`
  margin: 30px;
  display: flex;
  flex-wrap: wrap;
`;

export const ContentCard = styled.div`
  margin-right: 10px;
  margin-bottom: 30px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 colunas com largura igual */
  margin-left: 30px;
`;
