import Navbar from "../../../components/navbar/navbar";
import { CiHome } from "react-icons/ci";

import {
  Banner,
  Container,
  ContainerBanner,
  ContainerContent,
  ContainerTitle,
  Title,
  Text,
  Button,
  ContainerMargin,
  HorizontalLine,
  TitleInformation,
  TextInformation,
  Price,
  DescriptionPrice,
  MiniRetangulo,
  Quantidade,
  SomaTotal,
  ContainerFlex,
  SubPrice,
  ContainerInfos,
  ButtonInfo,
  InfoSection,
  InfoTitle,
  InfoRow,
  VerticalLine,
  ButtonContainer,
  SpanTitle,
  SpanDescription,
  ContentInformation,
  ContainerInformation,
} from "./style";
import ModalImages from "../../../components/modal-image/modal-image";
import casa from "../../../../src/assets/images/casa.png";
import { useEffect, useState } from "react";
import Amount from "../../../components/amount/amount";
import { useLocation } from "react-router-dom";

import {
  ButtonOutline,
  ButtonOutlineNoIcon,
} from "../../../components/button/button";
import Footer from "../../../components/footer/footer";
import { useTranslation } from "react-i18next";

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin:
    "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

export default function DetailsPropertySeller() {
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const item = JSON.parse(decodeURIComponent(searchParams.get("item") || "{}"));
  const [modalOpen, setModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [imageTitle, setImageTitle] = useState("");

  const handleOpenModal = (url: string, title: string) => {
    setImageUrl(url);
    setImageTitle(title);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const abrirMaps = () => {
    const lat = item.lat;
    const long = item.long;
    const address = item.address;
    // Verifica se lat, long e address estão definidos
    if (lat && long && address) {
      // Cria a URL do Google Maps com as coordenadas e o endereço
      const mapsUrl = `https://www.google.com/maps?q=${lat},${long}&query=${encodeURIComponent(
        address
      )}`;

      // Abre uma nova janela/tab para o Google Maps
      window.open(mapsUrl, "_blank");
    }
  };

  const enderecoLimitado = item.address.slice(0, 30) + "...";

  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log("item:", item);
  }, []);

  return (
    <Container>
      <Navbar />

      <ContainerTitle>
        <CiHome size={25} />
        <Title>
          {t("general-information")} {item?.name}
        </Title>
      </ContainerTitle>
      <ContentInformation>
        <ContainerBanner>
          <Banner src={item?.image} alt="Casa" />
        </ContainerBanner>
        <ContainerInformation>
          {/* <ContainerContent>
            <Text>{item?.name}</Text>
            <Button onClick={abrirMaps}>Abrir Mapa</Button>
          </ContainerContent> */}
          {/* 
          <ContainerMargin>
            <TitleInformation>Informações</TitleInformation>
            <TextInformation>{item?.description}</TextInformation>
          </ContainerMargin>

          <ContainerMargin>
            <HorizontalLine />
          </ContainerMargin> */}

          <ContainerMargin>
            {/* <Text>Informações</Text> */}

            <ContainerInfos>
              <Button onClick={abrirMaps}>{t("open-the-map")}</Button>
              <InfoSection>
                <InfoRow>
                  <SpanTitle>{t("hectares")}</SpanTitle>
                  <SpanDescription>{item?.hectares_amount}</SpanDescription>
                </InfoRow>
                <InfoRow>
                  <SpanTitle>{t("address")}</SpanTitle>
                  <SpanDescription>{enderecoLimitado}</SpanDescription>
                </InfoRow>
                <InfoRow>
                  <SpanTitle>{t("carbon-credit")}</SpanTitle>
                  <SpanDescription>
                    {item?.carbon_credits_amount}
                  </SpanDescription>
                </InfoRow>
                <InfoRow>
                  <SpanTitle>{t("price")}</SpanTitle>
                  <SpanDescription>{item?.priceAsCurrency}</SpanDescription>
                </InfoRow>
              </InfoSection>

              <ButtonContainer>
                <ButtonOutlineNoIcon
                  onClick={() => handleOpenModal(item?.cib_nirfImage, "CIB")}
                  title="CIB"
                />
                <ButtonOutlineNoIcon
                  onClick={() =>
                    handleOpenModal(
                      item?.addressImage,
                      `${t("proof-residence")}`
                    )
                  }
                  title={t("proof-residence")}
                />
              </ButtonContainer>
              <ButtonContainer>
                <ButtonOutlineNoIcon
                  onClick={() =>
                    handleOpenModal(item?.deedImage, `${t("deed-file")}`)
                  }
                  title={t("deed-file")}
                />
                <ButtonOutlineNoIcon
                  onClick={() =>
                    handleOpenModal(item?.image, `${t("image-property")}`)
                  }
                  title={t("image-property")}
                />
              </ButtonContainer>
            </ContainerInfos>
          </ContainerMargin>
        </ContainerInformation>
      </ContentInformation>
      <ModalImages
        isOpen={modalOpen}
        onClose={handleCloseModal}
        title={imageTitle}
        imageUrl={imageUrl}
      />
      <Footer
        companyName="Ecolife"
        address="Caruaru - PE"
        email="contato@suaempresa.com"
        phone="(11) 1234-5678"
        socialMedia={socialMediaLinks}
      />
    </Container>
  );
}
