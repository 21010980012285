import { useEffect, useState } from "react";
import Navbar from "../../components/navbar/navbar";
import { BsCart3 } from "react-icons/bs";
import { FaRegTrashAlt } from "react-icons/fa";
import { useCart } from "../../context/CartContext";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContainer,
  CardContent,
  CardDescription,
  CardImage,
  CardPrice,
  CardQuantity,
  CardTitle,
  CardValue,
  Column,
  Container,
  ContainerMargin,
  ContainerTitle,
  HorizontalLine,
  LabelCard,
  Price,
  RemoveButton,
  Row,
  RowPay,
  RowTotal,
  SubTitle,
  Title,
  TitleCard,
  TrashIcon,
} from "./style";
import Amount from "../../components/amount/amount";
import { payment } from "../../utils/server/paymentToken";
import { Button } from "../../components/button/button";
import axios from "axios";
import { baseBuyer } from "../../utils/server/api";
import { config } from "../../utils/server/header";
import PixModal from "../modal/modal";
import colors from "../../utils/colors";
import { toast } from "react-toastify";
import Footer from "../../components/footer/footer";
import { useTranslation } from "react-i18next";
import { Alert } from "@chakra-ui/react";
import { useMeData } from "../../hooks/serviceMe";
import Modal from "../modal/modal";

const socialMediaLinks = {
  facebook: "https://www.facebook.com/lifetecnologiaeconsultoria",
  site: "https://lifecon.com.br/",
  instagram: "https://www.instagram.com/lifecon_tecnologia/",
  linkedin:
    "https://www.linkedin.com/company/life-tecnologia/?originalSubdomain=br",
};

export default function Cart() {
  const location = useLocation();
  const { data } = useMeData();
  const { t } = useTranslation();
  const [valorProduto, setValorProduto] = useState(80);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pixCode, setPixCode] = useState("");
  const [ticket, setTicket] = useState("");
  const { cart, getCart } = useCart();
  const navigate = useNavigate();
  // const [cart, setCart] = useState<any>();
  const [credit_card, setCredit_card] = useState([]);
  const [somaTotal, setSomaTotal] = useState(0);
  const [paymentToken, setPaymentToken] = useState<any>();
  const [methodPayment, setMethodPayment] = useState("");
  const [activeCard, setActiveCard] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const [modalOpen, setModalOpen] = useState(false);
  const [company_id, setCompanyId] = useState<any>();

  const handleSomaTotalChange = (novoTotal: any) => {
    setSomaTotal(novoTotal);
  };

  const handleToken = () => {
    payment(activeCard);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleConfirmAction = () => {
    // Aqui você coloca a lógica que deseja executar quando o usuário confirmar a ação
    console.log("Ação confirmada");
    setModalOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate(`/mySpaceBuy`);
  };

  const activeCardPay = async () => {
    credit_card.map(async (card: any) => {
      if (card.isMain) {
        await setActiveCard(card);
      }
    });
  };

  // const getCart = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${baseBuyer}/order/cart?expand=client`,
  //       config
  //     );

  //     console.log("order", response.data?.orderProducts);
  //     setCart(response.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const showAlert = () => {
    alert("Não é possível finalizar a compra sem que tenha itens no carrinho");
  };

  const finishOrder = async () => {
    if (cart?.orderProducts.length === 0) {
      showAlert();
    } else {
      if (methodPayment === "1") {
        console.log(activeCard);
        await payment(activeCard);
      }
      const data = {
        paymentMethod: methodPayment,
        company_id: company_id,
        paymentToken: paymentToken,
      };
      try {
        setIsLoading(true);
        const response = await axios.post(
          `${baseBuyer}/order/create`,
          data,
          config()
        );

        // setIsModalOpen(true);
        setPixCode(response.data.result.qrcode);
        setTicket(response.data.result.url);
        setIsLoading(false);
        if (methodPayment === "2" || methodPayment === "3") {
          setIsModalOpen(true);
        }

        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const storedCards = JSON.parse(localStorage.getItem("creditCards") || "{}");
    const userCards = storedCards[data?.id] || []; // Verifica se há cartões para o usuário atual

    setCredit_card(userCards);
    const tokenPay = localStorage.getItem("payment_token");
    setPaymentToken(tokenPay);
    activeCardPay();

    const companyId = JSON.parse(localStorage.getItem("company") || "{}");
    console.log(companyId, "company");
    setCompanyId(companyId);
  }, [data]);

  useEffect(() => {
    getCart();
    console.log("cartao", credit_card);
    console.log("cartao", data?.id);
    // handleToken();
  }, []);

  const calculateSubtotal = (item: any) => {
    return item.quantity * item.price;
  };

  const calculateTotal = (products: any) => {
    if (products && products.length > 0) {
      return products.reduce(
        (total: any, item: any) => total + calculateSubtotal(item),
        0
      );
    }
    return 0;
  };

  const handleRemoveItem = async (id: any) => {
    console.log("id", id);
    const data = { id };
    try {
      const response = await axios.post(
        `${baseBuyer}/order/remove-product`,
        data,
        config()
      );

      if (response.data.result === false) {
        toast.error("Erro ao remover item do carrinho", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.success("Item removido do carrinho", {
          position: toast.POSITION.TOP_CENTER,
        });
        getCart();
      }
      console.log(response.data.result);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <Navbar />
      <ContainerTitle>
        <BsCart3 size={25} />
        <Title>{t("my-cart")}</Title>
      </ContainerTitle>
      <ContainerMargin>
        {cart?.orderProducts.length === 0 && (
          <div>
            <SubTitle>Seu carrinho está vazio!</SubTitle>
          </div>
        )}
        {cart?.orderProducts.map((item: any) => (
          <CardContainer key={item.product_id}>
            <CardImage src={item?.imageUrl} alt="Imagem do Produto" />
            <CardContent>
              <Column>
                <CardTitle>{item?.product_name}</CardTitle>
                <CardDescription>{item?.product_description}</CardDescription>
              </Column>

              {/* <Column>
                <CardQuantity>Quantidade:</CardQuantity>
                <Amount
                  price={valorProduto}
                  onSomaTotalChange={handleSomaTotalChange}
                  quantidade={item.quantity}
                  setQuantidade={(novaQuantidade) => {}}
                />
              </Column> */}

              <Column>
                <CardPrice>{t("price")}:</CardPrice>
                <CardValue>{item?.priceAsCurrency}</CardValue>
              </Column>

              <Column>
                <CardPrice>SubTotal:</CardPrice>
                <CardValue>
                  R${" "}
                  {somaTotal > 0
                    ? somaTotal.toFixed(2)
                    : calculateTotal(cart?.orderProducts).toFixed(2)}
                </CardValue>
              </Column>
            </CardContent>
            <RemoveButton onClick={() => handleRemoveItem(item.id)}>
              <Row>
                <FaRegTrashAlt size={18} style={{ marginRight: 5 }} />
                {t("to-remove")}
              </Row>
            </RemoveButton>
          </CardContainer>
        ))}
      </ContainerMargin>
      <ContainerMargin>
        <HorizontalLine />
      </ContainerMargin>
      <ContainerTitle>
        <BsCart3 size={25} />
        <Title>{t("payment-method")}</Title>
      </ContainerTitle>
      <ContainerMargin>
        <RowPay>
          <Button
            onClick={() => setMethodPayment("1")}
            colorButton={methodPayment === "1" ? colors.primary : colors.gray}
            title={t("credit-card")}
          />
          <Button
            onClick={() => setMethodPayment("2")}
            colorButton={methodPayment === "2" ? colors.primary : colors.gray}
            title="Pix"
          />
          <Button
            onClick={() => setMethodPayment("3")}
            colorButton={methodPayment === "3" ? colors.primary : colors.gray}
            title={t("ticket")}
          />
        </RowPay>
      </ContainerMargin>
      {methodPayment === "1" &&
        (credit_card.length === 0 ? (
          <ContainerMargin>
            <div
              style={{
                backgroundColor: "blue",
                padding: 10,
                width: "28%",
                height: 45,
                borderRadius: 8,
              }}
              onClick={() => (window.location.href = "/creditCard")}
            >
              <SubTitle>{t("add-a-credit-card")}</SubTitle>
            </div>
          </ContainerMargin>
        ) : (
          credit_card.map(
            (item: any) =>
              item.isMain && (
                <Card key={item.id}>
                  <LabelCard>Número do cartão</LabelCard>
                  <LabelCard>{item.number}</LabelCard>
                  <LabelCard>Titular</LabelCard>
                  <LabelCard>{item.firtsName + " " + item.lastName}</LabelCard>

                  <button
                    onClick={() => (window.location.href = "/creditCard")}
                  >
                    <TitleCard>Trocar de Cartão</TitleCard>
                  </button>
                </Card>
              )
          )
        ))}

      <RowTotal>
        <ContainerTitle>
          <BsCart3 size={25} />
          <Column>
            <Title>Total</Title>
            <Price>
              R${" "}
              {somaTotal > 0
                ? somaTotal.toFixed(2)
                : calculateTotal(cart?.orderProducts).toFixed(2)}
            </Price>
          </Column>
        </ContainerTitle>

        <ContainerTitle>
          <BsCart3 size={25} />
          <Column>
            <Title>SubTotal</Title>
            <Price>
              R${" "}
              {somaTotal > 0
                ? somaTotal.toFixed(2)
                : calculateTotal(cart?.orderProducts).toFixed(2)}
            </Price>
          </Column>
        </ContainerTitle>
      </RowTotal>
      {isModalOpen && (
        <PixModal
          onClose={closeModal}
          paymentMethod={methodPayment}
          pixCode={pixCode}
          ticket={ticket}
        />
      )}

      <ContainerMargin>
        <RowPay>
          <Button
            isLoading={isLoading}
            onClick={finishOrder}
            title={t("finish")}
          />
        </RowPay>
      </ContainerMargin>
      <Footer
        companyName="Ecolife"
        address="Caruaru - PE"
        email="contato@suaempresa.com"
        phone="(11) 1234-5678"
        socialMedia={socialMediaLinks}
      />
    </Container>
  );
}
