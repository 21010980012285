// Seu componente que utiliza o botão
import React, { useState } from "react";
import { Button, Container } from "./style";
import { useTranslation } from "react-i18next";

interface ButtonSelectProps {
  onSelectChange: (selectedOption: number) => void;
}

const ButtonSelect: React.FC<ButtonSelectProps> = ({ onSelectChange }) => {
  const [selectedOption, setSelectedOption] = useState<number>(1);
  const { t } = useTranslation();
  const handleButtonClick = (option: number) => {
    setSelectedOption(option);
    onSelectChange(option); // Chama a função de retorno de chamada com o novo valor
  };

  return (
    <Container>
      <Button
        selected={selectedOption === 1}
        onClick={() => handleButtonClick(1)}
      >
        {t("physical-person")}
      </Button>
      <Button
        selected={selectedOption === 2}
        onClick={() => handleButtonClick(2)}
      >
        {t("legal-person")}
      </Button>
    </Container>
  );
};

export default ButtonSelect;
