import React, { useEffect, useState } from "react";
import {
  ButtonEnter,
  Container,
  Content,
  LinhaVertical,
  Space,
  TitleButtonEnter,
  TitleInput,
  Title,
  MarginBottom,
  ErrorText,
  SelectContainer,
  DivImg,
  Img,
  ButtonImg,
} from "./style";
import { useAuth } from "../../../context/AuthContext";
import {
  Formik,
  Field,
  Form,
  FieldInputProps,
  ErrorMessage,
  FormikHelpers,
} from "formik";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { FaRegAddressCard } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { CiPhone } from "react-icons/ci";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { MdCorporateFare } from "react-icons/md";
import { FaWpforms } from "react-icons/fa";

import InputWithIcon from "../../../components/input/input";
import logo from "../../../assets/images/Logo_Ecolife_onda__1_-removebg-preview.png";
import ButtonSelect from "../../../components/button-select/ButtonSelect";
import { Center } from "@chakra-ui/react";
import registerEngineerValidation from "../../../utils/validation/registerEngineer";
import { BeatLoader } from "react-spinners";
import MaskedInput from "../../../components/input/inputMask";
import { useNavigate } from "react-router-dom";
export default function RegisterEngineer() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { registerEngineer, registerError } = useAuth();
  const [selectedOption, setSelectedOption] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const handleSelectChange = (option: number) => {
    setSelectedOption(option);
  };

  const handleSubmit = async (
    values: typeof initialValues,
    actions: FormikHelpers<typeof initialValues>
  ) => {
    try {
      const data = {
        name: values.name,
        surname: values.surname,
        document: values.document.replace(/[^0-9]/g, ""),
        rg: values.rg.replace(/[^0-9]/g, ""),
        phone: values.phone.replace(/[^0-9]/g, ""),
        email: values.email,
        password: values.password,
        confirm_password: values.confirm_password,
      };
      setIsLoading(true);
      await registerEngineer(data);
      setIsLoading(false);
    } catch (error) {
      console.error("Erro ao cadastrar:", (error as Error).message);
    }
    // Aqui você pode realizar a lógica de envio para o contexto

    // Reinicie o estado do Formik
    // actions.resetForm();
  };

  const initialValues = {
    name: "",
    surname: "",
    document: "",
    rg: "",
    phone: "",
    email: "",
    password: "",
    confirm_password: "",
  };

  return (
    <Container>
      <Content>
        <Space onClick={() => navigate("/")}>
          <DivImg>
            <Img src={logo} alt="Minha Imagem" />
            <ButtonImg onClick={() => navigate("/")}>
              {t("voltar para tela inicial")}
            </ButtonImg>
          </DivImg>
        </Space>
        <LinhaVertical />

        <Space>
          <Title>{t("comment-engineer")}</Title>

          <Formik
            initialValues={initialValues}
            validationSchema={registerEngineerValidation}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            <Form>
              <TitleInput> {t("name")}</TitleInput>
              <Field
                type="text"
                name="name"
                render={({ field }: { field?: FieldInputProps<any> }) => (
                  <InputWithIcon
                    icon={<FaWpforms />}
                    iconSize="23px"
                    field={field}
                  />
                )}
              />

              {registerError.some((error: any) => error.field === "name") ? (
                // Se houver um erro específico, exiba apenas o erro personalizado
                registerError
                  .filter((error: any) => error.field === "name")
                  .map((error: any, index: any) => (
                    <ErrorText key={index}>
                      <p>{error.msgs}</p>
                    </ErrorText>
                  ))
              ) : (
                // Caso contrário, exiba o erro do Formik
                <ErrorText>
                  <ErrorMessage name="name" />
                </ErrorText>
              )}

              <MarginBottom />

              <TitleInput> {t("surname")}</TitleInput>
              <Field
                type="text"
                name="surname"
                render={({ field }: { field?: FieldInputProps<any> }) => (
                  <InputWithIcon
                    icon={<HiOutlineUserCircle />}
                    iconSize="23px"
                    field={field}
                  />
                )}
              />
              <ErrorText>
                <ErrorMessage name="surname" />
              </ErrorText>

              <MarginBottom />

              <TitleInput>CPF</TitleInput>
              <Field
                type="text"
                name="document"
                render={({ field }: { field?: FieldInputProps<any> }) => (
                  <MaskedInput
                    field={field}
                    icon={<FaRegAddressCard />}
                    mask="999.999.999-99"
                    iconSize="23px"
                    placeholder=""
                  />
                )}
              />

              {registerError.some(
                (error: any) => error.field === "document"
              ) ? (
                // Se houver um erro específico, exiba apenas o erro personalizado
                registerError
                  .filter((error: any) => error.field === "document")
                  .map((error: any, index: any) => (
                    <ErrorText key={index}>
                      <p>{error.msgs}</p>
                    </ErrorText>
                  ))
              ) : (
                // Caso contrário, exiba o erro do Formik
                <ErrorText>
                  <ErrorMessage name="document" />
                </ErrorText>
              )}

              <MarginBottom />

              <TitleInput>RG</TitleInput>
              <Field
                type="text"
                name="rg"
                render={({ field }: { field?: FieldInputProps<any> }) => (
                  <MaskedInput
                    field={field}
                    icon={<FaRegAddressCard />}
                    mask="9.999.999"
                    iconSize="23px"
                    placeholder=""
                  />
                )}
              />

              {registerError.some((error: any) => error.field === "rg") ? (
                // Se houver um erro específico, exiba apenas o erro personalizado
                registerError
                  .filter((error: any) => error.field === "rg")
                  .map((error: any, index: any) => (
                    <ErrorText key={index}>
                      <p>{error.msgs}</p>
                    </ErrorText>
                  ))
              ) : (
                // Caso contrário, exiba o erro do Formik
                <ErrorText>
                  <ErrorMessage name="rg" />
                </ErrorText>
              )}

              <MarginBottom />

              <TitleInput> {t("phone")}</TitleInput>
              <Field
                type="text"
                name="phone"
                render={({ field }: { field?: FieldInputProps<any> }) => (
                  <MaskedInput
                    field={field}
                    icon={<CiPhone />}
                    mask="(99) 99999-9999"
                    iconSize="23px"
                    placeholder=""
                  />
                )}
              />

              {registerError.some((error: any) => error.field === "phone") ? (
                // Se houver um erro específico, exiba apenas o erro personalizado
                registerError
                  .filter((error: any) => error.field === "phone")
                  .map((error: any, index: any) => (
                    <ErrorText key={index}>
                      <p>{error.msgs}</p>
                    </ErrorText>
                  ))
              ) : (
                // Caso contrário, exiba o erro do Formik
                <ErrorText>
                  <ErrorMessage name="phone" />
                </ErrorText>
              )}

              <MarginBottom />

              <TitleInput>{t("email")}</TitleInput>
              <Field
                type="text"
                name="email"
                render={({ field }: { field?: FieldInputProps<any> }) => (
                  <InputWithIcon
                    icon={<MdOutlineEmail />}
                    iconSize="23px"
                    field={field}
                  />
                )}
              />

              {registerError.some((error: any) => error.field === "email") ? (
                // Se houver um erro específico, exiba apenas o erro personalizado
                registerError
                  .filter((error: any) => error.field === "email")
                  .map((error: any, index: any) => (
                    <ErrorText key={index}>
                      <p>{error.msgs}</p>
                    </ErrorText>
                  ))
              ) : (
                // Caso contrário, exiba o erro do Formik
                <ErrorText>
                  <ErrorMessage name="email" />
                </ErrorText>
              )}

              <MarginBottom />
              <TitleInput>{t("password")}</TitleInput>
              <Field
                type="password"
                name="password"
                render={({ field }: { field: FieldInputProps<any> }) => (
                  <InputWithIcon
                    icon={<RiLockPasswordLine />}
                    iconSize="23px"
                    field={field}
                    type="password"
                  />
                )}
              />

              <ErrorText>
                <ErrorMessage name="password" />
              </ErrorText>

              <MarginBottom />

              <TitleInput>{t("confirm-password")}</TitleInput>
              <Field
                type="text"
                name="confirm_password"
                render={({ field }: { field?: FieldInputProps<any> }) => (
                  <InputWithIcon
                    icon={<RiLockPasswordLine />}
                    iconSize="23px"
                    field={field}
                    type="password"
                  />
                )}
              />

              <ErrorText>
                <ErrorMessage name="confirm_password" />
              </ErrorText>

              <MarginBottom />

              <Center>
                <ButtonEnter type="submit">
                  {isLoading ? (
                    <BeatLoader className="spinner" size={8} color={"#fff"} />
                  ) : (
                    <TitleButtonEnter>{t("register")}</TitleButtonEnter>
                  )}
                </ButtonEnter>
              </Center>
            </Form>
          </Formik>
        </Space>
      </Content>
      <ToastContainer />
    </Container>
  );
}
