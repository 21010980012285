import styled from "styled-components";

export const ContainerTitle = styled.div`
  display: flex;
  margin: 30px;
`;

export const Title = styled.h1`
  font-family: "Josefin Bold";
  color: #000;
  font-size: 22px;
`;
