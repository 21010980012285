import React, { useEffect } from "react";
import {
  Br,
  Button,
  ButtonBuy,
  ButtonImg,
  Container,
  ContainerChoice,
  Content,
  DescriptionButtonBuy,
  DescriptionButtonSeller,
  DivImg,
  Img,
  Language,
  LanguageText,
  LinhaVertical,
  Space,
  Title,
  TitleButtonBuy,
  TitleButtonSeller,
  Us,
} from "./style";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/Logo_Ecolife_onda__1_-removebg-preview.png";
import br from "../../assets/images/brasil.png";
import us from "../../assets/images/estados-unidos.png";

export default function Choice() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const handleLogin = (personal: string) => {
    localStorage.setItem("userType", personal);
    if (personal === "cliente") {
      window.location.href = "/loginBuy";
    } else if (personal === "vendedor") {
      window.location.href = "/loginSeller";
    } else {
      window.location.href = "/loginEngineer";
    }
  };

  const changeLanguage = (lng: string) => {
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng); // Atualiza o idioma no i18n
    //i18n.changeLanguage(lg);
  };

  useEffect(() => {
    const fetchLanguage = async () => {
      const storedLanguage = await localStorage.getItem("language");
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage); // Atualiza o idioma no i18n
      }
    };

    fetchLanguage();
  }, []);

  return (
    <Container>
      <Content>
        <Space onClick={() => navigate("/")}>
          <DivImg>
            <Img src={logo} alt="Minha Imagem" />
            <ButtonImg onClick={() => navigate("/")}>
              {t("voltar para tela inicial")}
            </ButtonImg>
          </DivImg>
        </Space>
        <LinhaVertical />
        <Space>
          <Title>{t("welcome")}</Title>
          <LanguageText>{t("language")}</LanguageText>
          <Language>
            <a href="#" onClick={() => changeLanguage("pt")}>
              <Br src={br} alt="Brasil" />
            </a>
            <a href="#" onClick={() => changeLanguage("en")}>
              <Us src={us} alt="Estados Unidos" />
            </a>
          </Language>
          <ContainerChoice>
            <Button onClick={() => handleLogin("vendedor")}>
              <TitleButtonSeller>{t("seller")}</TitleButtonSeller>
              <DescriptionButtonSeller>
                {t("comment-seller")}
              </DescriptionButtonSeller>
            </Button>
            <ButtonBuy onClick={() => handleLogin("cliente")}>
              <TitleButtonBuy>{t("buyer")}</TitleButtonBuy>
              <DescriptionButtonBuy>{t("comment-buyer")}</DescriptionButtonBuy>
            </ButtonBuy>
            <Button
              style={{ backgroundColor: "gray" }}
              onClick={() => handleLogin("engenheiro")}
            >
              <TitleButtonSeller>{t("engineer")}</TitleButtonSeller>
              <DescriptionButtonSeller>
                {t("comment-engineer")}
              </DescriptionButtonSeller>
            </Button>
          </ContainerChoice>
        </Space>
      </Content>
    </Container>
  );
}
