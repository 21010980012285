import { useQuery } from "@tanstack/react-query";
import {
  baseBuyer,
  baseEngineer,
  baseSeller,
} from "../../src/utils/server/api";
import axios from "axios";

const type = localStorage.getItem("userType");

const fetchMe = async () => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    if (type === "cliente") {
      const response = await axios.get(`${baseBuyer}/me`, config);
      return { ...response.data };
    } else if (type === "vendedor") {
      const response = await axios.get(`${baseSeller}/me`, config);
      return { ...response.data };
    } else {
      const response = await axios.get(`${baseEngineer}/me`, config);
      return { ...response.data };
    }
  } catch (error) {
    // Trate os erros aqui
    console.error(error);
  }
};

export function useMeData() {
  const query = useQuery({
    queryFn: fetchMe,
    queryKey: ["me"],
  });

  return query;
}

const fetchMeExpand = async () => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    if (type === "cliente") {
      const response = await axios.get(`${baseBuyer}/me?expand=bank`, config);
      return { ...response.data };
    } else if (type === "vendedor") {
      const response = await axios.get(`${baseSeller}/me?expand=bank`, config);
      return { ...response.data };
    } else {
      const response = await axios.get(
        `${baseEngineer}/me?expand=bank`,
        config
      );
      return { ...response.data };
    }
  } catch (error) {
    // Trate os erros aqui
    console.error(error);
  }
};

export function useMeDataExpand() {
  const query = useQuery({
    queryFn: fetchMeExpand,
    queryKey: ["meExpand"],
  });

  return query;
}
