import React, { useEffect, FocusEvent, InputHTMLAttributes } from "react";
import { FieldInputProps, useField } from "formik";
import { CustomError, InputContainer, Label, StyledInput } from "./style";

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  htmlFor?: string;
  mask: string;
  placeholder?: string;
  selectValue?: string;
  selectWithValue?: boolean;
}

const CustomInput: React.FC<CustomInputProps> = ({
  name,
  label,
  mask,
  htmlFor,
  placeholder,
}) => {
  const [field, meta] = useField(name);

  return (
    <InputContainer>
      <Label htmlFor={htmlFor}>{label}</Label>
      <StyledInput mask={mask} {...field} placeholder={placeholder} />
      {meta.touched && meta.error ? (
        <CustomError>{meta.error}</CustomError>
      ) : null}
    </InputContainer>
  );
};

export default CustomInput;
